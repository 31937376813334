import React, { createContext, useContext, useState } from "react";

// Créer le contexte
const NotificationContext = createContext();

// Hook personnalisé pour utiliser le contexte de notification
export const useNotification = () => useContext(NotificationContext);

// Types de notifications avec leurs couleurs
const notificationTypes = {
  success: "bg-green-500",
  error: "bg-red-500",
  warning: "bg-yellow-500",
  info: "bg-blue-500"
};

// Composant Provider pour envelopper l'application
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  // Fonction pour afficher une notification
  const notify = (message, type = "info", duration = 3000) => {
    const id = Date.now(); // Identifiant unique pour chaque notification
    
    setNotifications(prev => [...prev, {
      id,
      message,
      type,
      className: "slide-in"
    }]);

    // Déclencher l'animation de sortie après un délai
    setTimeout(() => {
      setNotifications(prev => 
        prev.map(notif => 
          notif.id === id 
            ? { ...notif, className: "slide-out" }
            : notif
        )
      );

      // Supprimer la notification après l'animation
      setTimeout(() => {
        setNotifications(prev => 
          prev.filter(notif => notif.id !== id)
        );
      }, 1000); // Durée de l'animation
    }, duration);
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <div className="fixed bottom-7 right-24 z-50 space-y-2">
        {notifications.map(({ id, message, type, className }) => (
          <div
            key={id}
            className={`
              ${notificationTypes[type]}
              ${className}
              text-white p-3 rounded-lg shadow-lg
              min-w-[300px] max-w-[400px] max-h-[60px]
              flex items-center justify-between
              transform transition-all duration-2000 ease-in-out
            `}
          >
            <span>{message}</span>
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};