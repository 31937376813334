import AdminAddLeaderboard from "./AdminAddLeaderboard";
import { StakingAdmin } from "./StakingAdmin";
import "./AdminPanel.css";
import AdminAddBet from "./AdminAddBet";

const AdminPage = () => {
  return (
    <div className="bg-[#1D1F24] min-h-screen overflow-x-hidden">
      <section className="min-h-screen z-1 relative">
        <div className="AdminPanel">
          <AdminAddLeaderboard />
          <AdminAddBet />
          <StakingAdmin />
        </div>
      </section>
    </div>
  );
};

export default AdminPage;
