import React, { useState, useEffect, useCallback } from "react";
import styles from "../../styles/Bet.module.css";
import { Contract } from "ethers";
import bettingContractABI from "../ABI/Betting";
import { useWallet } from "../../context/WalletContext";

function ActiveBets({ match, activeBets, onWithdraw, pausedContracts }) {
  const [localPauseState, setLocalPauseState] = useState(false);
  const [filteredBets, setFilteredBets] = useState([]);
  const [winner, setWinner] = useState(null);
  const [withdrawingBets, setWithdrawingBets] = useState(new Set());
  const { wallet } = useWallet(); 

  // Mettre à jour l'état de pause local
  useEffect(() => {
    if (match?.bettingContractAddress) {
      const isPaused = pausedContracts[match.bettingContractAddress] || false;
      setLocalPauseState(isPaused);
    }
  }, [pausedContracts, match?.bettingContractAddress]);

  // Récupérer le gagnant depuis le contrat si le match est terminé
  const getWinnerFromContract = useCallback(async (contractAddress) => {
    if (!contractAddress || !wallet) return null;

    try {
      const contract = new Contract(contractAddress, bettingContractABI, wallet);
      const matchEnded = await contract.matchEnded();

      if (!matchEnded) return null;

      const result = await contract.matchResult();
      return result?.toString();
    } catch (error) {
      console.error("Error getting winner from contract:", error);
      return null;
    }
  }, [wallet]); 

  // Mettre à jour le gagnant quand le match change
  useEffect(() => {
    const updateWinner = async () => {
      if (match?.status === "finished" && match.bettingContractAddress) {
        const result = await getWinnerFromContract(
          match.bettingContractAddress
        );
        setWinner(result);
      }
    };

    updateWinner();
  }, [match, getWinnerFromContract]);

  // Filtrer les paris
  useEffect(() => {
    if (!match || !Array.isArray(activeBets)) {
      setFilteredBets([]);
      return;
    }

    const relevantBets = activeBets.filter((bet) => {
      // Vérifier que le pari correspond au match actuel
      if (bet.betId !== match.pandascoreId) {
        return false;
      }

      // Vérifier que le pari est actif
      if (!bet.isActive) {
        return false;
      }

      // Pour les matchs terminés
      if (match.status === "finished") {
        console.log(winner);
        // S'il y a un résultat
        if (winner !== null) {
          // Vérifier si l'utilisateur a gagné
          const userWon =
            (winner === "1" && bet.team === match.team1?.name) ||
            (winner === "2" && bet.team === match.team2?.name);

          return userWon;
        }
        return false;
      }

      // Pour les matchs en cours, afficher tous les paris actifs
      return true;
    });

    setFilteredBets(relevantBets);
  }, [match, activeBets, winner]);

  // Vérifier si on doit afficher le bouton de retrait
  const shouldShowWithdrawButton = useCallback(
    (match, localPauseState, bet) => {
      // Ne pas montrer pour les matchs en cours
      if (match.status === "running") {
        return false;
      }

      // Ne pas montrer si en pause ET match non terminé
      if (localPauseState && match.status !== "finished") {
        return false;
      }

      // Ne pas montrer si déjà retiré manuellement
      if (bet.manuallyWithdrawn) {
        return false;
      }

      if (!onWithdraw) {
        return false;
      }

      return true;
    },
    [onWithdraw]
  );

  const handleWithdraw = async (betId, team, amount, match) => {
    // Créer une clé unique pour ce pari
    const betKey = `${betId}-${team}`;

    // Marquer ce pari comme en cours de retrait
    setWithdrawingBets((prev) => new Set([...prev, betKey]));

    try {
      await onWithdraw(betId, team, amount, match);
    } finally {
      // Retirer le marqueur de retrait une fois terminé
      setWithdrawingBets((prev) => {
        const newSet = new Set(prev);
        newSet.delete(betKey);
        return newSet;
      });
    }
  };

  const isWinningBet = (bet) => {
    if (match.status === "finished" && winner !== null) {
      return (
        (winner === "1" && bet.team === match.team1?.name) ||
        (winner === "2" && bet.team === match.team2?.name)
      );
    }
    return false;
  };

  // Ne rien rendre s'il n'y a pas de paris à afficher
  if (!match || filteredBets.length === 0) {
    return null;
  }

  return (
    <div className="space-y-3">
      {filteredBets.map((bet, index) => {
        const betKey = `${bet.betId}-${bet.team}`;
        const isWithdrawing = withdrawingBets.has(betKey);

        return (
          <div
            key={`${bet.betId}-${bet.team}-${index}`}
            className={`flex flex-col border-[#635e5ea2] rounded-4 mb-3 mt-2 p-2 font-bold border-[1px] rounded-2xl shadow-sm ${
              isWinningBet(bet) ? "bg-green-600/30" : ""
            }`}
          >
            <p className="w-full mb-2">
              {bet.title.includes(":")
                ? bet.title.split(":").slice(1).join(":").trim()
                : bet.title}
            </p>
            <div className="flex justify-around items-end">
              <div className="flex-1 flex-col text-start pl-2">
                <p>{bet.odd}</p>
                <p>{bet.team}</p>
                <p className="flex items-center">
                  {typeof bet.amount === "number"
                    ? bet.amount.toFixed(2)
                    : bet.amount}{" "}
                  REKT
                </p>
              </div>
              <button
                id={styles.placeBet}
                onClick={() =>
                  shouldShowWithdrawButton(match, localPauseState, bet) &&
                  !isWithdrawing
                    ? handleWithdraw(bet.betId, bet.team, bet.amount, match)
                    : null
                }
                className={`${
                  !shouldShowWithdrawButton(match, localPauseState, bet) ||
                  isWithdrawing
                    ? styles.greyedOut
                    : ""
                }`}
                disabled={isWithdrawing}
              >
                {isWithdrawing ? "Withdrawing" : "Withdraw"}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ActiveBets;
