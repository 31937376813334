import { useState, useEffect, useCallback } from "react";
import { unstakeTokens, getStakedBalance } from "./Stakepool";
import { useWallet } from "../../context/WalletContext";

import rektLogo from "../../REKT_Logo.webp";

import styles from "../../styles/Staking.module.css";
import { useNotification } from "../Notifications/Notifications";

export const Unstake = () => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [stakedBalance, setStakedBalance] = useState("0");
  const { account, wallet } = useWallet();
  const { notify } = useNotification();

  const loadStakedBalance = useCallback(async () => {
    if (!account) return;

    try {
      const balance = await getStakedBalance(wallet);
      setStakedBalance(balance);
    } catch (error) {
      console.error("Error loading staked balance:", error);
    }
  }, [account, wallet]);

  useEffect(() => {
    loadStakedBalance();
    // Rafraîchir le solde toutes les 30 secondes
    const interval = setInterval(loadStakedBalance, 30000);
    return () => clearInterval(interval);
  }, [account, loadStakedBalance]);

  const handleUnstake = async () => {
    if (!account) {
      console.error("Please connect your wallet");
      return;
    }

    const parsedAmount = parseFloat(amount);

    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      console.error("Invalid amount for unstaking");
      return;
    }

    if (parsedAmount > parseFloat(stakedBalance)) {
      console.error("Amount greater than your staked balance");
      return;
    }

    setLoading(true);

    try {
      await unstakeTokens(wallet, Number(amount).toFixed(18));
      notify("Tokens unstaked successfully!");
      setAmount("");
      loadStakedBalance(); // Recharger le solde après l'unstake
    } catch (error) {
      console.error("Unstaking error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetMax = () => {
    const maxAmount = Number(stakedBalance).toFixed(18);
    setAmount(Number(maxAmount));
  };
  const getDisplayBalance = () => {
    try {
      const balanceInEth = Number(stakedBalance);
      const amountToSubtract = amount ? Number(amount) : 0;
      return (balanceInEth - amountToSubtract).toFixed(2);
    } catch (error) {
      console.error("Error calculating display balance:", error);
      return "0.00";
    }
  };

  return (
    <div>
      <hr className={styles.divider} />
      <h2>Unstake Tokens</h2>
      <div className={styles.balanceInfo}>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Staked: {getDisplayBalance()}
          <img
            src={rektLogo}
            alt="REKT Logo"
            style={{ width: "15px", height: "auto", marginLeft: "5px" }}
          />
        </p>
      </div>
      <div className={styles.unstake}>
        <div className={styles.inputRow}>
          <input
            type="number"
            value={amount === "" ? "" : parseFloat(amount)}
            onChange={(e) => {
              const value = e.target.value;

              // Permettre à l'utilisateur de supprimer et vider l'input
              if (value === "") {
                setAmount("");
                return;
              }

              const numericValue = parseFloat(value);

              // Si la valeur numérique est valide et ne dépasse pas le stakedBalance
              if (!isNaN(numericValue) && numericValue <= stakedBalance) {
                setAmount(numericValue);
              }
            }}
            onKeyDown={(e) => {
              // Empêche l'ajout de chiffres si la valeur est déjà au maximum
              if (
                parseFloat(e.target.value) >= stakedBalance &&
                !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(
                  e.key
                )
              ) {
                e.preventDefault();
              }
            }}
            placeholder="Amount"
            max={stakedBalance}
            id={styles.input}
          />
          <button
             onClick={handleSetMax}
            className={styles.maxButton}
          >
            MAX
          </button>
        </div>
        <button
          onClick={handleUnstake}
          disabled={loading || !parseFloat(stakedBalance)}
          id={styles.button}
        >
          {loading ? "Unstaking..." : "Unstake"}
        </button>
      </div>
    </div>
  );
};
