import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import axiosInstance from "../axiosConfig";
import { useWallet } from "../context/WalletContext";
import chat from "../chat.svg";
import styles from "../styles/TournamentList.module.css";
import TeamModal from "./TeamModal";
import TournamentBracket from "./TournamentBracket";
import ChatModal from "./ChatModal";
import { useNotification } from "./Notifications/Notifications";
import { ChevronLeft, ChevronRight } from "lucide-react";
import background1 from "./uploads/1-background.webp";
import background2 from "./uploads/2-background.webp";
import background3 from "./uploads/3-background.webp";
import background4 from "./uploads/4-background.webp";
import background5 from "./uploads/5-background.webp";
import background6 from "./uploads/6-background.webp";
import background7 from "./uploads/7-background.webp";
import background8 from "./uploads/8-background.webp";

// Créez un objet de mapping pour les backgrounds
const backgroundImages = {
  1: background1,
  2: background2,
  3: background3,
  4: background4,
  5: background5,
  6: background6,
  7: background7,
  8: background8,
};

const DIVISION_RANKS = {
  bronze: 1,
  silver: 2,
  gold: 3,
  platinum: 4,
  diamond: 5,
  master: 6,
  grandmaster: 7,
  elite: 8,
};

const TournamentsList = ({ game }) => {
  const { account } = useWallet();
  const [notification] = useState();
  const [tournaments, setTournaments] = useState([]);
  const [registrations, setRegistrations] = useState({});
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isBracketModalOpen, setIsBracketModalOpen] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [currentChatType, setCurrentChatType] = useState("general");
  const [userTeam, setUserTeam] = useState(null);
  const { notify } = useNotification();
  const scrollContainerRef = useRef(null);
  const [registeredTournaments, setRegisteredTournaments] = useState([]);

  const filteredTournaments = useMemo(() => {
    const filtered = tournaments.filter(tournament => 
      tournament.game === game && 
      tournament.state !== "distributing_prizes" &&
      (!tournament.isRegistrationClosed || registrations[tournament._id])
    );
    return filtered;
  }, [tournaments, game, registrations]);

  const fetchTournamentData = useCallback(async () => {    
    if (!account) {
      return;
    }

    try {
      const [tournamentsRes, userRes, registrationsRes] = await Promise.all([
        axiosInstance.get("/api/tournaments"),
        axiosInstance.get(`/api/users/eth/${account}`),
        axiosInstance.get(`/api/tournaments/user-registrations/${account}`) 
      ]);

      const userData = userRes.data;
      setUserTeam(userData.team);

      // Process tournaments data
      const activeTournaments = tournamentsRes.data
      .filter(t => t.game === game && t.state !== "distributing_prizes");

      const registrationMap = registrationsRes.data.registrations.reduce((acc, tournamentId) => {
        acc[tournamentId] = true;
        return acc;
      }, {});

      const gameTournaments = activeTournaments.filter(t => t.game === game)
        .sort((a, b) => {
          const divisionDiff = (DIVISION_RANKS[a.division] || 0) - (DIVISION_RANKS[b.division] || 0);
          return divisionDiff || (a.entryFee - b.entryFee);
        });

      setRegisteredTournaments(gameTournaments.filter(t => registrationMap[t._id]));
      setTournaments(gameTournaments.filter(t => !registrationMap[t._id]));
      setRegistrations(registrationMap);

    } catch (error) {
      console.error("Error fetching tournament data:", error);
      notify("Error loading tournaments");
    }
  }, [account, game, notify]);

  useEffect(() => {
    fetchTournamentData();
  }, [fetchTournamentData]);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const newScrollPosition =
        scrollContainerRef.current.scrollLeft +
        (direction === "left" ? -scrollAmount : scrollAmount);
      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const RegisteredTournamentStrip = () => (
    <div>
      {" "}
      <div className="flex p-2 justify-center">
        <h3>My tournament(s)</h3>
      </div>
      <div className={styles.registeredStrip}>
        <button
          className={styles.scrollButton}
          onClick={() => handleScroll("left")}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>

        <div className={styles.registeredContainer} ref={scrollContainerRef}>
          {registeredTournaments.map((tournament) => (
            <div key={tournament._id} className={styles.registeredTournament}>
              <div
                key={tournament._id}
                className={styles.registeredTournamentInner}
                style={{
                  backgroundImage: `url(${
                    backgroundImages[tournament.division]
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className={styles.registeredOverlay} />
                <div className={styles.registeredContent}>
                  <h3>{tournament.name}</h3>
                  <div className={styles.registeredActions}>
                    <button
                      className={styles.bracketButton}
                      onClick={() => openBracketModal(tournament)}
                    >
                      Bracket
                    </button>
                    <button
                      className={styles.seed}
                      onClick={() => openChatModal()}
                    >
                      <img src={chat} alt="Chat" className={styles.copySeed} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button
          className={styles.scrollButton}
          onClick={() => handleScroll("right")}
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    const checkAndCloseRegistration = async () => {
      try {
        for (const tournament of tournaments) {
          const now = new Date().getTime();
          const tournamentDate = new Date(tournament.date).getTime();

          if (
            (now >= tournamentDate ||
              tournament.teams.length >= tournament.maxTeams) &&
            !tournament.isRegistrationClosed
          ) {
            await axiosInstance.post(
              `/api/tournaments/${tournament._id}/close-registration`
            );

            // Rafraîchir la liste des tournois après fermeture
            const response = await axiosInstance.get("/api/tournaments");
            setTournaments(response.data);
          }
        }
      } catch (error) {
        console.error("Error closing registration:", error);
      }
    };

    if (tournaments.length > 0) {
      checkAndCloseRegistration();
    }
  }, [tournaments]);

  const checkTeamCaptain = async () => {
    try {
      const teamRes = await axiosInstance.get(`/api/teams/${userTeam}`);
      return teamRes.data.address === account;
    } catch (error) {
      console.error("Error checking team captain:", error);
      return false;
    }
  };

  const openChatModal = () => {
    setCurrentChatType(currentChatType);
    setIsChatModalOpen(true);
  };

  const closeTeamModal = () => {
    setIsTeamModalOpen(false);
    setSelectedTournament(null);
  };

  const openBracketModal = (tournament) => {
    if (!tournament || !tournament._id) {
      console.error("Invalid tournament data");
      return;
    }
    setSelectedTournament(tournament);
    setIsBracketModalOpen(true);
  };

  const closeBracketModal = () => {
    setIsBracketModalOpen(false);
    setSelectedTournament(null);
  };

  const handleRegisterClick = async (tournament) => {
    const isCaptain = await checkTeamCaptain();
    if (!isCaptain) {
      notify("Only team captains can register for tournaments");
      return;
    }
    setSelectedTournament(tournament);
    setIsTeamModalOpen(true);
  };

  const handleRegistrationSuccess = async (tournamentId) => {
    setRegistrations((prev) => ({
      ...prev,
      [tournamentId]: true,
    }));

    await fetchTournamentData();

    setIsTeamModalOpen(false);
    setSelectedTournament(null);
  };

  return (
    <>
      {registeredTournaments.length > 0 && <RegisteredTournamentStrip />}
      <hr className="border-t border-white/10 my-2 sm:my-3 w-full" />
      <div className={styles.tournamentsList}>
        <div className={styles.tournamentsContainer}>
          {filteredTournaments.map((tournament) => {
            return (
            <div
              key={tournament._id}
              className={styles.tournamentItem}
              style={{
                backgroundImage: `url(${
                  backgroundImages[tournament.division]
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className={styles.overlay}></div>
              <div className={styles.tournamentTitle}>
                <h2>{tournament.name}</h2>
                <div className={styles.tournamentInfo}>
                  <p>
                    Teams: {tournament.teams.length} / {tournament.maxTeams}
                    {tournament.teams.length < tournament.maxTeams && (
                      <span className="ml-2 text-green-400">
                        ({tournament.maxTeams - tournament.teams.length} spots
                        left)
                      </span>
                    )}
                  </p>
                  <p>Prizepool: {tournament.cashprize} REKT</p>
                  {registrations[tournament._id] ? (
                    <div>
                      {tournament.matches.map((match, index) => (
                        <button
                          key={`${match.matchId}-${index}`}
                          className={styles.seed}
                          onClick={() => openChatModal()}
                        >
                          <img
                            src={chat}
                            alt="Copy icon"
                            className={styles.copySeed}
                          />
                        </button>
                      ))}
                      {tournament.isRegistrationClosed && (
                        <button
                          className={styles.bracketButton}
                          onClick={() => openBracketModal(tournament)}
                        >
                          Bracket
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className={styles.entryFee}>
                      {!tournament.isRegistrationClosed &&
                        !registrations[tournament._id] && (
                          <button
                            className={styles.entryFeeButton}
                            onClick={() => handleRegisterClick(tournament)}
                          >
                            {tournament.entryFee}
                          </button>
                        )}
                    </div>
                  )}
                </div>
                {notification && (
                  <div className={styles.alert}>{notification}</div>
                )}
              </div>
            </div>
        );
      })}
        </div>
      </div>
      {ReactDOM.createPortal(
        <>
          {isChatModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-75 z-[9999] flex items-center justify-center">
              <div className="w-full h-full">
                <ChatModal
                  isOpen={isChatModalOpen}
                  onClose={() => setIsChatModalOpen(false)}
                  chatType={currentChatType}
                />
              </div>
            </div>
          )}

          {isTeamModalOpen && (
            <TeamModal
              isOpen={isTeamModalOpen}
              onRequestClose={closeTeamModal}
              eth={account}
              tournament={selectedTournament}
              onRegistrationSuccess={() =>
                handleRegistrationSuccess(selectedTournament._id)
              }
            />
          )}

          {isBracketModalOpen && selectedTournament && (
            <div className={styles.bracketOverlay} onClick={closeBracketModal}>
              <div
                className={styles.bracketContent}
                onClick={(e) => e.stopPropagation()}
              >
                <TournamentBracket tournamentId={selectedTournament._id} />
              </div>
            </div>
          )}
        </>,
        document.body
      )}
    </>
  );
};

export default TournamentsList;
