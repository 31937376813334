// WalletInterface.js
import React, { useState, useEffect, useCallback } from "react";
import { isAddress, formatEther } from "ethers";
import { useWallet } from "../context/WalletContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEye,
  faEyeSlash,
  faArrowRight,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { useNotification } from "./Notifications/Notifications";
import TransakButton from "./ICO/TransakButton";

const WalletInterface = ({ onMnemonicSaved, forceShowMnemonic, pseudo }) => {
  const {
    wallet,
    SUPPORTED_TOKENS,
    getAllBalances,
    sendTransaction,
    sendToken,
  } = useWallet();

  const [balances, setBalances] = useState({
    BNB: "0", // BNB natif sur BSC
    REKT: "0",
  });
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showMnemonic, setShowMnemonic] = useState(forceShowMnemonic);
  const [mnemonicSaved, setMnemonicSaved] = useState(false);
  const [sendAmount, setSendAmount] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [selectedToken, setSelectedToken] = useState("BNB");
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");
  const { notify } = useNotification("");
  const [pressProgress, setPressProgress] = useState(0);
  const [isPressed, setIsPressed] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [isWhitepaper, setIsWhitepaper] = useState(false);

  useEffect(() => {
    const checkWhitepaper = () => {
      setIsWhitepaper(document.body.classList.contains('whitepaper-active'));
    };

    // Vérifie au montage
    checkWhitepaper();

    // Observer les changements de classe sur le body
    const observer = new MutationObserver(checkWhitepaper);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  const handleHidePrivateKey = useCallback((e) => {
    e.stopPropagation();
    setShowPrivateKey(false);
    setIsFullyLoaded(false);
    setShowProgressBar(false);
    setPressProgress(0);
    setIsPressed(false);
  }, []);

  const handlePressStart = useCallback(
    (e) => {
      if (!isFullyLoaded && !e.target.closest("button")) {
        setIsPressed(true);
        setPressProgress(0);
        setShowPrivateKey(false);
        setShowProgressBar(true);
      }
    },
    [isFullyLoaded]
  );

  const handlePressEnd = useCallback(() => {
    if (!isFullyLoaded) {
      setIsPressed(false);
      if (pressProgress < 100) {
        setShowProgressBar(false);
        setPressProgress(0);
        setShowPrivateKey(false);
      }
    }
  }, [isFullyLoaded, pressProgress]);

  useEffect(() => {
    let progressInterval;
    if (isPressed && pressProgress < 130) {
      progressInterval = setInterval(() => {
        setPressProgress((prev) => {
          const newValue = prev + 2;
          if (newValue >= 130) {
            setIsFullyLoaded(true);
            setShowProgressBar(false);
            setShowPrivateKey(true);
            return 130;
          }
          return newValue;
        });
      }, 10);
    }
    return () => clearInterval(progressInterval);
  }, [isPressed, pressProgress]);

  useEffect(() => {
    const updateBalances = async () => {
      if (wallet) {
        const newBalances = await getAllBalances();
        setBalances({
          BNB: formatEther(newBalances.BNB), // Pour BNB (natif)
          REKT: formatEther(newBalances.REKT), // Pour REKT
        });
      }
    };

    updateBalances();
    const interval = setInterval(updateBalances, 15000);
    return () => clearInterval(interval);
  }, [wallet, getAllBalances]);

  useEffect(() => {
    setShowMnemonic(forceShowMnemonic);
  }, [forceShowMnemonic]);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      notify("Copied !");
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const handleSend = async () => {
    if (!isAddress(recipientAddress)) {
      setError("Invalid address");
      return;
    }

    try {
      setIsSending(true);
      setError("");

      let tx;
      if (selectedToken === "BNB") {
        // Envoi de BNB (natif)
        tx = await sendTransaction(recipientAddress, sendAmount);
      } else {
        // Envoi de REKT
        tx = await sendToken(
          SUPPORTED_TOKENS.REKT,
          recipientAddress,
          sendAmount
        );
      }

      await tx.wait();
      setSendAmount("");
      setRecipientAddress("");
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSending(false);
    }
  };

  const handleConfirmMnemonic = () => {
    setMnemonicSaved(true);
    localStorage.setItem("mnemonicSaved", "true");
    if (onMnemonicSaved) {
      onMnemonicSaved();
    }
  };

  const accentColor = isWhitepaper ? "text-[#6387ff]" : "text-[#F4B000]";
  const gradientBorder = isWhitepaper ? "border-[#6C63FF]/20" : "border-[#F4B000]/20";
  const gradientButton = isWhitepaper 
    ? "bg-gradient-to-r from-[#6C63FF] to-[#FF6584]" 
    : "bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]";
  const accentText = isWhitepaper ? "text-[#6387ff]" : "text-[#F4B000]";
  const borderGradient = isWhitepaper ? "border-[#6C63FF]/20" : "border-[#F4B000]/20";

  if (!mnemonicSaved && forceShowMnemonic) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-[12px] flex justify-center items-center p-4 z-50">
        <div className="w-full max-w-xl bg-[#03030369] p-10 rounded-3xl shadow-2xl animate-fade-in relative overflow-hidden backdrop-blur-[100px]">
          {/* Gradient border top */}
          <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />

          <div className="text-center">
            <h2 className="text-2xl font-bold text-[#FF6B6B] mb-2">
              ⚠️ IMPORTANT
            </h2>
            <p className="text-white/80">Save your recovery phrase</p>
          </div>

          <div className="bg-white/5 border border-[#F4B000]/20 p-4 rounded-xl relative mt-6">
            <p
              className={`text-white break-all font-mono ${
                showMnemonic ? "" : "blur-sm"
              }`}
            >
              {wallet?.mnemonic?.phrase}
            </p>
            <button
              className="absolute top-4 right-4 text-white/70 hover:text-white"
              onClick={() => setShowMnemonic(!showMnemonic)}
            >
              <FontAwesomeIcon icon={showMnemonic ? faEyeSlash : faEye} />
            </button>
          </div>

          <div className="space-y-3 text-white/70 text-sm mt-6">
            <p>
              • This phrase is the <span className="text-[#FF6B6B]">ONLY</span>{" "}
              way to recover your wallet
            </p>
            <p>• Store it in a safe place and never share it</p>
            <p>
              • Losing this phrase means permanently losing access to your funds
            </p>
          </div>

          <div className="flex gap-4 mt-6">
            <button
              onClick={() => copyToClipboard(wallet?.mnemonic?.phrase)}
              className="flex-1 py-3 bg-white/5 rounded-xl text-white hover:bg-white/10 transition-colors"
            >
              <FontAwesomeIcon icon={faCopy} className="mr-2" />
              Copy
            </button>
            <button
              onClick={handleConfirmMnemonic}
              className="flex-1 py-3 bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] rounded-xl text-white hover:opacity-90 transition-opacity"
            >
              I have saved my phrase
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full m-auto">
      <hr className="border-t border-white/10 w-full" />
      <div className="p-5 rounded-3xl relative overflow-hidden">
        {/* Header */}
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-3">
            <FontAwesomeIcon
              icon={faWallet}
              className={`text-xl w-10 h-10 ${accentColor}`}
            />
          </div>
          <div className="text-right space-y-1">
            <div>
              <div className="text-xs text-white/70">Balance BNB</div>
              <div className={`text-sm font-bold ${accentText}`}>
                {Number(balances.BNB).toFixed(5)} BNB
              </div>
            </div>
            <div>
              <div className="text-xs text-white/70">Balance REKT</div>
              <div className={`text-sm font-bold ${accentText}`}>
                {Number(balances.REKT).toFixed(5)} REKT
              </div>
            </div>
          </div>
        </div>

        {/* Send Form */}
        <div className="space-y-3 mt-4">
          <div>
            <select
              value={selectedToken}
              onChange={(e) => setSelectedToken(e.target.value)}
              className={`w-full bg-white/5 border ${gradientBorder} rounded-xl px-3 py-2 text-sm text-white mt-1 focus:outline-none focus:${borderGradient} [&>*]:bg-[#000000b7] appearance-none cursor-pointer`}
            >
              <option value="BNB">BNB</option>
              <option value="REKT">REKT</option>
            </select>
          </div>

          <div>
            <input
              type="text"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
              className={`w-full bg-white/5 border ${borderGradient} rounded-xl px-3 py-2 text-sm text-white mt-0 focus:outline-none focus:${borderGradient}`}
              placeholder="BSC Address"
            />
          </div>

          <div>
            <input
              type="number"
              value={sendAmount}
              onChange={(e) => setSendAmount(e.target.value)}
              className={`w-full bg-white/5 border ${borderGradient} rounded-xl px-3 py-2 text-sm text-white mt-0 focus:outline-none focus:${borderGradient}`}
              placeholder="Amount"
              step="0.00001"
            />
          </div>

          {error && <div className="text-red-500 text-xs">{error}</div>}

          <button
            onClick={handleSend}
            disabled={isSending || !sendAmount || !recipientAddress}
            className={`w-full py-2 ${gradientButton} rounded-xl text-white text-sm hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            {isSending ? "Sending..." : "Send"}
          </button>

          <div>
            <TransakButton
              onSuccess={(orderId) => {
                getAllBalances();
              }}
            />
          </div>
        </div>

        {/* Wallet Info */}
        <div className="space-y-3 mt-6">
          <div className={`bg-white/5 border ${borderGradient} p-3 rounded-xl`}>
            <div className="text-xs text-white/70 mb-1">Address</div>
            <div className="flex items-center justify-between">
              <button
                onClick={() => copyToClipboard(wallet?.address)}
                className="text-white/70 hover:text-white truncate hover:scale-[1.01] transition-transform"
              >
                <div className="text-white text-sm font-mono truncate">
                  {wallet?.address}
                </div>
              </button>
            </div>
          </div>

          {/* Private Key Section */}
          <div
            className={`relative bg-white/5 border ${borderGradient} p-3 rounded-xl select-none overflow-hidden ${
              !isFullyLoaded ? "cursor-pointer" : ""
            }`}
            onMouseDown={handlePressStart}
            onMouseUp={handlePressEnd}
            onMouseLeave={handlePressEnd}
            onTouchStart={handlePressStart}
            onTouchEnd={handlePressEnd}
          >
            {/* Barre de progression avec état séparé */}
            {showProgressBar && (
              <div
                className="absolute left-0 top-0 h-full bg-gradient-to-r from-green-500/20 to-green-400/20"
                style={{
                  width: `${pressProgress}%`,
                  transition: "width 0.05s linear",
                }}
              />
            )}

            <div className="relative z-10">
              <div className="flex justify-between items-center mb-1">
                <div className="text-xs text-white/70">Private Key</div>
                {isFullyLoaded && (
                  <button
                    onClick={handleHidePrivateKey}
                    className="text-white/70 hover:text-white text-xs"
                  >
                    <FontAwesomeIcon icon={faEyeSlash} className="mr-1" />
                  </button>
                )}
              </div>
              <div className="flex items-center justify-between">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (showPrivateKey) {
                      copyToClipboard(wallet?.privateKey);
                    }
                  }}
                  className={`text-white text-sm font-mono truncate ${
                    showPrivateKey
                      ? "text-white/70 hover:text-white hover:scale-[1.01] transition-transform"
                      : "blur-sm pointer-events-none"
                  }`}
                >
                  {wallet?.privateKey}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletInterface;
