import React, { useState, useEffect, useCallback } from "react";
import { getLiveStreams } from "../services/twitchService";
import styles from "../styles/MatchCard.module.css";

const TwitchLiveStreams = ({ channels, onLiveStatusChange, isExpanded }) => {
  const [liveStreams, setLiveStreams] = useState([]);

  // const fetchLiveStreams = useCallback(async () => {
  //   try {
  //     const streams = await getLiveStreams(channels);
  //     setLiveStreams(streams);
  //     const isLive = streams.length > 0;
  //     onLiveStatusChange(isLive);
  //   } catch (error) {
  //     console.error("Error fetching live streams:", error);
  //     onLiveStatusChange(false);
  //   }
  // }, [channels, onLiveStatusChange]);

  const fetchLiveStreams = useCallback(async () => {
    try {
      if (channels.some(channel => !channel)) return;

      const streams = await getLiveStreams(channels);
      setLiveStreams(streams);
      const isLive = streams.length > 0;
      onLiveStatusChange(isLive);
    } catch (error) {
      console.error("Error fetching live streams:", error);
      onLiveStatusChange(false);
    }
  }, [channels, onLiveStatusChange]);

  useEffect(() => {
    fetchLiveStreams();

    const intervalId = setInterval(fetchLiveStreams, 60000);

    return () => clearInterval(intervalId);
  }, [fetchLiveStreams]);

  return (
    <div className={styles.liveStream}>
      {liveStreams.length > 0 ? (
        liveStreams.map((stream) => (
              <iframe
                key={stream.id}
                src={`https://player.twitch.tv/?channel=${stream.user_login}&parent=${window.location.hostname}`}
                className="w-full h-full"
                allowFullScreen
                title={`${stream.user_login} est en live.`}
                style={{
                  backgroundColor: "black",
                  display: "block",
                }}
              />
        ))
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default React.memo(TwitchLiveStreams);