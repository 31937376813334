import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosConfig";
import { useNotification } from "./Notifications/Notifications";
import styles from "../styles/TeamModal.module.css";
import ProfileModal from "./Profile/ModalProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faSignOutAlt,
  faCamera,
  faUserMinus,
} from "@fortawesome/free-solid-svg-icons";

const TeamGestionModal = ({ isOpen, onRequestClose, eth, openTeamChat }) => {
  const [team, setTeam] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [inviteMemberAddress, setInviteMemberAddress] = useState("");
  const [inviteMemberRole, setInviteMemberRole] = useState("member");
  const [errorMessage, setErrorMessage] = useState("");
  const [, setIsLoading] = useState(true);
  const [teamDescription, setTeamDescription] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isTeamNameValid, setIsTeamNameValid] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [rankings, setRankings] = useState({
    rank: "N/A",
    tournamentElo: 0,
  });
  const { notify } = useNotification();

  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const bannerInputRef = useRef(null);
  const logoInputRef = useRef(null);

  useEffect(() => {
    const checkIfTeamExists = async (eth) => {
      setIsLoading(true);
      try {
        const res = await axiosInstance.get(`/api/users/eth/${eth}`);
        if (res.data) {
          const teamRes = await axiosInstance.get(
            `/api/teams/${res.data.team}/members/${eth}`
          );
          if (teamRes.data) {
            setTeam(teamRes.data);
            fetchRankings(teamRes.data._id);
          }
        }
      } catch (err) {
        console.error("Error checking if team exists:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (eth) {
      checkIfTeamExists(eth);
    }
  }, [eth]);

  const fetchRankings = async (teamId) => {
    try {
      const response = await axiosInstance.get(
        "/api/leaderboards/rankingsTeam"
      );
      const { rankings } = response.data;

      const teamRanking = rankings.find((t) => t.teamId === teamId);
      setRankings({
        rank: teamRanking?.rank || "N/A",
        betElo: teamRanking?.betElo || 0,
      });
    } catch (error) {
      console.error("Error fetching rankings:", error);
    }
  };

  const handleKickMember = async (memberId) => {
    try {
      const response = await axiosInstance.post(`/api/teams/${team._id}/kick`, {
        memberId,
        captainId: team.captain,
      });

      if (response.data.team) {
        setTeam(response.data.team);
        notify("Member kicked successfully", "success");
      }
    } catch (error) {
      notify(error.response?.data?.message || "Error kicking member", "error");
    }
  };

  const handleKickConfirmation = (member) => {
    if (
      window.confirm(
        `Are you sure you want to kick ${member.user.name} from the team?`
      )
    ) {
      handleKickMember(member.user._id);
    }
  };

  const handleFileSelect = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    const validTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      notify("Non supported file. Use JPG, PNG or GIF.", "error");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      notify("Maximum 5MB", "error");
      return;
    }

    try {
      if (type === "banner") {
        setIsUploadingBanner(true);
      } else {
        setIsUploadingLogo(true);
      }

      const formData = new FormData();
      formData.append("image", file);
      formData.append("type", type);

      const response = await axiosInstance.post(
        `/api/teams/${team._id}/upload-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setTeam((prevTeam) => ({
          ...prevTeam,
          [type === "banner" ? "bannerUrl" : "logoUrl"]: response.data.url,
        }));
        notify("Success", "success");
      }
    } catch (error) {
      console.error("Erreur upload:", error);
      notify(error.response?.data?.message || "Error during upload", "error");
    } finally {
      if (type === "banner") {
        setIsUploadingBanner(false);
      } else {
        setIsUploadingLogo(false);
      }
    }
  };

  const validateTeamNameFormat = (name) => {
    return (
      name.length >= 3 && name.length <= 20 && /^[a-zA-Z0-9 ]+$/.test(name)
    );
  };

  const handleCreateTeam = async () => {
    if (!validateTeamNameFormat(teamName)) {
      setIsTeamNameValid(false);
      setErrorMessage(
        "Le nom de l'équipe doit avoir entre 3 et 20 caractères et ne contenir que des lettres, des chiffres et des espaces."
      );
      return;
    }

    try {
      const response = await axiosInstance.post("/api/teams", {
        name: teamName,
        description: teamDescription,
        captainId: eth,
        address: eth,
      });
      const team = response.data.team;

      notify("Team created !");
      setTeam(team);
      setTeamName("");
      setTeamDescription("");
      setErrorMessage("");
      if (window.chatModalComponent) {
        window.chatModalComponent.refreshChatModal();
      }
      onRequestClose();
    } catch (error) {
      console.error("Erreur lors de la création de l'équipe:", error);
      setErrorMessage(
        error.response?.data?.message ||
          "Erreur lors de la création de l'équipe"
      );
    }
  };

  // Dans TeamGestionModal.js
  const handleInviteMember = async () => {
    if (!inviteMemberAddress || !team?._id) {
      return;
    }

    try {
      // Validation basique du format d'adresse ethereum
      console.log(eth, inviteMemberAddress, team, inviteMemberRole);
      const response = await axiosInstance.post("/api/invitations/invite", {
        from: eth,
        to: inviteMemberAddress,
        team: team._id,
        role: inviteMemberRole || "member",
      });

      if (response.data) {
        notify("Success !", "success");
        setInviteMemberAddress("");
        setInviteMemberRole("member");
      }
    } catch (error) {
      console.error("Error inviting member:", error);
      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        "Erreur lors de l'invitation. Veuillez réessayer.";
      notify(errorMessage, "error");
    }
  };

  const handleLeaveTeam = async () => {
    try {
      await axiosInstance.post(`/api/teams/${team._id}/leave`, {
        eth,
      });
      notify("You left the team.", "success");
      setTeam(null);
    } catch (error) {
      console.error("Erreur lors de la sortie de l'équipe:", error.message);
    }
  };

  const handleLeaveTeamConfirmation = () => {
    if (window.confirm("Êtes-vous sûr de vouloir quitter l'équipe ?")) {
      handleLeaveTeam();
    }
  };

  const handleDeleteTeamConfirmation = () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer l'équipe ?")) {
      handleDeleteTeam();
    }
  };

  const handleDeleteTeam = async () => {
    try {
      await axiosInstance.delete(`/api/teams/${team._id}`);
      notify("Team deleted.", "success");
      setTeam(null);
    } catch (error) {
      console.error(
        "Erreur lors de la suppression de l'équipe:",
        error.message
      );
    }
  };

  const handleEditDescription = () => {
    setTeamDescription(team.description || "");
    setIsEditingDescription(true);
  };

  const handleSaveDescription = async () => {
    try {
      await axiosInstance.put(`/api/teams/${team._id}/description`, {
        description: teamDescription,
      });
      setTeam((prevTeam) => ({
        ...prevTeam,
        description: teamDescription,
      }));
      setIsEditingDescription(false);
      notify("Updated !", "success");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour de la description:",
        error.message
      );
    }
  };

  const handleMemberClick = (member, event) => {
    event.stopPropagation();
    setSelectedMember(member);
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
    setSelectedMember(null);
  };

  const handleOpenTeamChat = () => {
    openTeamChat();
  };

  return (
    <>
      <div
        className={styles.customTeamModal}
        style={{ display: isOpen ? "flex" : "none" }}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            onRequestClose();
          }
        }}
      >
        <div className={styles.modalContent}>
          <div className={styles.teamDashboard}>
            <div
              className={styles.teamBanner}
              style={{
                backgroundImage: `url(${team?.bannerUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {team?.address === eth && (
                <div className={styles.imageControls}>
                  <button
                    className={styles.imageUploadButton}
                    onClick={() => bannerInputRef.current.click()}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                    <span>Update Banner</span>
                  </button>
                  {isUploadingBanner && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                    </div>
                  )}
                </div>
              )}
              <input
                ref={bannerInputRef}
                type="file"
                accept="image/*"
                onChange={(e) => handleFileSelect(e, "banner")}
                style={{ display: "none" }}
              />

              <div className={styles.teamBannerContent}>
                <div
                  className={styles.teamDashboardLogo}
                  style={{
                    backgroundImage: `url(${team?.logoUrl || null})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {team?.address === eth && (
                    <div className={styles.logoControls}>
                      <button
                        className={styles.imageUploadButton}
                        onClick={() => logoInputRef.current.click()}
                      >
                        <FontAwesomeIcon icon={faCamera} />
                        {isUploadingLogo && (
                        <div className=" inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                        </div>
                      )}
                      </button>

                    </div>
                  )}
                  <input
                    ref={logoInputRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileSelect(e, "logo")}
                    style={{ display: "none" }}
                  />
                </div>

                <div className={styles.teamDashboardInfo}>
                  <h1 className={styles.teamDashboardName}>
                    {team ? team.name : "Create Team"}
                  </h1>
                  <div className={styles.teamDashboardMeta}>
                    {team && (
                      <>
                        <span>
                          Created:{" "}
                          {team.createdAt &&
                            new Date(team.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                        </span>
                        <span>•</span>
                        <span>Members: {team.members?.length || 0}</span>
                        <span>•</span>
                        <span>Division: Pro</span>
                        <span>•</span>
                        <span>Rank: #{rankings.rank || "N/A"}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.teamContent}>
              {team ? (
                <>
                  <div className={styles.teamMain}>
                    <div className={styles.teamDescription}>
                      <h2>About Our Team</h2>
                      {isEditingDescription ? (
                        <div className="flex flex-row gap-4 items-start">
                          <textarea
                            value={teamDescription}
                            placeholder="Description..."
                            onChange={(e) => setTeamDescription(e.target.value)}
                            maxLength={300}
                            className={`flex-1 resize-none bg-transparent outline-none ${styles.textarea}`}
                          />
                          {team.address === eth && (
                            <button
                              onClick={handleSaveDescription}
                              className={styles.teamActionBtn}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="flex min-h-[150px]">
                          <p className="flex-1 break-words whitespace-pre-line">
                            {team.description || "Add a team description..."}
                          </p>
                          {team.address === eth && (
                            <button
                              onClick={handleEditDescription}
                              className={styles.teamActionBtn}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="h-full w-[1px] bg-[#8080802f] mx-auto"></div>
                    <div className={styles.teamMembersList}>
                      <h2>Team Members</h2>
                      <div className="overflow-auto max-h-[250px]">
                        {team.members?.map(
                          (member) =>
                            member.user && (
                              <div
                                key={member.user._id}
                                className={styles.teamMemberCard}
                                onClick={(e) =>
                                  handleMemberClick(member.user, e)
                                }
                              >
                                <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center relative">
                                  {member.user.imageUrl ? (
                                    <>
                                      <img
                                        src={member.user.imageUrl}
                                        alt={`${member.user.name}`}
                                        className="w-[50px] h-[50px] rounded-full object-cover z-10 relative"
                                        onError={(e) => {
                                          e.target.style.display = "none";
                                          e.target.parentElement.querySelector(
                                            ".fallback-initial"
                                          ).style.display = "flex";
                                        }}
                                      />
                                      <span
                                        className={`fallback-initial hidden absolute inset-0 ${styles.memberInitial}`}
                                      >
                                        {member.user.name[0].toUpperCase()}
                                      </span>
                                    </>
                                  ) : (
                                    <span className={styles.memberInitial}>
                                      {member.user.name[0].toUpperCase()}
                                    </span>
                                  )}
                                </div>
                                <div className={styles.memberInfo}>
                                  <h3 className="flex">
                                    {member.user.name}{" "}
                                    <div>
                                    <h3 className="bg-[#2279ca] ml-2 w-5 h-5 rounded-full flex justify-center items-center !text-[10px] font-mono">
                                      {member.user.experience.level}
                                    </h3>
                                    </div>
                                  </h3>
                                  <p>{member.role}</p>
                                </div>
                                {eth === member.user.eth && (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleLeaveTeamConfirmation();
                                    }}
                                    className={styles.leaveButton}
                                  >
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                  </button>
                                )}
                                {team.address === eth &&
                                  member.user._id !== team.captain && (
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleKickConfirmation(member);
                                      }}
                                      className={`${styles.leaveButton} text-red-500`}
                                      title="Kick member"
                                    >
                                      <FontAwesomeIcon icon={faUserMinus} />
                                    </button>
                                  )}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>

                  <aside className={styles.teamSidebar}>
                    <div className={styles.teamActions}>
                      {team.address === eth && (
                        <div className={styles.inviteMemberForm}>
                          <input
                            type="text"
                            placeholder="Address"
                            value={inviteMemberAddress}
                            onChange={(e) =>
                              setInviteMemberAddress(e.target.value)
                            }
                          />
                          <button
                            onClick={handleInviteMember}
                            className={styles.teamActionBtn}
                          >
                            +
                          </button>
                        </div>
                      )}
                      <button
                        className={`${styles.teamActionBtn} ${styles.teamActionBtnChat}`}
                        onClick={handleOpenTeamChat}
                      >
                        Team Chat
                      </button>
                      <button className={styles.teamActionBtn}>
                        Tournaments
                      </button>
                      {team.address === eth && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTeamConfirmation();
                          }}
                          className={`${styles.teamActionBtn} ${styles.deleteTeamBtn}`}
                        >
                          Delete Team
                        </button>
                      )}
                    </div>
                  </aside>
                </>
              ) : (
                <div className={styles.createTeamModal}>
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      placeholder="Team name"
                      value={teamName}
                      onChange={(e) => {
                        setTeamName(e.target.value);
                        setIsTeamNameValid(
                          validateTeamNameFormat(e.target.value)
                        );
                      }}
                      className={styles.input}
                    />
                    {!isTeamNameValid && (
                      <span className={styles.errorMessage}>
                        Between 3 and 20 characters. <br /> Only letters,
                        numbers and spaces.
                      </span>
                    )}
                  </div>
                  <button
                    onClick={handleCreateTeam}
                    className={styles.teamActionBtn}
                    disabled={!isTeamNameValid}
                  >
                    Create Team
                  </button>
                </div>
              )}
            </div>
            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
      {selectedMember && isProfileModalOpen && (
        <ProfileModal
          isOpen={isProfileModalOpen}
          onRequestClose={closeProfileModal}
          type="user"
          id={selectedMember._id}
          userId={eth}
        />
      )}
    </>
  );
};

export default TeamGestionModal;
