import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { parseUnits, Contract } from "ethers";
import { useNotification } from "./Notifications/Notifications";
import gameSettings from "./config/gameSettings";
import mainTournamentABI from "./ABI/mainTournament";
import rektABI from "./ABI/Rekt";
import styles from "../styles/TeamModal.module.css";
import { useWallet } from "../context/WalletContext.js";
const { subContractABI } = require("./ABI/subTournament.js");

const TeamModal = ({
  isOpen,
  onRequestClose,
  eth,
  tournament,
  onRegistrationSuccess,
}) => {
  const [team, setTeam] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [isTeamNameValid, setIsTeamNameValid] = useState(true);
  const { notify } = useNotification();
  const { wallet, provider: walletProvider, account } = useWallet();

  const TOURNAMENT_ADDRESS =
    process.env.REACT_APP_MAIN_TOURNAMENT_CONTRACT_ADDRESS;
  const REKT_ADDRESS = process.env.REACT_APP_REKT_CONTRACT_ADDRESS;

  const validateTeamNameFormat = (name) => {
    return (
      name.length >= 3 && name.length <= 20 && /^[a-zA-Z0-9 ]+$/.test(name)
    );
  };

  const handleCreateTeam = async () => {
    if (!validateTeamNameFormat(teamName)) {
      setIsTeamNameValid(false);
      setErrorMessage(
        "The team name must be between 3 and 20 characters and contain only letters, numbers and spaces."
      );
      return;
    }

    try {
      const response = await axiosInstance.post("/api/teams", {
        name: teamName,
        description: teamDescription,
        captainId: account,
        address: account,
      });
      const team = response.data.team;

      notify("Success !");
      setTeam(team);
      setTeamName("");
      setTeamDescription("");
      setErrorMessage("");
      onRequestClose();
    } catch (error) {
      console.error("Erreur lors de la création de l'équipe:", error);
      console.log("Détails de l'erreur:", error.transactionHash, error.receipt);
      setErrorMessage(
        error.response?.data?.message ||
          "Erreur lors de la création de l'équipe"
      );
    }
  };

  useEffect(() => {
    const checkIfTeamExists = async (eth) => {
      try {
        console.log("Checking if team exists for user:", eth);
        const res = await axiosInstance.get(`/api/users/eth/${eth}`);
        console.log("User data:", res.data.team);
        if (res.data) {
          const teamRes = await axiosInstance.get(
            `/api/teams/${res.data.team}/members/${eth}`
          );
          console.log("User has a team:", teamRes.data);
          setTeam((prevTeam) =>
            prevTeam !== teamRes.data ? teamRes.data : prevTeam
          );
        } else {
          console.log("User does not have a team.");
          setTeam(null);
        }
      } catch (err) {
        console.error("Error checking if team exists:", err);
      }
    };

    checkIfTeamExists(eth);
  }, [eth]);

  const handleMemberSelect = (memberId) => {
    const selectedMember = team.members.find(
      (member) => member.user._id === memberId
    );
    const isCoachSelected = selectedMembers.some(
      (memberId) =>
        team.members.find((member) => member.user._id === memberId)?.role ===
        "coach"
    );

    if (
      selectedMember.role === "coach" &&
      isCoachSelected &&
      !selectedMembers.includes(memberId)
    ) {
      notify("Il ne peut y avoir qu'un seul coach par équipe.");
      return;
    }

    setSelectedMembers((prev) => {
      // Vérifie si le membre est déjà sélectionné
      const alreadySelected = prev.some(
        (member) => member.user?._id === memberId
      );

      if (alreadySelected) {
        // Si déjà sélectionné, retirer
        return prev.filter((member) => member.user._id !== memberId);
      } else {
        // Sinon, ajouter l'objet `selectedMember` à la sélection
        return [...prev, selectedMember];
      }
    });
  };

  const getContract = (address, abi) => {
    if (!address || !wallet) return null;

    try {
      // Utiliser directement le wallet qui a déjà le provider
      return new Contract(address, abi, wallet);
    } catch (error) {
      console.error("Error creating contract:", error);
      return null;
    }
  };

  const handleRegister = async (tournament) => {
    if (!wallet || !walletProvider) {
      notify("Please connect your wallet first");
      return;
    }

    const gameKey = tournament.game.toLowerCase().replace(/\s+/g, "");
    const settings = gameSettings[gameKey];

    if (!tournament || !tournament.game) {
      console.error("Tournament data is missing or incorrect:", tournament);
      notify("Tournament data is missing or incorrect.");
      return;
    }

    if (selectedMembers.length === 0) {
      notify(`You need to select at least ${settings.minSize} member(s).`);
      return;
    }

    try {
      const teamResponse = await axiosInstance.get(
        `/api/teams/${team._id}/members`
      );
      const teamData = teamResponse.data;

      const selectedMembersData = teamData.members.filter((member) =>
        selectedMembers.some((selected) => selected._id === member._id)
      );

      const coachCount = selectedMembersData.filter(
        (member) => member.role === "coach"
      ).length;

      const memberCount = selectedMembersData.length;
      const totalMembers = memberCount + coachCount;

      console.log({
        selectedMembers,
        selectedMembersData,
        coachCount,
        memberCount,
        totalMembers,
      });

      if (!settings) {
        notify("Invalid game settings");
        return;
      }

      if (memberCount < settings.minSize) {
        notify(
          `You need at least ${settings.minSize} members to register for this tournament.`
        );
        return;
      }

      if (coachCount > 1) {
        notify("Team cannot have more than one coach.");
        return;
      }

      if (coachCount === 1 && totalMembers > settings.maxSizeWithCoach) {
        notify(
          `Team cannot have more than ${settings.maxSizeWithCoach} members with a coach.`
        );
        return;
      }

      if (coachCount === 0 && memberCount > settings.maxSizeWithoutCoach) {
        notify(
          `Team cannot have more than ${settings.maxSizeWithoutCoach} members without a coach.`
        );
        return;
      }

      const checkRegistrationResponse = await axiosInstance.get(
        `/api/tournaments/${tournament._id}/teams/${teamData._id}`,
        {
          params: {
            address: teamData.address,
          },
        }
      );

      if (checkRegistrationResponse.data.isRegistered) {
        notify("Votre équipe est déjà inscrite à ce tournoi.");
        return;
      }

      setIsRegistering(true);
      console.log("Starting registration process with wallet:", wallet.address);
      const tournamentContract = getContract(
        TOURNAMENT_ADDRESS,
        mainTournamentABI
      );
      const rektContract = getContract(REKT_ADDRESS, rektABI);
      const subContract = getContract(
        tournament.subContractAddress,
        subContractABI
      );

      if (!tournamentContract || !rektContract || !subContract) {
        throw new Error("Failed to initialize contracts");
      }

      // Check if team is already registered
      const teamR = await subContract.managerTeam(team.address);
      if (teamR.isRegistered) {
        notify("Team is already registered for this tournament");
        return;
      }

      // Convert entry fee to proper format
      const entryFeeInRekt = parseUnits(tournament.entryFee.toString(), 18);

      try {
        const approveTx = await rektContract.approve(
          TOURNAMENT_ADDRESS,
          entryFeeInRekt
        );
        await approveTx.wait();

        // Verify allowance
        await rektContract.allowance(
          wallet.address,
          TOURNAMENT_ADDRESS
        );

        const playerAddresses = selectedMembers.map(
          (member) => member.user.eth
        );

        // Register team in backend
        const prepareResponse = await axiosInstance.post(
          `/api/tournaments/${tournament._id}/teams/prepare-registration`,
          {
            address: team.address,
            teamId: team._id,
            members: selectedMembers.map((m) => ({
              user: m.user._id,
              role: m.role,
            })),
          }
        );

        if (!prepareResponse.data.success) {
          throw new Error("Failed to prepare registration");
        }

        const registerTx = await tournamentContract.registerTeam(
          tournament.subContractAddress,
          playerAddresses
        );
        const receipt = await registerTx.wait();

        // Confirm registration in backend
        await axiosInstance.post(
          `/api/tournaments/${tournament._id}/teams/confirm-registration`,
          {
            address: team.address,
            teamId: team._id,
            members: selectedMembers.map((m) => ({
              user: m.user._id,
              role: m.role,
            })),
            transactionHash: receipt.hash,
          }
        );

        notify("Successfully registered!");

        if (onRegistrationSuccess) {
          onRegistrationSuccess();
        }

        onRequestClose();
      } catch (txError) {
        console.error("Transaction error:", txError);
        throw txError;
      }
    } catch (error) {
      console.error("Registration error:", error);
      notify(error.message || "Registration failed");
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <div
      className={styles.customTeamModal}
      style={{ display: isOpen ? "flex" : "none" }}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onRequestClose();
        }
      }}
    >
      <div className="p-3">
        <div className={styles.modalContent2}>
          {team ? (
            <>
              <h3 className="text-2xl font-semibold text-center mb-4 text-white">
                {team.name}
              </h3>
              <hr className="border-t border-white/60 my-3 w-full" />

              <div className={styles.membersContainer}>
                <div className={styles.selectMembersList}>
                  {team.members &&
                    team.members.map((member) => (
                      <div
                        key={member.user._id}
                        className={`${styles.selectMember} ${
                          selectedMembers.some(
                            (selected) => selected.user._id === member.user._id
                          )
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() => handleMemberSelect(member.user._id)}
                      >
                        <div className={styles.memberImageContainer}>
                          {member.user.imageUrl ? (
                            <img
                              src={member.user.imageUrl}
                              alt={`${member.user.name}`}
                              className={styles.memberImage}
                              onError={(e) => {
                                e.target.style.display = "none";
                                e.target.parentElement.innerHTML = `<div class="${
                                  styles.memberInitial
                                }">${member.user.name[0].toUpperCase()}</div>`;
                              }}
                            />
                          ) : (
                            <div className={styles.memberInitial}>
                              {member.user.name[0].toUpperCase()}
                            </div>
                          )}
                        </div>

                        <div className={styles.memberInfo2}>
                          <span>{member.user.name}</span>
                          <span>
                            {member.role.charAt(0).toUpperCase() +
                              member.role.slice(1)}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="px-8 pb-4">
                {tournament._id && (
                  <button
                    onClick={() => handleRegister(tournament)}
                    disabled={isRegistering}
                    className={styles.registerTeamButton}
                  >
                    {isRegistering ? "Registering..." : "Play !"}
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className={styles.createTeamModal}>
              <form controlId="formTeamName">
                <input
                  type="text"
                  placeholder="Team name"
                  value={teamName}
                  onChange={(e) => {
                    setTeamName(e.target.value);
                    setIsTeamNameValid(validateTeamNameFormat(e.target.value));
                  }}
                  className={`${styles.createTeamForm} ${
                    !isTeamNameValid ? styles.invalid : ""
                  }`}
                  aria-invalid={!isTeamNameValid}
                />
                {!isTeamNameValid && (
                  <span className={styles.errorMessage}>
                    Between 3 and 20 characters. <br /> Only letters, numbers
                    and spaces.
                  </span>
                )}
              </form>
              <button
                onClick={handleCreateTeam}
                className={styles.createTeamButton}
                disabled={!isTeamNameValid}
              >
                Create
              </button>
            </div>
          )}
        </div>
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

export default TeamModal;
