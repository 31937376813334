import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faTrophy,
  faCoins,
  faStore,
  faDice,
  faLock,
  faPowerOff,
  faUsers,
  faTimes,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import logo from "./REKT_Logo.webp";
import logoBleu from "./logoBleu.webp";

// Components
import LandingPage from "./components/Home/LandingPage";
import Esport from "./components/Tournament";
import Bet from "./components/Bet/Bet";
import Marketplace from "./components/Marketplace/Marketplace";
import AdminPage from "./components/Admin/AdminPage";
import AdminRoute from "./components/AdminRoute";
import CachedImage from "./components/CachedImage";
import WalletConnector from "./components/WalletConnector";
import { useWallet } from "./context/WalletContext";
import ChatButton from "./components/ChatButton";
import ChatModal from "./components/ChatModal";
import TeamGestionModal from "./components/TeamGestionModal";
import { ToastContainer } from "react-toastify";
import NotificationButton from "./components/Notifications/NotificationsButton";
import axiosInstance from "./axiosConfig";
import WhitePaper from "./components/Whitepaper/Whitepaper";
import Staking from "./components/Staking/StakingInterface";
import MetaMaskTutorial from "./components/Home/TutorialMetamask";
import AffiliateRewardsPage from "./components/Home/Affiliation";
import AffiliateDashboard from "./components/Home/Affiliate";
import NotFound from "./NotFound";

const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;
const SUB_ADMIN_ADDRESSES = process.env.REACT_APP_SUB_ADMIN_ADDRESSES;

function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [currentMatchId, setCurrentMatchId] = useState(null);
  const [currentChatType, setCurrentChatType] = useState("general");
  const { account, disconnect } = useWallet();
  const [isAdmin, setIsAdmin] = useState(false);
  const [profile, setProfile] = useState(null);
  const [, setIsModalOpen] = useState(false);
  const [isTeamGestionModalOpen, setIsTeamGestionModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [lastSeenMessages, setLastSeenMessages] = useState([]);
  const [currentLogo, setCurrentLogo] = useState(logo);

  useEffect(() => {
    const updateLogo = () => {
      // Vérifie si nous sommes sur la page whitepaper
      if (document.body.classList.contains("whitepaper-active")) {
        setCurrentLogo(logoBleu);
      } else {
        setCurrentLogo(logo);
      }
    };

    // Met à jour le logo initialement
    updateLogo();

    // Observer les changements de classe sur le body
    const observer = new MutationObserver(updateLogo);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });

    // Nettoyage
    return () => observer.disconnect();
  }, []);

  const handleEveryoneMention = (messageId, messages) => {
    if (!isChatOpen) {
      // On vérifie si les messages actuels sont différents de ceux qu'on avait à la fermeture
      const hasNewMention = messages.some(
        (msg) => !lastSeenMessages.includes(msg._id)
      );
      if (hasNewMention) {
        setNotificationCount((prev) => prev + 1);
      }
    }
  };

  const openChat = (matchId, chatType) => {
    setCurrentMatchId(matchId);
    setCurrentChatType(chatType);
    setIsChatOpen(true);
    setNotificationCount(0);
  };

  const handleCloseChat = (currentMessages) => {
    // On stocke tous les IDs des messages au moment de la fermeture
    const messageIds = currentMessages.map((msg) => msg._id);
    setLastSeenMessages(messageIds);
    setIsChatOpen(false);
    setNotificationCount(0);
  };

  const fetchProfile = async (eth) => {
    try {
      const res = await axiosInstance.get(`/api/users/eth/${eth}`);
      if (res.data) {
        console.log("Profile found:", res.data);
        setProfile(res.data);
        setIsModalOpen(false); // Fermer le modal si on a un profil
      }
    } catch (err) {
      console.error("Error in fetchProfile:", err.response || err);
      if (err.response && err.response.status === 404) {
        // Seulement ouvrir le modal si l'utilisateur n'existe vraiment pas
        console.log("Profile not found, opening creation modal");
        setProfile(null);
        setIsModalOpen(true);
      } else {
        // Pour les autres erreurs, ne pas considérer comme un premier login
        console.error("Error fetching profile:", err);
        setProfile(null);
      }
    }
  };

  const saveProfile = (newProfile) => {
    console.log("Saving profile:", newProfile);
    setProfile(newProfile);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const loadProfile = async () => {
      if (account) {
        console.log("Loading profile for account:", account);
        await fetchProfile(account);

        // Vérification admin
        if (
          (ADMIN_ADDRESS || SUB_ADMIN_ADDRESSES) &&
          (account === ADMIN_ADDRESS || account === SUB_ADMIN_ADDRESSES)
        ) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    };

    loadProfile();
  }, [account]);

  const openTeamGestionModal = () => {
    closeMobileMenu();
    setIsTeamGestionModalOpen(true);
  };

  const closeTeamGestionModal = () => {
    setIsTeamGestionModalOpen(false);
  };

  const openTeamChat = () => {
    setCurrentChatType("clan");
    setIsChatOpen(true);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
    // Ferme le menu étendu si ouvert
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  // Fonction pour fermer le menu mobile
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  // Gestionnaire pour les clics sur les liens
  const handleLinkClick = () => {
    closeMobileMenu();
  };

  const updateProfile = useCallback((newProfile) => {
    setProfile(newProfile);
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Router>
      <div className="App">
        <ToastContainer />
        {account && profile && (
          <>
            <NotificationButton userId={profile._id} />
            <ChatButton
              onClick={openChat}
              notificationCount={notificationCount}
            />
            <ChatModal
              isOpen={isChatOpen}
              onClose={handleCloseChat}
              matchId={currentMatchId}
              chatType={currentChatType}
              profile={profile}
              onEveryoneMention={handleEveryoneMention}
            />
          </>
        )}
        {isTeamGestionModalOpen && (
          <TeamGestionModal
            isOpen={isTeamGestionModalOpen}
            onRequestClose={closeTeamGestionModal}
            eth={account}
            openTeamChat={openTeamChat}
          />
        )}
        <WalletConnector
          onFetchProfile={fetchProfile}
          saveProfile={saveProfile}
          onProfileUpdate={updateProfile}
        />
        <nav
          className={`vertical-nav ${isExpanded ? "expanded" : ""}`}
          onMouseEnter={() => !isMobileMenuOpen && setIsExpanded(true)}
          onMouseLeave={() => !isMobileMenuOpen && setIsExpanded(false)}
        >
          <div className="burger-menu" onClick={toggleMobileMenu}>
            <FontAwesomeIcon
              icon={isMobileMenuOpen ? faTimes : faBars}
              className={`text-[#F4B000] w-[24px] h-[24px] ${
                isMobileMenuOpen ? "rotate-180" : ""
              }`}
            />
          </div>
          <div className="logo">
            <Link
              to="/"
              onClick={handleLinkClick}
              aria-label="Navigate to home page"
            >
              <CachedImage
                src={currentLogo}
                className="App-logo"
                alt="logo"
                aria-hidden="true"
              />
            </Link>
          </div>

          <div className={`nav-item ${isMobileMenuOpen ? "active" : ""}`}>
            <div>
              <Link
                className="menu-item"
                to="/"
                onClick={handleLinkClick}
                aria-label="Navigate to home page"
              >
                <FontAwesomeIcon
                  className="text-[#F4B000] w-[33px] h-[33px] pt-2 pb-2 icon"
                  icon={faHome}
                  aria-hidden="true"
                />
                <span className="navbar-text">HOME</span>
              </Link>
              {account && (
                <>
                  <Link
                    className="menu-item"
                    onClick={() => openTeamGestionModal()}
                    aria-label="Open team management interface"
                  >
                    <FontAwesomeIcon
                      className="text-[#F4B000] w-[33px] h-[33px] pt-2 pb-2 icon"
                      icon={faUsers}
                      aria-hidden="true"
                    />
                    <span className="navbar-text">TEAM</span>
                  </Link>
                  <Link
                    className="menu-item"
                    to="/versus"
                    onClick={handleLinkClick}
                    aria-label="Access tournaments section"
                  >
                    <FontAwesomeIcon
                      className="text-[#F4B000] w-[33px] h-[33px] pt-2 pb-2 icon"
                      icon={faTrophy}
                      aria-hidden="true"
                    />
                    <span className="navbar-text">TOURNAMENTS</span>
                  </Link>
                  <Link
                    className="menu-item"
                    to="/bet"
                    onClick={handleLinkClick}
                    aria-label="Access betting section"
                  >
                    <FontAwesomeIcon
                      className="text-[#F4B000] w-[33px] h-[33px] pt-2 pb-2 icon"
                      icon={faDice}
                      aria-hidden="true"
                    />
                    <span className="navbar-text">BETS</span>
                  </Link>
                  <Link
                    className="menu-item"
                    to="/staking"
                    onClick={handleLinkClick}
                    aria-label="Access staking interface"
                  >
                    <FontAwesomeIcon
                      className="text-[#F4B000] w-[33px] h-[33px] pt-2 pb-2 icon"
                      icon={faCoins}
                      aria-hidden="true"
                    />
                    <span className="navbar-text">STAKING</span>
                  </Link>
                  <Link
                    className="menu-item"
                    to="/marketplace"
                    onClick={handleLinkClick}
                    aria-label="Access marketplace"
                  >
                    <FontAwesomeIcon
                      className="text-[#F4B000] w-[33px] h-[33px] pt-2 pb-2 icon"
                      icon={faStore}
                      aria-hidden="true"
                    />
                    <span className="navbar-text">MARKETPLACE</span>
                  </Link>
                </>
              )}
            </div>
            <div className="menu-item-logout">
              {isAdmin && (
                <Link
                  className="menu-item"
                  to="/admin"
                  onClick={handleLinkClick}
                  aria-label="Access admin dashboard"
                >
                  <FontAwesomeIcon
                    className="text-[#acb1a9] w-[33px] h-[33px] pt-2 pb-2 icon"
                    icon={faLock}
                    aria-hidden="true"
                  />
                  <span className="navbar-text">ADMIN</span>
                </Link>
              )}
              {account && (
                <Link
                  to="/"
                  className="menu-item"
                  onClick={() => {
                    handleLinkClick();
                    disconnect();
                    setProfile(null);
                    setIsAdmin(false);
                    setIsChatOpen(false);
                    setIsTeamGestionModalOpen(false);
                    window.location.href = "/";
                  }}
                  aria-label="Log out from application"
                >
                  <FontAwesomeIcon
                    className="text-[#5a5a5a69] hover:text-[#F4B000]/10 w-[33px] h-[33px] pt-2 pb-2 icon"
                    icon={faPowerOff}
                    aria-hidden="true"
                  />
                  <span className="navbar-text">LOG OUT</span>
                </Link>
              )}
            </div>
          </div>
        </nav>
        <div
          className={`main-content ${isMobileMenuOpen ? "menu-open" : ""}`}
          onClick={() => isMobileMenuOpen && closeMobileMenu()}
        >
          <Routes>
            <Route path="/" element={<LandingPage profile={profile} />} />
            {account ? (
              <>
                <Route
                  path="/versus"
                  element={<Esport openChat={openChat} />}
                />
                <Route path="/bet" element={<Bet />} />
                <Route path="/staking" element={<Staking />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/metamask" element={<MetaMaskTutorial />} />
                <Route
                  path="/affiliation"
                  element={
                    profile?.affiliated ? (
                      <AffiliateDashboard />
                    ) : (
                      <AffiliateRewardsPage />
                    )
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <AdminRoute
                      element={AdminPage}
                      allowedAddress={SUB_ADMIN_ADDRESSES}
                    />
                  }
                />
              </>
            ) : (
              <>
                <Route path="*" element={<NotFound />} />
              </>
            )}
            <Route path="/whitepaper" element={<WhitePaper />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
