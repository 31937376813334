import React, { useState, useRef } from "react";
import TournamentsList from "./TournamentList";
import EsportLeaderboard from "./EsportLeaderboard";
import GameTabs from "./Bet/GameTabs";
import gameSettings from "./config/gameSettings";
import { GAME_NAMES, normalizeGameName } from "../utils/gameUtils";
import styles from "../styles/Esport.module.css";
import Particles from "../utils/particlesUtils";
import SEOManager from "./SEO/SEOManager";

const Esport = () => {
  const [game, setGame] = useState(GAME_NAMES.VALORANT);
  const nodeRef = useRef(null);

  const handleGameChange = (selectedGame) => {
    setGame(normalizeGameName(selectedGame));
  };

  const getContent = () => {
    const settings = gameSettings[game];
    if (!settings) {
      console.error(`Settings not found for game: ${game}`);
      return <div className="flex justify-center mt-10 mb-10">COMING SOON</div>;
    }

    if (game) {
      switch (game) {
        case GAME_NAMES.VALORANT:
          return <TournamentsList game={game} settings={settings} />;
        case GAME_NAMES.RL:
          return <TournamentsList game={game} settings={settings} />;
        case GAME_NAMES.LOL:
          return <TournamentsList game={game} settings={settings} />;
        case GAME_NAMES.DOTA2:
          return <TournamentsList game={game} settings={settings} />;
        case GAME_NAMES.CSGO:
          return <TournamentsList game={game} settings={settings} />;
        default:
          return <div>Select a game</div>;
      }
    }
  };

  return (
    <>
      {" "}
      <SEOManager
        title={`REKT Esport - Tournament`}
        description={`Participate in tournaments on REKT.`}
        ogTitle={`REKT Esport | Tournament`}
        ogDescription="Participate in tournaments on REKT and earn rewards while playing."
      />
      <div className="bg-[#1D1F24] min-h-screen text-white overflow-x-hidden">
        <Particles />
        <div className={`${styles.base}  min-h-screen z-1 relative`}>
          <div className={styles.container}>
            <GameTabs
              activeTab={game}
              onTabClick={handleGameChange}
              showAllTab={false}
            />
            <div className={styles.gameTabs}></div>
          </div>
          <div className={styles.container2}>
            <div className={styles.esportDiv}>
              <div ref={nodeRef}>{getContent()}</div>
            </div>
            <EsportLeaderboard game={game} />
          </div>
        </div>
        <div className="rondBizarreOrangeQuiClignote" />
      </div>
    </>
  );
};

export default Esport;
