import { useState, useEffect, useCallback } from "react";
import { parseUnits, formatUnits, Contract } from "ethers";
import { useWallet } from "../../context/WalletContext";
import stakepoolABI from "../ABI/Stakepool";
import tokenABI from "../ABI/Rekt";
import { useNotification } from "../Notifications/Notifications";

import styles from "../../styles/Staking.module.css";

export const StakingAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [targetAPR, setTargetAPR] = useState("");
  const [currentConfig, setCurrentConfig] = useState(null);
  const { account, wallet, provider } = useWallet();
  const { notify } = useNotification();

  const stakepoolAddress = process.env.REACT_APP_STAKEPOOL_CONTRACT_ADDRESS;
  const tokenContractAddress = process.env.REACT_APP_REKT_CONTRACT_ADDRESS;

  // Vérifie si l'utilisateur est admin
  const checkAdminStatus = useCallback(async () => {
    try {
      const stakingContract = new Contract(
        stakepoolAddress,
        stakepoolABI,
        wallet
      );

      const adminRole = await stakingContract.DEFAULT_ADMIN_ROLE();
      const userAddress = await wallet.getAddress();
      const adminStatus = await stakingContract.hasRole(adminRole, userAddress);

      setIsAdmin(adminStatus);
      console.log("Admin status:", adminStatus);
    } catch (error) {
      console.error("Erreur lors de la vérification du statut admin:", error);
    }
  }, [stakepoolAddress, wallet]);

  const calculateRewardsForAPR = async () => {
    try {
      const stakingContract = new Contract(
        stakepoolAddress,
        stakepoolABI,
        wallet
      );

      const totalSupply = await stakingContract.totalSupply();
      const totalStaked = formatUnits(totalSupply, 18);

      // Calculer les récompenses nécessaires pour l'APR cible
      const aprDecimal = parseFloat(targetAPR) / 100; // Convertir le pourcentage en décimal
      const rewardsNeeded = parseFloat(totalStaked) * aprDecimal;

      // Calculer le taux de récompense par seconde
      const rewardPerSecond = rewardsNeeded / (365 * 24 * 60 * 60);

      console.log("Calculs pour APR:", {
        totalStaked,
        targetAPR: targetAPR + "%",
        rewardsNeeded,
        rewardPerSecond,
      });

      return rewardsNeeded;
    } catch (error) {
      console.error("Erreur lors du calcul des récompenses:", error);
      throw error;
    }
  };

  const setupRewards = async () => {
    if (!targetAPR || parseFloat(targetAPR) <= 0) {
      notify("Veuillez entrer un APR valide");
      return;
    }

    setLoading(true);
    try {
      // Calculer le montant des récompenses nécessaire

      const stakingContract = new Contract(
        stakepoolAddress,
        stakepoolABI,
        wallet
      );

      // Convertir le montant en Wei
      const rewardsAmount = await calculateRewardsForAPR();
      console.log("Montant des récompenses à configurer:", rewardsAmount);
      const rewardAmountWei = parseUnits(rewardsAmount.toString(), 18);

      console.log(
        "Configuration des récompenses pour un APR de",
        targetAPR + "%"
      );

      // Vérifier la balance du contrat
      const tokenContract = new Contract(
        tokenContractAddress,
        tokenABI,
        wallet
      );
      const contractBalance = await tokenContract.balanceOf(stakepoolAddress);

      if (contractBalance < rewardAmountWei) {
        throw new Error(
          `Le contrat n'a pas assez de tokens. Nécessaire: ${formatUnits(
            rewardAmountWei,
            18
          )}, Disponible: ${formatUnits(contractBalance, 18)}`
        );
      } else {
        console.log(`Disponible: ${formatUnits(contractBalance, 18)}`);
      }

      try {
        // Envoi de la transaction
        const tx = await stakingContract.notifyRewardAmount(rewardAmountWei);
        console.log("Transaction envoyée:", tx.hash);

        // Attendre la confirmation d'une manière compatible avec ethers v6
        try {
          const receipt = await provider.waitForTransaction(tx.hash);
          if (receipt.status === 1) {
            console.log("Transaction confirmée!");
            notify(
              `Récompenses configurées avec succès pour un APR de ${targetAPR}%!`
            );
          } else {
            throw new Error("La transaction a échoué");
          }
        } catch (waitError) {
          console.error(
            "Erreur lors de l'attente de la confirmation:",
            waitError
          );
          throw new Error("Erreur lors de la confirmation de la transaction");
        }
      } catch (txError) {
        console.error("Erreur lors de l'envoi de la transaction:", txError);
        throw txError;
      }

      // Vérifier la nouvelle configuration
      const newConfig = await checkRewardSetup();
      if (newConfig) {
        notify(
          `Récompenses configurées avec succès!\nAPR effectif: ${newConfig.effectiveAPR.toFixed(
            2
          )}%`
        );
      } else {
        notify(
          "Récompenses configurées, mais impossible de vérifier la nouvelle configuration"
        );
      }
    } catch (error) {
      console.error("Erreur lors de la configuration des récompenses:", error);
      notify("Erreur: " + (error.message || "Une erreur est survenue"));
    } finally {
      setLoading(false);
    }
  };

  // Pour aider au débogage
  const checkRewardSetup = useCallback(async () => {
    try {
      const stakingContract = new Contract(
        stakepoolAddress,
        stakepoolABI,
        wallet
      );
      const tokenContract = new Contract(
        tokenContractAddress,
        tokenABI,
        wallet
      );

      const [rewardRate, totalSupply, periodFinish, realSupply] =
        await Promise.all([
          stakingContract.rewardRate(),
          stakingContract.totalSupply(),
          stakingContract.periodFinish(),
          tokenContract.balanceOf(stakingContract),
        ]);

      const rewardRateNumber = Number(formatUnits(rewardRate, 18));
      const totalSupplyNumber = Number(formatUnits(totalSupply, 18));
      const realSupplyNumber = Number(formatUnits(realSupply, 18));
      const rewardsPerDay = rewardRateNumber * 86400;
      const annualRewards = rewardsPerDay * 365;

      // Calcul de l'APR effectif
      const effectiveAPR =
        totalSupplyNumber > 0 ? (annualRewards / totalSupplyNumber) * 100 : 0;

      const config = {
        rewardRate: rewardRateNumber,
        rewardPerDay: rewardsPerDay,
        totalSupply: totalSupplyNumber,
        periodFinish: new Date(Number(periodFinish) * 1000),
        effectiveAPR: effectiveAPR,
        annualRewards: annualRewards,
        realSupply: realSupplyNumber,
      };

      console.log("Configuration actuelle des récompenses:", config);
      setCurrentConfig(config);
      return config;
    } catch (error) {
      console.error(
        "Erreur lors de la vérification de la configuration:",
        error
      );
      return null;
    }
  }, [stakepoolAddress, tokenContractAddress, wallet]);

  useEffect(() => {
    if (account) {
      checkAdminStatus();
      checkRewardSetup();
      const interval = setInterval(checkRewardSetup, 30000); // Vérifier toutes les 30 secondes
      return () => clearInterval(interval);
    }
  }, [account, checkRewardSetup, checkAdminStatus]);

  if (!account) {
    return <div>Veuillez connecter votre wallet</div>;
  }

  if (!isAdmin) {
    return <div>Vous n'avez pas les droits d'administration</div>;
  }

  return (
    <div className={styles.adminPanel}>
      {currentConfig && (
        <div className={styles.infoGrid}>
          <h3>Annual Configuration :</h3>
          <div className={styles.configInfo}>
            <p>
              APR: <strong>{currentConfig.effectiveAPR.toFixed(2)}%</strong>
            </p>
            <p>
              Daily Rewards:{" "}
              <strong>{currentConfig.rewardPerDay.toFixed(4)} tokens</strong>
            </p>
            <p>
              Annual Rewards:{" "}
              <strong>{currentConfig.annualRewards.toFixed(2)} tokens</strong>
            </p>
            <p>
              Staked Supply:{" "}
              <strong>{currentConfig.totalSupply.toFixed(2)} tokens</strong>
            </p>
            <p>
              Total Supply:{" "}
              <strong>{currentConfig.realSupply.toFixed(2)} tokens</strong>
            </p>
            <p>
              End in:{" "}
              <strong>{currentConfig.periodFinish.toLocaleString()}</strong>
            </p>
          </div>
        </div>
      )}

      <div className={styles.rewardSetup}>
        <div className={styles.inputGroup}>
          <input
            type="number"
            step="0.1"
            value={targetAPR}
            onChange={(e) => setTargetAPR(e.target.value)}
            placeholder="APR (%)"
            className={styles.input}
          />
          <button
            onClick={setupRewards}
            disabled={loading}
            className={styles.button}
          >
            {loading ? "Adding..." : "Add APR"}
          </button>
        </div>
      </div>
    </div>
  );
};
