// src/utils/stakepool.js
import {
  parseUnits,
  Contract,
  getBigInt,
  formatUnits,
} from "ethers";
import stakepoolABI from "../ABI/Stakepool";
import tokenABI from "../ABI/Rekt";

const stakepoolAddress = process.env.REACT_APP_STAKEPOOL_CONTRACT_ADDRESS;
const tokenContractAddress = process.env.REACT_APP_REKT_CONTRACT_ADDRESS;

const getContract = async (contractAddress, contractABI, wallet) => {
  if (!contractAddress) {
    console.error("Adresse du contrat non définie !");
    return null;
  }
  if (wallet) {
    try {
      return new Contract(contractAddress, contractABI, wallet);
    } catch (error) {
      console.error("Erreur lors de la création du contrat", error);
      return null;
    }
  } else {
    console.error("Wallet non disponible");
    return null;
  }
};

export const getWalletBalance = async (wallet) => {
  if (!wallet) {
    throw new Error("Le wallet n'est pas défini");
  }

  const tokenContract = await getContract(
    tokenContractAddress,
    tokenABI,
    wallet
  );
  const userAddress = await wallet.getAddress();

  try {
    const balance = await tokenContract.balanceOf(userAddress);
    return formatUnits(balance, 18);
  } catch (error) {
    console.error("Erreur lors de la récupération du solde du wallet", error);
    throw error;
  }
};

// Fonction pour obtenir le taux de récompense actuel
export const getRewardRate = async (wallet) => {
  try {
    const stakingContract = await getContract(
      stakepoolAddress,
      stakepoolABI,
      wallet
    );

    // Récupération des données du contrat
    const rewardRate = await stakingContract.rewardRate();
    const totalSupply = await stakingContract.totalSupply();

    // Conversion en string pour manipulation sûre
    const rewardRateStr = rewardRate.toString();
    const totalSupplyStr = totalSupply.toString();

    // Si pas de total supply, retourner 0
    if (totalSupplyStr === "0") {
      return "0";
    }

    // Calcul de l'APR
    // APR = (rewardRate * secondsInYear * 100) / totalSupply
    const secondsInYear = 365 * 24 * 60 * 60;

    try {
      // Utilisation de js natif pour les calculs
      const annualRewards = Number(rewardRateStr) * secondsInYear;
      const totalSupplyNumber = Number(formatUnits(totalSupplyStr, 18));

      if (totalSupplyNumber === 0) return "0";

      const apr = (annualRewards * 100) / totalSupplyNumber;

      // Limiter à 2 décimales
      return apr.toFixed(2);
    } catch (calculError) {
      console.error("Erreur dans le calcul de l'APR:", calculError);
      console.log("Valeurs:", {
        rewardRate: rewardRateStr,
        totalSupply: totalSupplyStr,
        secondsInYear,
      });
      return "0";
    }
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du taux de récompense",
      error
    );
    return "0";
  }
};

// Fonction pour obtenir la période de fin des récompenses
export const getRewardPeriodEnd = async (wallet) => {
  try {
    const stakingContract = await getContract(
      stakepoolAddress,
      stakepoolABI,
      wallet
    );
    const periodFinish = await stakingContract.periodFinish();
    return periodFinish.toString();
  } catch (error) {
    console.error("Erreur lors de la récupération de la période de fin", error);
    return "0";
  }
};

// Fonction pour obtenir le total staké
export const getTotalStaked = async (wallet) => {
  try {
    const stakingContract = await getContract(
      stakepoolAddress,
      stakepoolABI,
      wallet
    );
    const total = await stakingContract.totalSupply();
    return formatUnits(total, 18);
  } catch (error) {
    console.error("Erreur lors de la récupération du total staké", error);
    return "0";
  }
};

// Fonction pour obtenir le solde staké
export const getStakedBalance = async (wallet) => {
  if (!wallet) {
    throw new Error("Le wallet n'est pas défini");
  }

  const stakingContract = await getContract(
    stakepoolAddress,
    stakepoolABI,
    wallet
  );
  const userAddress = await wallet.getAddress();

  try {
    const balance = await stakingContract.balanceOf(userAddress);
    return formatUnits(balance, 18);
  } catch (error) {
    console.error("Erreur lors de la récupération du solde staké", error);
    throw error;
  }
};

// Fonction pour obtenir les récompenses en attente
export const getPendingRewards = async (wallet) => {
  try {
    const stakingContract = await getContract(
      stakepoolAddress,
      stakepoolABI,
      wallet
    );
    const address = await wallet.getAddress();
    const rewards = await stakingContract.earned(address);
    return formatUnits(rewards, 18);
  } catch (error) {
    console.error("Erreur lors de la récupération des récompenses", error);
    return "0";
  }
};

// Fonction pour staker les tokens
export const stakeTokens = async (wallet, amount) => {
  if (!wallet) {
    throw new Error(
      "Le wallet n'est pas défini. Veuillez vérifier la connexion à MetaMask."
    );
  }
  const stakingContract = await getContract(
    stakepoolAddress,
    stakepoolABI,
    wallet
  );
  const tokenContract = await getContract(
    tokenContractAddress,
    tokenABI,
    wallet
  );

  try {
    if (!stakingContract) {
      throw new Error("Le contrat de staking n'a pas été initialisé.");
    }
    const amountToString = amount.toString();
    const parsedAmount = parseUnits(amountToString, 18);

    const userAddress = await wallet.getAddress();
    const allowance = await tokenContract.allowance(
      userAddress,
      stakepoolAddress
    );

    const allowanceBigInt = getBigInt(allowance);
    const parsedAmountBigInt = getBigInt(parsedAmount);

    // Comparer directement les BigNumber
    if (allowanceBigInt < parsedAmountBigInt) {
      const approvalTx = await tokenContract.approve(
        stakepoolAddress,
        parsedAmount
      );
      await approvalTx.wait();
    }

    const tx = await stakingContract.stake(parsedAmount);
    await tx.wait();
    console.log("Staking réussi !");
  } catch (error) {
    console.error("Erreur de staking", error);
  }
};

// Fonction pour retirer les tokens
export const unstakeTokens = async (wallet, amount) => {
  if (!wallet) {
    throw new Error(
      "Le wallet n'est pas défini. Veuillez vérifier la connexion à MetaMask."
    );
  }

  const stakingContract = await getContract(
    stakepoolAddress,
    stakepoolABI,
    wallet
  );

  try {
    if (!stakingContract) {
      throw new Error("Le contrat de staking n'a pas été initialisé.");
    }

    const parsedAmount = parseUnits(amount.toString(), 18);
    const tx = await stakingContract.withdraw(parsedAmount);
    await tx.wait();
    console.log("Unstaking réussi !");
    return tx;
  } catch (error) {
    console.error("Erreur d'unstaking", error);
    throw error;
  }
};

// Fonction pour récupérer les récompenses
export const claimRewards = async (wallet) => {
  if (!wallet) {
    throw new Error(
      "Le wallet n'est pas défini. Veuillez vérifier la connexion à MetaMask."
    );
  }

  const stakingContract = await getContract(
    stakepoolAddress,
    stakepoolABI,
    wallet
  );

  try {
    if (!stakingContract) {
      throw new Error("Le contrat de staking n'a pas été initialisé.");
    }

    const tx = await stakingContract.getReward();
    await tx.wait();
    console.log("Récupération des récompenses réussie !");
    return tx;
  } catch (error) {
    console.error("Erreur lors de la récupération des récompenses", error);
    throw error;
  }
};
