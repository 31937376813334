import React, { useState, useEffect } from "react";
import { faWallet, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransactionHistory from './TransactionHistory';

const TopTabsWallet = ({ children }) => {
  const [activeTab, setActiveTab] = useState("wallet");
  const [isWhitepaper, setIsWhitepaper] = useState(false);

  useEffect(() => {
    const checkWhitepaper = () => {
      setIsWhitepaper(document.body.classList.contains('whitepaper-active'));
    };

    // Vérifie au montage
    checkWhitepaper();

    // Observer les changements de classe sur le body
    const observer = new MutationObserver(checkWhitepaper);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  const TabButton = ({ tab, icon, label }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`
        flex items-center gap-2 px-6 py-3 text-sm
        transition-all duration-300 w-full justify-center
        ${activeTab === tab 
          ? `bg-white/10 text-white border-t-2 ${isWhitepaper ? "border-[#6C63FF]/20" : "border-[#F4B000]/20"}` 
          : "text-white/50 hover:text-white/80"
        }
      `}
    >
      <FontAwesomeIcon icon={icon} className="w-4 h-4" />
      <span>{label}</span>
    </button>
  );

  return (
    <div className="flex flex-col w-full">
      {/* Top Tabs */}
      <div className="flex gap-1 bg-black/20">
        <TabButton tab="wallet" icon={faWallet} label="Wallet" />
        <TabButton tab="history" icon={faHistory} label="History" />
      </div>

      {/* Content */}
      <div className="flex-1">
        {activeTab === "wallet" && (
          <div className="animate-fade-in">
            {children}
          </div>
        )}
        {activeTab === "history" && (
          <div className="py-0 animate-fade-in">
              <div className="text-white/70 text-sm">
                <TransactionHistory/>
              </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopTabsWallet;