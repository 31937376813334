import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { Medal } from "lucide-react";
import ProfileModal from "./Profile/ModalProfile";
import styles from "../styles/Esport.module.css";

const EsportLeaderboard = ({ game }) => {
  const [players, setPlayers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const getRankStyle = (rank) => {
    switch (rank) {
      case 1:
        return "bg-gradient-to-r from-yellow-500/30 to-yellow-600/30 hover:from-yellow-500/40 hover:to-yellow-600/40";
      case 2:
        return "bg-gradient-to-r from-gray-300/30 to-gray-400/30 hover:from-gray-300/40 hover:to-gray-400/40";
      case 3:
        return "bg-gradient-to-r from-orange-700/30 to-orange-800/30 hover:from-orange-700/40 hover:to-orange-800/40";
      default:
        return "hover:bg-white/5";
    }
  };

  const getMedalColor = (rank) => {
    switch (rank) {
      case 1:
        return "text-yellow-400";
      case 2:
        return "text-gray-300";
      case 3:
        return "text-orange-700";
      default:
        return null;
    }
  };

  const ProfileImage = ({ player }) => {
    const [imageError, setImageError] = useState(!player.profileImage);

    if (imageError) {
      return (
        <div className="w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-gray-600/50 flex items-center justify-center text-white/90 uppercase pt-[2px] text-xs sm:text-base">
          {player.name.charAt(0)}
        </div>
      );
    }

    return (
      <img
        src={player.profileImage}
        alt={`${player.name}'s profile`}
        className="w-6 h-6 sm:w-8 sm:h-8 rounded-full object-cover"
        onError={() => setImageError(true)}
      />
    );
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/leaderboards/${game}/players`
        );
        const sortedData = response.data.sort(
          (a, b) => b.tournamentElo - a.tournamentElo
        );

        const rankedData = sortedData.slice(0, 25).map((player, index) => ({
          ...player,
          rang: index + 1,
        }));

        setPlayers(rankedData);
      } catch (error) {
        console.error("Erreur lors de la récupération des joueurs :", error);
      }
    };

    fetchPlayers();
  }, [game]);

  const handleMemberClick = (member, event) => {
    event.stopPropagation();
    setSelectedMember(member);
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
    setSelectedMember(null);
  };

  return (
    <>
      <div className={`${styles.ladder} w-full`}>
        <div className="rounded-lg bg-white/10 backdrop-blur-sm p-2 sm:p-5 shadow-lg">
          <h2 className="text-white/80 text-lg sm:text-xl font-semibold">
            Leaderboard
          </h2>
          <hr className="border-t border-white/60 my-2 sm:my-3 w-full" />
          <div className="rounded-lg border border-white/10 overflow-hidden">
            {players.map((player) => (
              <div
                key={player.name + player.rang}
                className={`w-full transition-colors duration-200 ${getRankStyle(
                  player.rang
                )}`}
                onClick={(e) => handleMemberClick(player, e)}
                style={{ cursor: "pointer" }}
              >
                <div className="w-full px-2 sm:px-4 py-1.5 sm:py-2 flex items-center space-x-2 sm:space-x-4">
                  {/* Rank avec médaille pour le podium */}
                  <div className="w-6 sm:w-8 flex justify-center relative">
                    <span className="text-white/90 text-sm sm:text-base">
                      {player.rang}
                    </span>
                    {player.rang <= 3 && (
                      <Medal
                        className={`absolute -right-4 sm:-right-6 ${getMedalColor(
                          player.rang
                        )}`}
                        size={14}
                      />
                    )}
                  </div>

                  {/* Image de profil */}
                  <div className="w-6 h-6 sm:w-8 sm:h-8 rounded-full overflow-hidden bg-white/10 flex-shrink-0">
                    <ProfileImage player={player} />
                  </div>

                  {/* Nom du joueur */}
                  <div className="flex-1 text-white/90 text-sm sm:text-base truncate">
                    {player.name}
                  </div>

                  {/* Score ELO */}
                  <div className="text-right text-white/90 w-16 sm:w-2 text-sm sm:text-base">
                    {player.tournamentElo}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedMember && isProfileModalOpen && (
        <ProfileModal
          isOpen={isProfileModalOpen}
          onRequestClose={closeProfileModal}
          type="user"
          id={selectedMember.id}
          userId={selectedMember.eth}
        />
      )}
    </>
  );
};

export default EsportLeaderboard;
