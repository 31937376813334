import React, { useState } from 'react';
import ModalProfile from './ModalProfile';
import axiosInstance from "../../axiosConfig";

const FriendsList = ({ friends }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedFriend: null,
    selectedFriendId: null
  });

  const fetchUserId = async (eth) => {
    try {
      const response = await axiosInstance.get(`/api/users/eth/${eth}`);
      return response.data._id;
    } catch (error) {
      console.error('Error fetching user ID:', error);
      return null;
    }
  };

  const openModal = async (friend) => {
    const userId = await fetchUserId(friend.eth);
    if (userId) {
      setModalState({
        isOpen: true,
        selectedFriend: friend,
        selectedFriendId: userId
      });
    } else {
      console.error('Could not fetch user ID');
    }
  };

  const closeModal = () => {
    setModalState({
      isOpen: false,
      selectedFriend: null,
      selectedFriendId: null
    });
  };

  return (
    <>
      <div className="bg-white/5 rounded-2xl p-4 sm:p-6">
        <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4 text-white">Friends</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 max-h-[100px] overflow-y-auto">
          {friends.length > 0 ? (
            friends.map((friend) => (
              <div
                key={friend.eth}
                onClick={() => openModal(friend)}
                className="flex items-center p-3 sm:p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors cursor-pointer group"
              >
                <div className="relative">
                  {friend.imageUrl ? (
                    <img
                      src={friend.imageUrl}
                      alt={friend.name}
                      className="w-10 h-10 rounded-full object-cover group-hover:ring-2 ring-[#F4B000] transition-all"
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] flex items-center justify-center text-white group-hover:ring-2 ring-[#F4B000] transition-all">
                      {friend.name.substring(0, 2).toUpperCase()}
                    </div>
                  )}
                  <div className="absolute -bottom-0 -right-1 w-3 h-3 bg-green-500 rounded-full border-2 border-[#1a1b1f]" />
                </div>
                <div className="ml-3 overflow-hidden">
                  <div className="text-white font-medium truncate">{friend.name}</div>
                  <div className="text-white/50 text-sm flex items-center space-x-2">
                    <span>Level {friend.level || 1}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full text-white/50 text-center py-8">
              No friends yet
            </div>
          )}
        </div>
      </div>

      {modalState.selectedFriend && modalState.selectedFriendId && (
        <ModalProfile
          isOpen={modalState.isOpen}
          onRequestClose={closeModal}
          type="user"
          id={modalState.selectedFriendId}
          userId={modalState.selectedFriend.eth}
          eth={modalState.selectedFriend.eth}
        />
      )}
    </>
  );
};

export default FriendsList;