import React, { useState, useEffect } from "react";
import styles from "../../styles/MatchCard.module.css";
import { normalizeGameName } from "../../utils/gameUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import TwitchLiveStreams from "../TwitchLiveStreams";
import { useWallet } from "../../context/WalletContext";

const MatchCardSkeleton = () => {
  return (
    <div className={`${styles.matchCard} animate-pulse relative isolate !bg-gray-300/10 !h-[230px] overflow-hidden`}>
     <div 
        className="absolute inset-0 z-10 -translate-x-full"
        style={{
          background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.08), transparent)',
          animation: 'shimmer 2s infinite',
        }}
      />
      {/* Header */}
      <div className={styles.matchHeader}>
        <div className={styles.titleHead}>
          <span className={styles.matchTitle}>
            <div className="h-4 bg-gray-700/30 rounded w-3/4"></div>
            <div className="h-3 bg-gray-700/30 rounded w-1/2 mt-2"></div>
          </span>
        </div>
        <span className={styles.matchTime}>
          <div className="h-4 bg-gray-700/30 rounded w-16"></div>
        </span>
      </div>

      {/* Teams */}
      <div className={styles.teamVsTeam}>
        <div className={styles.team}>
          <div className="w-[43px] h-[43px] bg-gray-700/30 rounded-full"></div>
        </div>
        <div className={`${styles.vs} bg-gray-700/30 rounded w-12 h-6`}></div>
        <div className={styles.team}>
          <div className="w-[43px] h-[43px] bg-gray-700/30 rounded-full"></div>
        </div>
      </div>

      {/* Odds */}
      <div className={styles.odds}>
        <div className="h-10 bg-gray-700/30 rounded w-28"></div>
        <div className="h-10 bg-gray-700/30 rounded w-28"></div>
      </div>
    </div>
  );
};

const ScoreDisplay = React.memo(({ match }) => {
  if (!match) return null;

  // S'assurer d'avoir toutes les sources possibles de scores
  const getScore = (team, index) => {
    const sources = [team?.score, match.results?.[index]?.score, team?.results];

    // Utiliser la première valeur non-nulle
    const score = sources.find((s) => s !== undefined && s !== null) || "0";
    return score.toString();
  };

  const scores = {
    team1: getScore(match.team1, 0),
    team2: getScore(match.team2, 1),
  };

  if (match.status !== "running") {
    return (
      <span className={styles.vs}>
        VS
        {match.number_of_games && (
          <span className="text-xs opacity-50 ml-1">
            (Bo{match.number_of_games})
          </span>
        )}
      </span>
    );
  }

  return (
    <span className={styles.vs}>
      <span className={`${styles.score} font-bold`}>{scores.team1}</span>
      <span className={styles.scoreSeparator}> : </span>
      <span className={`${styles.score} font-bold`}>{scores.team2}</span>
      {match.number_of_games && (
        <span className="text-xs opacity-50 ml-1">
          (Bo{match.number_of_games})
        </span>
      )}
    </span>
  );
});

function MatchCard({
  match,
  onSelectBet,
  selectedTeam,
  betExists,
  pausedContracts,
  onStatusChange,
  loading,
}) {
  // Déclarer tous les Hooks au début du composant
  const [isLiveExpanded, setIsLiveExpanded] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [, setIsLive] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");
  const [previousStatus, setPreviousStatus] = useState(null);
  const { account } = useWallet();

  // Extraire les données en toute sécurité après les Hooks
  const matchData = {
    id: match?.id,
    status: match?.status || "unknown",
    leagueName: match?.league?.name || match?.league,
    team1Name: match?.team1?.name || "TBD",
    team2Name: match?.team2?.name || "TBD",
    stream: match?.stream,
    begin_at: match?.begin_at,
    title: match?.title || "Untitled Match",
    tournamentName: match?.tournamentName || match?.tournament,
  };

  useEffect(() => {
    if (match?.status !== previousStatus) {
      onStatusChange?.(matchData.status);
      setPreviousStatus(matchData.status);
    }
  }, [
    matchData.status,
    matchData.id,
    previousStatus,
    onStatusChange,
    match?.status,
  ]);

  useEffect(() => {
    function updateTimer() {
      if (!matchData.begin_at) {
        setTimeLeft("Soon");
        return 0;
      }

      const target = new Date(matchData.begin_at);
      if (isNaN(target.getTime())) {
        setTimeLeft("Soon");
        return 0;
      }

      const now = new Date();
      const diff = target - now;

      if (diff <= 0) {
        setTimeLeft("Soon");
        return 0;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (hours >= 24) {
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        setTimeLeft(`${days}D ${remainingHours}H`);
      } else if (hours > 0) {
        setTimeLeft(`${hours}H ${minutes}M`);
      } else {
        setTimeLeft(`${minutes}M ${seconds}S`);
      }

      return hours;
    }

    const initialHours = updateTimer();
    const interval = initialHours > 0 ? 60000 : 1000;
    const timer = setInterval(updateTimer, interval);

    return () => clearInterval(timer);
  }, [matchData.begin_at]);

  if (loading) {
    return <MatchCardSkeleton />;
  }

  const handleToggle = () => {
    if (isLiveExpanded) {
      setIsClosing(true);
      setTimeout(() => {
        setIsLiveExpanded(false);
        setIsClosing(false);
      }, 400);
    } else {
      setIsLiveExpanded(true);
    }
  };

  // Vérifier si le contrat est en pause
  const isPaused = pausedContracts;

  const getChannelFromUrl = (urlArray) => {
    // Si on reçoit une seule URL sous forme de string
    if (typeof urlArray === "string") {
      if (!urlArray) return null;

      const twitchMatches = urlArray.match(
        /(?:https?:\/\/)?(?:(?:www|player)\.)?twitch\.tv\/(?:\?channel=)?([^/?&\s]+)/i
      );

      if (twitchMatches) {
        return { platform: "twitch", channel: twitchMatches[1] };
      }
      return null;
    }

    // Si on reçoit un tableau d'URLs
    if (Array.isArray(urlArray)) {
      // Parcourt chaque URL du tableau
      for (const url of urlArray) {
        if (!url || typeof url !== "string") continue;

        const twitchMatches = url.match(
          /(?:https?:\/\/)?(?:(?:www|player)\.)?twitch\.tv\/(?:\?channel=)?([^/?&\s]+)/i
        );

        if (twitchMatches) {
          return { platform: "twitch", channel: twitchMatches[1] };
        }
      }
    }

    return null;
  };

  const handleClick = (team, odd) => {
    if (!betExists && onSelectBet) {
      onSelectBet(
        matchData.id,
        matchData.title,
        team,
        odd,
        normalizeGameName(match?.game),
        matchData.status,
        match?.isActive
      );
    }
  };

  // Vérification des données requises après les Hooks
  if (!match) {
    return <div className={styles.matchCard}>Match data unavailable</div>;
  }

  if (!match.team1 || !match.team2 || !match.odds) {
    return (
      <div className={styles.matchCard}>
        <div className={styles.errorMessage}>Match data incomplete</div>
      </div>
    );
  }

  const buttonClassName = (team) =>
    `${styles.oddButton} ${
      betExists || pausedContracts || matchData.status === "running" ? styles.betExists : ""
    } ${selectedTeam === team ? styles.selected : ""}`;

  return (
    <>
      {isLiveExpanded &&
        matchData.stream &&
        getChannelFromUrl(matchData.stream)?.platform === "twitch" && (
          <div
            className={`${styles.liveStreamContainer} ${
              isLiveExpanded ? styles.expanded : ""
            } ${isClosing ? styles.closing : ""}`}
          >
            <TwitchLiveStreams
              channels={[getChannelFromUrl(matchData.stream).channel]}
              onLiveStatusChange={setIsLive}
              isExpanded={isLiveExpanded}
            />
          </div>
        )}

      <div
        className={`${styles.matchCard} ${betExists ? styles.betExists : ""} ${
          isLiveExpanded ? styles.streamExpanded : ""
        }`}
      >
        <div className={styles.matchHeader}>
          <div className={styles.titleHead}>
            <span className={styles.matchTitle}>
              {matchData.title}
              <br />
              <span className={styles.matchTitle2}>
                {[matchData.leagueName, matchData.tournamentName]
                  .filter(Boolean)
                  .join(" - ") || ""}
              </span>
            </span>
          </div>

          <span className={styles.matchTime}>
            {matchData.status === "running" ? (
              <span
                className={`font-bold flex items-center gap-1 ${
                  getChannelFromUrl(matchData.stream)?.platform === "twitch"
                    ? "text-red-600 cursor-pointer"
                    : "text-[#141414a6]"
                }`}
                onClick={
                  getChannelFromUrl(matchData.stream)?.platform === "twitch"
                    ? handleToggle
                    : undefined
                }
              >
                {getChannelFromUrl(matchData.stream)?.platform === "twitch" ? (
                  <FontAwesomeIcon icon={faVideo} />
                ) : (
                  <FontAwesomeIcon icon={faVideoSlash} />
                )}
                {matchData.status === "running" && (
                  <span className={styles.runningIndicator}></span>
                )}
              </span>
            ) : (
              timeLeft
            )}
          </span>
        </div>
        <div className={styles.teamVsTeam}>
          <div className={styles.team}>
            {match.team1?.logo ? (
              <img
                src={match.team1.logo}
                alt={`${matchData.team1Name} logo`}
                style={{ borderRadius: "0" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ width: "43px", height: "43px" }}
              />
            )}
          </div>
          <ScoreDisplay
            match={{
              ...match,
              team1: {
                ...match.team1,
                score: match.team1?.score || match.results?.[0]?.score || "0",
              },
              team2: {
                ...match.team2,
                score: match.team2?.score || match.results?.[1]?.score || "0",
              },
              status: match.status,
              results: [
                {
                  score: match.team1?.score || match.results?.[0]?.score || "0",
                },
                {
                  score: match.team2?.score || match.results?.[1]?.score || "0",
                },
              ],
            }}
          />
          <div className={styles.team}>
            {match.team2?.logo ? (
              <img
                src={match.team2.logo}
                alt={`${matchData.team2Name} logo`}
                style={{ borderRadius: "0" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ width: "43px", height: "43px" }}
              />
            )}
          </div>
        </div>
        <div className={styles.odds}>
          <button
            className={buttonClassName(matchData.team1Name)}
            onClick={() => handleClick(matchData.team1Name, match.odds.team1)}
            disabled={
              betExists ||
              matchData.status === "running" ||
              isPaused ||
              account === ""
            }
          >
            {match.odds.team1}
          </button>
          {betExists && (
            <div className={styles.betExistsMessage}>
              {betExists ? "Already bet" : ""}
            </div>
          )}
          <button
            className={buttonClassName(matchData.team2Name)}
            onClick={() => handleClick(matchData.team2Name, match.odds.team2)}
            disabled={betExists || matchData.status === "running" || isPaused}
          >
            {match.odds.team2}
          </button>
        </div>
      </div>
    </>
  );
}

export default MatchCard;
