import React, { useEffect, useState, useCallback } from "react";
import axiosInstance from "../../axiosConfig";
import styles from "../../styles/TeamModal.module.css";
import ProfileModal from "./ModalProfile";

const Team = ({ type, id, userId, eth }) => {
  const [profile, setProfile] = useState(null);
  const [rankings, setRankings] = useState({
    rank: "N/A",
    tournamentElo: 0
  });
  const [team, setTeam] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        // Utilisons le même endpoint que TeamGestionModal qui retourne les données complètes des membres
        const { data } = await axiosInstance.get(
          `/api/teams/${id}/members/${userId}`
        );
        setTeam(data);
      } catch (error) {
        console.error("Error fetching team", error);
      }
    };

    if (id) {
      fetchTeam();
    }
  }, [id, userId]);

  const fetchRankings = useCallback(async (teamId) => {
    try {
      const response = await axiosInstance.get("/api/leaderboards/rankingsTeam");
      const { rankings } = response.data;
  
      const teamRanking = rankings.find(t => t.teamId === id); // utiliser 'id' au lieu de teamId
      setRankings({
        rank: teamRanking?.rank || "N/A",
        tournamentElo: teamRanking?.tournamentElo || 0
      });
    } catch (error) {
      console.error("Error fetching rankings:", error);
    }
  }, [id]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axiosInstance.get(`/api/profile/${type}/${id}`);
        setProfile(data);
        fetchRankings();
        if (data?.eth) {
          fetchRankings(data.eth);
        }
      } catch (error) {
        console.error("Error fetching profile", error);
      }
    };
    fetchProfile();
  }, [type, id, fetchRankings]);

  const handleMemberClick = (member, event) => {
    event.stopPropagation();
    setSelectedMember(member);
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
    setSelectedMember(null);
  };

  if (!profile)
    return <div className="text-white text-center p-4">Loading...</div>;

  if (!team)
    return <div className="text-white text-center p-4">Loading...</div>;

  console.log(rankings)

  return (
    <>
      <div className={`${styles.teamDashboard}`}>
        <div
          className={styles.teamBanner}
          style={{
            backgroundImage: `url(${team?.bannerUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={styles.teamBannerContent}>
            <div
              className={styles.teamDashboardLogo}
              style={{
                backgroundImage: `url(${team?.logoUrl || null})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <div className={styles.teamDashboardInfo}>
              <h1 className={styles.teamDashboardName}>{team.name}</h1>
              <div className={styles.teamDashboardMeta}>
                <span>
                  Created:{" "}
                  {new Date(team.createdAt).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </span>
                <span>•</span>
                <span>Members: {team.members?.length || 0}</span>
                <span>•</span>
                <span>Division: Pro</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.teamContent}>
          <div className={styles.teamMain}>
            <div className={styles.teamDescription}>
              <h2>About Our Team</h2>
              <div className="flex min-h-[150px]">
                <p className="flex-1 break-words whitespace-pre-line">
                  {team.description || "No team description available."}
                </p>
              </div>
            </div>
            <div className="h-full w-[1px] bg-[#8080802f] mx-auto"></div>
            <div className={styles.teamMembersList}>
              <h2>Team Members</h2>
              <div className="overflow-auto max-h-[250px]">
                {team.members?.map(
                  (member) =>
                    member.user && (
                      <div
                        key={member.user._id}
                        className={styles.teamMemberCard}
                        onClick={(e) => handleMemberClick(member.user, e)}
                      >
                        <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center relative">
                          {member.user.imageUrl ? (
                            <>
                              <img
                                src={member.user.imageUrl}
                                alt={`${member.user.name}`}
                                className="w-[50px] h-[50px] rounded-full object-cover z-10 relative"
                                onError={(e) => {
                                  e.target.style.display = "none";
                                  e.target.parentElement.querySelector(
                                    ".fallback-initial"
                                  ).style.display = "flex";
                                }}
                              />
                              <span
                                className={`fallback-initial hidden absolute inset-0 ${styles.memberInitial}`}
                              >
                                {member.user.name[0].toUpperCase()}
                              </span>
                            </>
                          ) : (
                            <span className={styles.memberInitial}>
                              {member.user.name[0].toUpperCase()}
                            </span>
                          )}
                        </div>
                        <div className={styles.memberInfo}>
                          <h3 className="flex">
                            {member.user.name}{" "}
                            <h3 className="bg-[#2279ca] ml-2 w-5 h-5 rounded-full flex justify-center items-center">
                              <span className="!text-[10px] font-mono">
                                {member.user.experience.level}
                              </span>
                            </h3>
                          </h3>
                          <p>{member.role}</p>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
          <aside className={styles.teamSidebar}>
            <div className={styles.teamActions}>
              <div className="bg-white/5 rounded-2xl p-4 mb-4">
                <div className="text-white/70 text-sm mb-2">
                  Versus Score :
                </div>
                <div className="text-2xl font-bold text-[#F4B000]">
                  {rankings.tournamentElo?.toFixed(1) || "0"}
                </div>
                <div className="text-white/70 text-sm mt-3 mb-2">Rank :</div>
                <div className="text-2xl font-bold text-[#F4B000]">
                  #{rankings.rank || "N/A"}
                </div>
              </div>
              <button className={styles.teamActionBtn}>Tournaments</button>
            </div>
          </aside>
        </div>
      </div>
      {selectedMember && isProfileModalOpen && (
        <ProfileModal
          isOpen={isProfileModalOpen}
          onRequestClose={closeProfileModal}
          type="user"
          id={selectedMember._id}
          userId={eth}
        />
      )}
    </>
  );
};

export default Team;
