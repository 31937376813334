import React, { useState, useEffect } from "react";

const FaucetCountdown = ({ startTime }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const [isWhitepaper, setIsWhitepaper] = useState(false);

  useEffect(() => {
    const checkWhitepaper = () => {
      setIsWhitepaper(document.body.classList.contains('whitepaper-active'));
    };

    // Vérifie au montage
    checkWhitepaper();

    // Observer les changements de classe sur le body
    const observer = new MutationObserver(checkWhitepaper);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = Math.floor(Date.now() / 1000);
      const endTime = startTime + 24 * 60 * 60; // 24h en secondes
      const diff = endTime - now;

      if (diff <= 0) {
        setIsComplete(true);
        return "Faucet is now available!";
      }

      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);
      const seconds = diff % 60;

      return `${hours}h ${minutes}m ${seconds}s`;
    };

    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  return (
    <div
      className={`flex flex-col text-xl font-semibold ${
        isComplete ? "text-[#F4B000]" : "text-white"
      }`}
    >
      <div className="font-medium text-white flex justify-between">
        <span>Next REKT drop : </span><span>{timeLeft}</span>
      </div>
      {!isComplete && (
        <div className="w-full bg-[#ffffff20] h-1.5 mt-2 rounded-full overflow-hidden">
          <div
            className={`h-full ${isWhitepaper ? "bg-[#6C63FF]/20" : "bg-[#F4B000]/20"} transition-all duration-1000 rounded-full`}
            style={{
              width: `${
                100 -
                ((Math.floor(Date.now() / 1000) - startTime) / (24 * 60 * 60)) *
                  100
              }%`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FaucetCountdown;
