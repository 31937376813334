import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../axiosConfig";
import { BrowserProvider, Contract } from "ethers";
import bettingContractABI from "../ABI/Betting"

// Assure-toi d'ajouter l'adresse correcte de chaque contrat dans le fichier .env
const bettingContractAddress = process.env.REACT_APP_BETTING_CONTRACT_ADDRESS;

const AdminAddBet = () => {
  const [activeMatches, setActiveMatches] = useState([]);

  useEffect(() => {
    // Charger les matchs actifs
    const fetchActiveMatches = async () => {
      try {
        const response = await axiosInstance.get("/api/bets/active");
        setActiveMatches(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des matchs actifs:",
          error
        );
      }
    };

    fetchActiveMatches();
  }, []);

  // Fonction pour récupérer l'instance du contrat
  const getContract = useCallback(
    async (specificAddress = null, specificABI = null) => {
      const addressToUse = specificAddress || bettingContractAddress;
      const abiToUse = specificABI || bettingContractABI;

      if (!addressToUse) {
        console.error("Contract address not defined !");
        return null;
      }

      if (typeof window.ethereum !== "undefined") {
        try {
          const provider = new BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          return new Contract(addressToUse, abiToUse, signer);
        } catch (error) {
          console.error("Erreur lors de la récupération du signer:", error);
          return null;
        }
      } else {
        alert("Installez MetaMask pour interagir avec ce site.");
      }
    },
    []
  );

  const handleEndMatch = async (matchId, result, bca) => {
    if (!matchId) {
      console.error("ID du match non défini");
      alert("Erreur : ID du match non défini");
      return;
    }
    if (!bca) {
      console.error("Nop!");
      return;
    }

    try {
      const contract = await getContract(bca);

      if (contract) {
        // 1. Exécuter la transaction blockchain
        const tx = await contract.endMatch(result);
        await tx.wait();
        console.log(`Match ${matchId} terminé. Résultat: ${result}`);

        // 2. Mettre à jour le statut dans la base de données
        const response = await axiosInstance.put(`/api/bets/${matchId}`, {
          status: "finished",
          isActive: false,
          result: result, // Ajout du résultat
        });

        // 3. Vérifier la réponse
        if (!response.data) {
          throw new Error("Pas de données reçues de l'API");
        }

        // 4. Mettre à jour l'interface utilisateur
        setActiveMatches((prevMatches) => {
          // Créer une copie des matchs actifs sans le match terminé
          const updatedMatches = prevMatches.filter(
            (match) => match.pandascoreId !== matchId
          );

          // Si le match n'a pas été filtré (cas improbable), on force sa mise à jour
          if (updatedMatches.length === prevMatches.length) {
            const matchIndex = prevMatches.findIndex(
              (m) => m.pandascoreId === matchId
            );
            if (matchIndex !== -1) {
              updatedMatches[matchIndex] = {
                ...prevMatches[matchIndex],
                status: "finished",
                isActive: false,
              };
            }
          }

          return updatedMatches;
        });

        alert("Match terminé avec succès !");
      }
    } catch (error) {
      console.error("Erreur lors de la fin du match:", error);
      if (error.response) {
        console.error("Réponse d'erreur:", error.response.data);
      }
      alert(`Erreur lors de la fin du match: ${error.message}`);
    }
  };

  function getFirstWord(text) {
    if (!text) return "";
    return text.trim().split(" ")[0];
  }

  return (
    <div className="admin-tournament-form">
      <h2>Active Matches</h2>
      <div className="match-list">
        {activeMatches.map((match) => (
          <div key={match._id}>
            <p>{match.title}</p>
            <div className="match-list-group">
              <button
                onClick={() =>
                  handleEndMatch(
                    match.pandascoreId,
                    1,
                    match.bettingContractAddress
                  )
                }
                className="button-match-list1"
              >
                {getFirstWord(match.team1.name)}
              </button>
              <button
                onClick={() =>
                  handleEndMatch(
                    match.pandascoreId,
                    3,
                    match.bettingContractAddress
                  )
                }
                className="button-match-list2"
              >
                Tie
              </button>
              <button
                onClick={() =>
                  handleEndMatch(
                    match.pandascoreId,
                    2,
                    match.bettingContractAddress
                  )
                }
                className="button-match-list1"
              >
                {getFirstWord(match.team2.name)}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminAddBet;
