// Dashboard.js
import React, { useState, useEffect } from "react";
import { useWallet } from "../context/WalletContext";
import ProfileModal from "./ProfileModal";
import SearchResults from "./Search/SearchResults";
import { useNotification } from "./Notifications/Notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faUser } from "@fortawesome/free-solid-svg-icons";
import { ChevronDown } from "lucide-react";
import WalletInterface from "./WalletInterface";
import SideTabsWallet from "./SideTabWallet";

const XPBar = ({ currentXP, requiredXP, isCircle }) => {
  const percentage = (currentXP / requiredXP) * 100;

  if (isCircle) {
    return (
      <div className="absolute inset-0 w-12 h-12 -m-[2px] pointer-events-none">
        <svg className="w-full h-full" viewBox="0 0 44 51">
          <defs>
            <linearGradient id="circleGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#60A5FA" />
              <stop offset="100%" stopColor="#2563EB" />
            </linearGradient>
          </defs>
          <circle
            cx="24"
            cy="24"
            r="22"
            fill="none"
            strokeWidth="3"
            stroke="#2424244f"
            className="w-full h-full"
          />
          <circle
            cx="24"
            cy="24"
            r="22"
            fill="none"
            strokeWidth="4"
            stroke="url(#circleGradient)"
            strokeDasharray={`${percentage * 1.385} 999`}
            transform="rotate(-205 24 24)"
            className="w-full h-full"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className="w-full bg-[#2424244f] rounded-full h-1">
      <div
        className="h-1 rounded-full transition-all duration-300"
        style={{
          width: `${percentage}%`,
          background: "linear-gradient(to right, #60A5FA, #2563EB)",
          opacity: 0.8,
        }}
      />
    </div>
  );
};

const SidebarControls = ({ expanded, onToggle }) => {
  return (
    <div className="ml-2 flex flex-col">
      <button
        onClick={onToggle}
        className={`w-5 h-[50px] bg-[#5a5a5a69] backdrop-blur-md rounded-lg flex items-center justify-center text-[#ffffff] hover:bg-[#F4B000]/10 transition-all duration-300 mobileChevronDown ${
          expanded ? "rotate-180" : ""
        }`}
      >
        <ChevronDown className="w-6 h-6" />
      </button>
    </div>
  );
};

const Dashboard = ({
  name,
  eth,
  bio,
  image,
  saveProfile,
  currentUserId,
  experience = { level: 1, currentXP: 0, totalXP: 0 },
  pseudo,
}) => {
  const { account } = useWallet();
  const { notify } = useNotification();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 821);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 821);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatAddress = (address) => {
    if (!address) return "";
    const start = address.slice(0, 2);
    const end = address.slice(-8);
    return `${start}...${end}`;
  };

  const handleCopy = () => {
    const textToCopy = account;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        notify("Copied to clipboard !", "info");
      })
      .catch((err) => {
        notify("Failed to copy ", "error" + err);
      });
  };

  const displayName =
    name.length > 14 ? `${name.slice(0, 8)}...${name.slice(-2)}` : name;

  const currentXP = Number(experience.currentXP);
  const level = Number(experience.level);
  const requiredXP = Math.floor(100 * Math.pow(1.5, level - 1));

  return (
    <>
      <div>
        <SearchResults currentUserId={currentUserId} />
        <div
          className={`relative transition-all duration-700 ease-in-out ${
            isExpanded ? "!h-[670px] profilExpanded" : "!h-[77px]"
          } profil`}
        >
          <div className="profil_info">
            <div className="profil_title flex flex-row justify-end">
              <div className="flex flex-col h-[50px] justify-center">
                <p
                  className="text-base text-[#ecf1fc] justify-end align-bottom pb-1"
                  title={name}
                >
                  {displayName}
                </p>

                <div className="copied-text flex items-center">
                  <span>{formatAddress(account)}</span>
                  <FontAwesomeIcon
                    icon={faCopy}
                    onClick={handleCopy}
                    alt="Copy icon"
                    className="copy-icon ml-2"
                  />
                </div>
              </div>
              <div className="relative ml-1">
                {isSmallScreen && (
                  <XPBar
                    currentXP={currentXP}
                    requiredXP={requiredXP}
                    isCircle
                  />
                )}
                {image ? (
                  <img
                    src={image}
                    alt="Profile"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsProfileModalOpen(true)}
                    className="w-12 h-12 rounded-full object-cover ml-1 z-40"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="w-8 h-7 md:w-9 md:h-9 flex align-middle bg-[#2424244f] rounded-full p-[7px] object-cover transition-transform duration-200 ease-in-out hover:scale-110"
                    onClick={() => setIsProfileModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <SidebarControls
                expanded={isExpanded}
                onToggle={() => setIsExpanded(!isExpanded)}
              />
            </div>
            <div className="fixed flex items-center justify-between text-xs bg-[#2563EB] rounded-full text-[#ecf1fc] max-md:top-[30px] max-md:right-[50px] md:top-[45px] md:right-[38px] z-[1000]">
              <span className="flex items-center justify-center text-center w-[21px] h-[21px] leading-none">
                {level}
              </span>
            </div>
          </div>
          {/* Barre de niveau collée en bas */}
          {!isSmallScreen && (
            <div className="absolute bottom-0 left-0 right-0">
              <XPBar currentXP={currentXP} requiredXP={requiredXP} />
            </div>
          )}
          <div
            className={`absolute top-[77px] left-0 right-0 transition-opacity duration-700 ${
              isExpanded ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
          >
            <SideTabsWallet>
            <WalletInterface
              onMnemonicSaved={() => setIsExpanded(false)}
              forceShowMnemonic={false}
              pseudo={pseudo || name}
              isEmbedded={true}
            />
            </SideTabsWallet>
          </div>
        </div>
      </div>

      {isProfileModalOpen && (
        <ProfileModal
          isOpen={isProfileModalOpen}
          onRequestClose={() => setIsProfileModalOpen(false)}
          saveProfile={saveProfile}
          eth={eth}
          existingProfile={{ name, eth, bio, image, currentUserId }}
        />
      )}
    </>
  );
};

export default Dashboard;
