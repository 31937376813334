import React, { useEffect, useState, useCallback } from "react";
import { useWallet, normalizeAddress } from "../context/WalletContext";
import ProfileModal from "./ProfileModal";
import Dashboard from "./Dashboard";
import axiosInstance from "../axiosConfig";
import WalletInterface from "./WalletInterface";
import { HDNodeWallet } from "ethers";
import { useNotification } from "./Notifications/Notifications";

const ConnectWalletModal = ({ isOpen, onClose, onConnect }) => {
  const [mnemonic, setMnemonic] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleConnect = async (e) => {
    e.preventDefault();
    console.log("ici 1");
    setError("");
    setIsLoading(true);

    try {
      if (password.length < 8) {
        setError("Password must be at least 8 characters");
        return;
      }
      console.log("ici 2");
      await onConnect(mnemonic, password);
      onClose();
    } catch (err) {
      setError(
        err.response?.data?.message || "Invalid recovery phrase or password"
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center p-4 z-50">
    <div className="bg-[#1D1F24] w-full max-w-lg rounded-3xl relative overflow-hidden">
      <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />
      
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white/70 hover:text-white transition-colors"
      >
        X
      </button>

      <div className="p-10">
        <div className="text-center mb-10">
          <h2 className="text-3xl font-bold text-[#F4B000] mb-4">
            Connect Wallet
          </h2>
          <p className="text-white/70 text-lg">
            Enter your recovery phrase to connect
          </p>
        </div>

        <form onSubmit={handleConnect} className="space-y-6">
          <div>
            <label className="text-white/70 text-sm block mb-2">
              Recovery Phrase
            </label>
            <textarea
              value={mnemonic}
              onChange={(e) => setMnemonic(e.target.value)}
              className="w-full bg-white/5 border border-[#F4B000]/20 rounded-xl px-4 py-3 text-white placeholder-white/30 focus:outline-none focus:border-[#F4B000]/50"
              placeholder="Enter your 12-word phrase..."
              rows={3}
            />
          </div>

          <div>
            <label className="text-white/70 text-sm block mb-2">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-white/5 border border-[#F4B000]/20 rounded-xl px-4 py-3 text-white placeholder-white/30 focus:outline-none focus:border-[#F4B000]/50"
              placeholder="Enter password"
            />
          </div>

          {error && (
            <div className="text-[#FF6B6B] text-sm px-4 py-2 bg-[#FF6B6B]/10 rounded-lg">
              {error}
            </div>
          )}

          <div className="flex gap-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 py-3 px-4 bg-white/5 rounded-xl text-white hover:bg-white/10 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!mnemonic || !password || isLoading}
              className="flex-1 py-3 px-4 bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] rounded-xl text-white font-semibold hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? "Connecting..." : "Connect"}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  );
};

const WalletConnector = ({ onFetchProfile, saveProfile, onProfileUpdate }) => {
  const { account, createWallet, loading, connectWithMnemonic, isConnected } =
    useWallet();

  // Core states
  const [profile, setProfile] = useState(null);
  const [walletInfo, setWalletInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // UI states
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [showMnemonic, setShowMnemonic] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const { notify } = useNotification();

  // Profile fetching
  const fetchProfile = useCallback(async (address) => {
    if (!address) return null;

    try {
      const res = await axiosInstance.get(`/api/users/eth/${address}`);

      if (res.data) {
        const profileData = {
          ...res.data,
          image: res.data.imageUrl || null,
          experience: res.data.experience || {
            level: 1,
            currentXP: 0,
            totalXP: 0,
          },
          tournaments: res.data.tournaments || [],
          bets: res.data.bets || [],
          achievements: res.data.achievements || [],
          friendships: res.data.friendships || [],
          friendRequests: res.data.friendRequests || [],
          actionHistory: res.data.actionHistory || {
            teams: { creates: [], joins: [] },
          },
        };

        setProfile(profileData);
        return profileData;
      }
      return null;
    } catch (err) {
      if (err.response?.status === 404) return null;
      console.error("Error fetching profile:", err);
      return null;
    }
  }, []);

  // Profile creation flow
  const handleCreateWallet = async () => {
    try {
      if (isConnected) {
        handleDisconnect();
        return;
      }

      setIsLoading(true);
      const newWalletInfo = await createWallet();

      // S'assurer que toutes les informations sont sauvegardées
      const completeWalletInfo = {
        ...newWalletInfo,
        eth: normalizeAddress(newWalletInfo.address),
      };

      localStorage.setItem(
        "tempWalletInfo",
        JSON.stringify(completeWalletInfo)
      );
      setWalletInfo(completeWalletInfo);

      // Connecter le wallet immédiatement
      await connectWithMnemonic(
        completeWalletInfo.mnemonic,
        completeWalletInfo.password
      );

      setIsProfileModalOpen(true);
    } catch (error) {
      console.error("Error creating wallet:", error);
      notify("Error creating wallet");
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfileSaved = async (savedProfile) => {
    try {
      setIsLoading(true);

      // Pour un nouveau compte, on gère le stockage du wallet
      if (!profile) {
        // Si c'est un nouveau profil
        if (savedProfile.walletInfo) {
          localStorage.setItem("walletStatus", "connected");
          localStorage.setItem(
            "walletMnemonic",
            savedProfile.walletInfo.mnemonic
          );
          localStorage.setItem(
            "encryptedWallet",
            JSON.stringify(savedProfile.walletInfo)
          );

          const expiryTime = new Date().getTime() + 12 * 60 * 60 * 1000;
          localStorage.setItem("sessionExpiry", expiryTime.toString());

          localStorage.removeItem("tempWalletInfo");

          // Connecter avec les nouvelles informations
          await connectWithMnemonic(
            savedProfile.walletInfo.mnemonic,
            savedProfile.password
          );

          setShowMnemonic(true);
        }
      } else {
        // Pour une mise à jour de profil existant, on conserve la connexion actuelle
        const existingWalletInfo = JSON.parse(
          localStorage.getItem("encryptedWallet")
        );
        if (existingWalletInfo?.mnemonic && savedProfile.password) {
          await connectWithMnemonic(
            existingWalletInfo.mnemonic,
            savedProfile.password
          );
        }
      }

      setProfile(savedProfile);
      // Propager le changement à App.js
      onProfileUpdate(savedProfile);

      setIsProfileModalOpen(false);
    } catch (error) {
      console.error("Error in profile setup:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Mnemonic confirmation handler
  const handleMnemonicSaved = async () => {
    try {
      setIsLoading(true);

      // Fetch complete profile again to ensure we have latest data
      const fetchedProfile = await fetchProfile(normalizeAddress(account));
      if (fetchedProfile) {
        setProfile(fetchedProfile);

        // S'assurer que le wallet est bien connecté
        if (fetchedProfile.encryptedWallet?.mnemonic) {
          await connectWithMnemonic(
            fetchedProfile.encryptedWallet.mnemonic,
            fetchedProfile.password
          );
        }
      }

      // Reset UI states
      setShowMnemonic(false);
      setShowWallet(false);
    } catch (error) {
      console.error("Error completing profile setup:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectWallet = async (mnemonic, password) => {
    try {
      const tempWallet = HDNodeWallet.fromPhrase(mnemonic);
      const address = normalizeAddress(tempWallet.address);

      // Vérifier le mot de passe
      await axiosInstance.post("/api/users/verify-password", {
        eth: address,
        password: password,
      });

      const walletInfo = await connectWithMnemonic(mnemonic, password);
      setWalletInfo(walletInfo);

      const existingProfile = await fetchProfile(address);

      if (existingProfile) {
        setShowMnemonic(false);
        setShowWallet(false);
        setIsConnectModalOpen(false);
      } else {
        setShowMnemonic(false);
        setIsProfileModalOpen(true);
      }
    } catch (error) {
      console.error("Error connecting wallet:", error);
      // Propager l'erreur pour que le modal puisse l'afficher
      throw error;
    }
  };

  // Cleanup/Disconnect handler
  const handleDisconnect = useCallback(() => {
    // Nettoyer tous les états locaux
    setProfile(null);
    setWalletInfo(null);

    // Nettoyer tous les états UI
    setIsProfileModalOpen(false);
    setIsConnectModalOpen(false);
    setShowMnemonic(false);
    setShowWallet(false);

    // Nettoyer tout le localStorage
    localStorage.clear();

    // Propager la déconnexion au parent
    onProfileUpdate(null);

    window.location.reload();
  }, [onProfileUpdate]);

  // Initial profile load
  useEffect(() => {
    if (account && isConnected) {
      fetchProfile(account);
    }
  }, [account, isConnected, fetchProfile]);

  // Profile edit handler
  const handleEditProfile = () => {
    setIsProfileModalOpen(true);
  };

  if (loading || isLoading) {
    return (
      <button className="cta-button" disabled>
        Loading...
      </button>
    );
  }

  return (
    <div className="flex flex-col items-center">
      {isProfileModalOpen && (
        <ProfileModal
          isOpen={true}
          onRequestClose={() => setIsProfileModalOpen(false)}
          saveProfile={handleProfileSaved}
          eth={normalizeAddress(account)}
          walletInfo={walletInfo}
          existingProfile={{
            name: profile?.name || "",
            bio: profile?.bio || "",
            image: profile?.image,
            eth: normalizeAddress(account),
            currentUserId: profile,
          }}
          isNewAccount={!profile}
          isConnected={isConnected}
        />
      )}

      {!profile ? (
        <div className="flex gap-4 cta-buttons">
          <button className="cta-button" onClick={handleCreateWallet}>
            Register
          </button>
          <button
            className="cta-button2"
            onClick={() => setIsConnectModalOpen(true)}
          >
            Connect
          </button>
        </div>
      ) : (
        <>
          {showMnemonic ? (
            <WalletInterface
              onMnemonicSaved={handleMnemonicSaved}
              forceShowMnemonic={true}
            />
          ) : (
            profile && (
              <>
                {showWallet ? (
                  <WalletInterface
                    onMnemonicSaved={handleMnemonicSaved}
                    forceShowMnemonic={false}
                    pseudo={profile.name}
                  />
                ) : (
                  <Dashboard
                    name={profile.name}
                    eth={normalizeAddress(account)}
                    bio={profile.bio}
                    image={profile.image}
                    onEditProfile={handleEditProfile}
                    saveProfile={handleProfileSaved}
                    currentUserId={profile}
                    experience={profile.experience}
                  />
                )}
              </>
            )
          )}
        </>
      )}

      <ConnectWalletModal
        isOpen={isConnectModalOpen}
        onClose={() => setIsConnectModalOpen(false)}
        onConnect={handleConnectWallet}
      />
    </div>
  );
};

export default WalletConnector;
