import React, {
  useEffect,
  useState,
  lazy,
  Suspense,
  useTransition,
} from "react";
import {
  Trophy,
  Users,
  Star,
  Sparkles,
  Crown,
  BarChart3,
  MessageSquare,
  CheckCircle,
  XCircle,
  X,
} from "lucide-react";
import axiosInstance from "../../axiosConfig";
import SEOManager from "../SEO/SEOManager";
import { useNavigate } from 'react-router-dom';

const RoadmapWeb3 = lazy(() => import("./RoadmapWeb3"));
const IcoStatus = lazy(() => import("../ICO/IcoStatus"));

// Composant Alert intégré directement pour simplifier
const CustomAlert = ({ message, type = "success", onClose }) => {
  const alertStyles = {
    success: {
      bg: "bg-[#2D332D]",
      border: "border-[#4CAF50]/20",
      text: "text-[#4CAF50]",
      icon: <CheckCircle className="w-5 h-5" />,
    },
    error: {
      bg: "bg-[#332D2D]",
      border: "border-[#FF6B6B]/20",
      text: "text-[#FF6B6B]",
      icon: <XCircle className="w-5 h-5" />,
    },
  };

  const style = alertStyles[type];

  return (
    <div
      className={`flex items-center justify-between ${style.bg} ${style.border} border rounded-lg p-4`}
    >
      <div className="flex items-center space-x-3">
        <span className={style.text}>{style.icon}</span>
        <span className="text-white text-sm font-medium">{message}</span>
      </div>
      {onClose && (
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-gray-300 transition-colors"
        >
          <X className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

const Web3EsportsPage = (profile) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertState, setAlertState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [isPending] = useTransition();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/whitepaper');
  };

  const handleClickAffiliate = () => {
    navigate('/affiliation');
  };

  const games = [
    { name: "Valorant", reward: "48K REKT" },
    { name: "CS2", reward: "48K REKT" },
    { name: "Dota 2", reward: "48K REKT" },
    { name: "LoL", reward: "48K REKT" },
  ];

  // Fonction pour valider l'email
  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  // Fonction pour gérer la soumission de l'email
  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    // Validation de l'email
    if (!validateEmail(email)) {
      setAlertState({
        show: true,
        type: "error",
        message: "Please enter a valid email address",
      });
      return;
    }

    setIsSubmitting(true);
    setAlertState({ show: false, type: "", message: "" });

    try {
      const response = await axiosInstance.post("/api/subscribe", { email });

      if (response.status === 200) {
        setAlertState({
          show: true,
          type: "success",
          message: "Thank you for your interest! We'll keep you updated.",
        });
        setEmail(""); // Reset email field
      } else {
        throw new Error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to submit email. Please try again.";
      setAlertState({
        show: true,
        type: "error",
        message: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const useScrollAnimation = () => {
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("opacity-100", "translate-y-0");
            entry.target.classList.remove("opacity-0", "translate-y-20");
          }
        });
      };

      const observer = new IntersectionObserver(
        handleIntersection,
        observerOptions
      );

      document
        .querySelectorAll(".scroll-animation:not(h1)")
        .forEach((element) => {
          element.classList.add(
            "opacity-0",
            "translate-y-20",
            "transition-all",
            "duration-700",
            "ease-out"
          );
          observer.observe(element);
        });

      return () => observer.disconnect();
    }, []);
  };

  useScrollAnimation();

  return (
    <>
      <SEOManager
        title="REKT Esport | Esport & Web3 Gaming"
        description="Discover the REKT platform: esports tournaments, betting and staking"
      />
      <div className="relative bg-[#1D1F24] min-h-screen text-white z-[0]">
        <div className="rondBizarreOrangeQuiClignote" />
        {/* Hero Section */}
        <section className="min-h-screen flex flex-col lg:flex-row items-center justify-center px-4 sm:px-6 lg:px-8 relative py-20 lg:py-20 z-[1] mainContentHome">
          <div className="max-w-[600px] text-center w-full lg:ml-0 xl:ml-0 relative z-1 space-y-6 lg:space-y-8 scroll-animation">
            <h1 className="text-4xl lg:text-6xl font-bold leading-tight">
              The Future of{" "}
              <span className="text-[#F4B000] block sm:inline">
                Competitive Gaming
              </span>{" "}
              is Here
            </h1>

            <p className="opacity-80 text-[#9da5b1] text-base sm:text-lg max-w-xl mx-auto">
              Join the revolution where Web3 meets esports. Compete in 8-team
              tournaments across multiple divisions and earn REKT tokens.
              <style>{`
                @media (max-width: 820px) {
                  p {
                    display: none;
                  }
                }
              `}</style>
            </p>

            <div className="mt-8 lg:mt-0 scroll-animation flex justify-center">
              <Suspense fallback={<div>Loading...</div>}>
                {isPending ? <div>Loading...</div> : <IcoStatus />}
              </Suspense>
            </div>

            <div className="flex sm:flex-row flex-col gap-2 justify-center w-full">
              <button
                onClick={handleClick}
                className="bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] px-6 sm:px-8 py-3 rounded-full font-semibold hover:-translate-y-0.5 transition-transform w-full sm:w-auto"
              >
                Learn More
              </button>
              <button
                onClick={handleClickAffiliate}
                className="bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] px-6 sm:px-8 py-3 rounded-full font-semibold hover:-translate-y-0.5 transition-transform w-full sm:w-auto"
              >
                {profile?.profile?.affiliated === true
                  ? "Affiliation Dashboard"
                  : "Get Affiliation"}
              </button>
            </div>

            <div className="grid grid-cols-3 gap-6 sm:gap-12 mt-8 sm:mt-12 scroll-animation">
              <div className="text-center">
                <div className="text-3xl sm:text-4xl font-bold text-[#F4B000]">
                  8
                </div>
                <div className="opacity-70 text-sm sm:text-base">Divisions</div>
              </div>
              <div className="text-center">
                <div className="text-3xl sm:text-4xl font-bold text-[#F4B000]">
                  48K+
                </div>
                <div className="opacity-70 text-sm sm:text-base">
                  REKT Max Prize
                </div>
              </div>
              <div className="text-center">
                <div className="text-3xl sm:text-4xl font-bold text-[#F4B000]">
                  5+
                </div>
                <div className="opacity-70 text-sm sm:text-base">Games</div>
              </div>
            </div>
          </div>
        </section>

        {/* Epic Team Teasing Section */}
        <section className="py-20 px-4 sm:px-6 lg:px-8 relative bg-[#1A1C21] overflow-hidden mainContentHome">
          <div className="absolute inset-0 bg-gradient-to-r from-[#F4B000]/20 to-transparent" />

          <div className="max-w-6xl mx-auto relative">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
              <div className="lg:w-1/2 space-y-6 scroll-animation">
                <div className="inline-block bg-[#F4B000]/10 px-4 py-2 rounded-full">
                  <span className="text-[#F4B000] font-semibold">
                    Coming Soon
                  </span>
                </div>

                <span className="text-4xl lg:text-5xl font-bold flex w-full text-left">
                  Join the&nbsp;
                  <span className="text-[#F4B000]">Revolution</span>
                </span>

                <p className="text-xl text-[#9da5b1]">
                  We're not just building a platform. We're creating a
                  professional esports team that will reshape the industry.
                </p>

                <div className="space-y-4">
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#F4B000] rounded-full" />
                    <span className="text-lg">
                      Professional players and coaches
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#F4B000] rounded-full" />
                    <span className="text-lg">
                      State-of-the-art training facility
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#F4B000] rounded-full" />
                    <span className="text-lg">
                      Multi-game competitive presence
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-2 h-2 bg-[#F4B000] rounded-full" />
                    <span className="text-lg">
                      Revolutionary fan engagement system
                    </span>
                  </div>
                </div>

                {/* Register Interest Form */}
                <div className="flex items-center gap-2">
                  <span className="text-lg">Want to become a Pro Player ?</span>
                </div>
                <form onSubmit={handleEmailSubmit} className="space-y-4">
                  <div className="flex flex-col sm:flex-row gap-4">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className="px-6 py-3 rounded-full bg-[#252832] border border-[#F4B000]/30 focus:outline-none focus:border-[#F4B000] w-full sm:w-auto"
                      disabled={isSubmitting}
                    />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-[#F4B000] text-black px-8 py-3 rounded-full font-semibold hover:-translate-y-0.5 transition-transform whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? "Submitting..." : "Apply"}
                    </button>
                  </div>

                  {/* Nouvel Alert personnalisé */}
                  {alertState.show && (
                    <CustomAlert
                      type={alertState.type}
                      message={alertState.message}
                      onClose={() =>
                        setAlertState({ show: false, type: "", message: "" })
                      }
                    />
                  )}
                </form>
              </div>

              {/* Stats/Countdown Side */}
              <div className="lg:w-1/2 scroll-animation">
                <div className="bg-[#252832] rounded-xl p-8 backdrop-blur-lg bg-opacity-50">
                  {/* Countdown */}
                  <div className="text-center">Coming Soon</div>
                  <div className="grid grid-cols-4 gap-4 mb-8">
                    {/* <div className="text-center">
                    <div className="text-3xl font-bold text-[#F4B000]">12</div>
                    <div className="text-sm text-[#9da5b1]">Days</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-[#F4B000]">18</div>
                    <div className="text-sm text-[#9da5b1]">Hours</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-[#F4B000]">45</div>
                    <div className="text-sm text-[#9da5b1]">Minutes</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-[#F4B000]">30</div>
                    <div className="text-sm text-[#9da5b1]">Seconds</div>
                  </div> */}
                  </div>

                  {/* Epic Stats */}
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      {/* <span>Team Budget Secured</span> */}
                      {/* <span className="text-[#F4B000] font-bold">$2.5M</span> */}
                    </div>
                    {/* <div className="w-full bg-[#1D1F24] rounded-full h-2">
                    <div className="bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] w-3/4 h-2 rounded-full" />
                  </div> */}

                    <div className="grid grid-cols-2 gap-4 mt-6">
                      <div className="bg-[#1D1F24] p-4 rounded-lg text-center">
                        <div className="text-2xl font-bold text-[#F4B000]">
                          5
                        </div>
                        <div className="text-sm text-[#9da5b1]">
                          Games Coverage
                        </div>
                      </div>
                      <div className="bg-[#1D1F24] p-4 rounded-lg text-center">
                        <div className="text-2xl font-bold text-[#F4B000]">
                          25+
                        </div>
                        <div className="text-sm text-[#9da5b1]">
                          Pro Players
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Team System */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 mainContentHome">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-4 scroll-animation">
              Team&nbsp;<span className="text-[#F4B000]">Features</span>
            </h2>

            <p className="text-center text-[#9da5b1] text-lg mb-12 max-w-2xl mx-auto">
              Create your dream team, compete in tournaments, and earn rewards
              together through our innovative team management system
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
              {/* Scholarship System Card */}
              <div className="bg-[#252832] p-8 rounded-xl hover:bg-[#2A2E38] transition-colors scroll-animation">
                <div className="flex items-start mb-6 scroll-animation">
                  <Users className="w-12 h-12 text-[#F4B000] mr-4 flex-shrink-0" />
                  <div>
                    <h3 className="text-2xl font-bold mb-3">
                      Team Scholarship System
                    </h3>
                    <ul className="space-y-3 text-[#9da5b1]">
                      <li className="flex items-center">
                        <div className="w-2 h-2 bg-[#F4B000] rounded-full mr-2 flex-shrink-0" />
                        <span>
                          Captains earn 5% of team winnings through scholarship
                          program
                        </span>
                      </li>
                      <li className="flex items-center">
                        <div className="w-2 h-2 bg-[#F4B000] rounded-full mr-2 flex-shrink-0" />
                        <span>Manage your roster of up to 8 players</span>
                      </li>
                      <li className="flex items-center">
                        <div className="w-2 h-2 bg-[#F4B000] rounded-full mr-2 flex-shrink-0" />
                        <span>
                          Smart contract ensures automatic reward distribution
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Rankings & Achievements Card */}
              <div className="bg-[#252832] p-8 rounded-xl hover:bg-[#2A2E38] transition-colors scroll-animation">
                <div className="flex items-start mb-6 scroll-animation">
                  <BarChart3 className="w-12 h-12 text-[#F4B000] mr-4 flex-shrink-0" />
                  <div>
                    <h3 className="text-2xl font-bold mb-3">
                      Rankings & Achievements
                    </h3>
                    <ul className="space-y-3 text-[#9da5b1]">
                      <li className="flex items-center">
                        <div className="w-2 h-2 bg-[#F4B000] rounded-full mr-2 flex-shrink-0" />
                        <span>
                          Earn team experience and level up through competitions
                        </span>
                      </li>
                      <li className="flex items-center">
                        <div className="w-2 h-2 bg-[#F4B000] rounded-full mr-2 flex-shrink-0" />
                        <span>
                          Unlock achievements for special team milestones
                        </span>
                      </li>
                      <li className="flex items-center">
                        <div className="w-2 h-2 bg-[#F4B000] rounded-full mr-2 flex-shrink-0" />
                        <span>
                          Compete in seasonal rankings for additional rewards
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Additional Features */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 scroll-animation">
              <div className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors text-center scroll-animation">
                <Trophy className="w-12 h-12 text-[#F4B000] mb-4 mx-auto" />
                <h3 className="text-xl font-bold mb-2">Performance Bonuses</h3>
                <p className="text-[#9da5b1]">
                  Earn bonus REKT tokens based on your team's ranking position
                  and division advancement
                </p>
              </div>

              <div className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors text-center scroll-animation">
                <Crown className="w-12 h-12 text-[#F4B000] mb-4 mx-auto" />
                <h3 className="text-xl font-bold mb-2">Team Profile</h3>
                <p className="text-[#9da5b1]">
                  Customize your team profile, showcase achievements, and track
                  your tournament history
                </p>
              </div>

              <div className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors text-center scroll-animation">
                <MessageSquare className="w-12 h-12 text-[#F4B000] mb-4 mx-auto" />
                <h3 className="text-xl font-bold mb-2">Team Communication</h3>
                <p className="text-[#9da5b1]">
                  Integrated team chat and tournament scheduling tools for
                  better coordination
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Division System */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 mainContentHome">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 scroll-animation">
              Climb the&nbsp;<span className="text-[#F4B000]">Divisions</span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors scroll-animation">
                <Crown className="w-12 h-12 text-[#F4B000] mb-4" />
                <h3 className="text-xl font-bold mb-2">Division 8</h3>
                <p className="text-[#9da5b1] mb-2">Elite Competition</p>
                <div className="text-[#F4B000] font-bold">
                  48K REKT$ Prize Pool
                </div>
              </div>

              <div className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors scroll-animation">
                <Trophy className="w-12 h-12 text-[#F4B000] mb-4" />
                <h3 className="text-xl font-bold mb-2">Divisions 5-7</h3>
                <p className="text-[#9da5b1] mb-2">Advanced Leagues</p>
                <div className="text-[#F4B000] font-bold">12K-24K REKT$</div>
              </div>

              <div className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors scroll-animation">
                <Star className="w-12 h-12 text-[#F4B000] mb-4" />
                <h3 className="text-xl font-bold mb-2">Divisions 3-4</h3>
                <p className="text-[#9da5b1] mb-2">Intermediate Leagues</p>
                <div className="text-[#F4B000] font-bold">3K-6K REKT$</div>
              </div>

              <div className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors scroll-animation">
                <Sparkles className="w-12 h-12 text-[#F4B000] mb-4" />
                <h3 className="text-xl font-bold mb-2">Divisions 1-2</h3>
                <p className="text-[#9da5b1] mb-2">Entry Leagues</p>
                <div className="text-[#F4B000] font-bold">350-1.5K REKT$</div>
              </div>
            </div>
          </div>
        </section>

        {/* Games Section */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 bg-[#252832] mainContentHome">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 scroll-animation">
              Featured&nbsp;<span className="text-[#F4B000]">Games</span>
            </h2>

            <div className="flex flex-wrap justify-center gap-6 max-w-[1200px] mx-auto">
              {games.map((game, index) => (
                <div
                  key={index}
                  className="bg-[#1D1F24] rounded-xl p-6 text-center hover:transform hover:-translate-y-1 transition-all w-48 scroll-animation"
                >
                  <h3 className="text-xl font-bold mb-2">{game.name}</h3>
                  <p className="text-[#F4B000] font-semibold">
                    Up to {game.reward}$
                  </p>
                  <div className="mt-3 text-sm text-[#9da5b1]">
                    8-Team Tournaments
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Suspense fallback={<div>Loading...</div>}>
          {isPending ? <div>Loading...</div> : <RoadmapWeb3 />}
        </Suspense>

        {/* CTA Section */}
        <section className="py-16 px-4 sm:px-6 lg:px-8 mainContentHome">
          <div className="max-w-4xl mx-auto text-center scroll-animation">
            <h2 className="text-xl sm:text-4xl font-bold mb-6">
              Ready to&nbsp;
              <span className="text-[#F4B000]">Start Your Journey</span>&nbsp;?
            </h2>
            <p className="text-[#9da5b1] mb-8 text-lg">
              Join now to compete in 8-team tournaments and climb through the
              divisions
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Web3EsportsPage;
