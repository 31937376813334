import React, { useState, useEffect, useRef } from "react";
import Invitations from "./Invitations";
import axiosInstance from "../../axiosConfig";
import styles from "../../styles/NotificationButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useWallet } from "../../context/WalletContext";
import { ethers } from "ethers";

const FAUCET_ADDRESS = "0x9655e22670Da4Ae0b74B0de547e7EaF38799c590";
const FAUCET_ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "requestTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "lastRequestTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const NotificationButton = ({ userId }) => {
  const [show, setShow] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { account, provider } = useWallet();
  const creatingNotification = useRef(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setIsLoading(true);

        if (account && provider) {
          const faucetContract = new ethers.Contract(FAUCET_ADDRESS, FAUCET_ABI, provider);
          const lastClaimTime = await faucetContract.lastRequestTime(account);
          const currentTime = Math.floor(Date.now() / 1000);
          const lastClaimTimeNum = parseInt(lastClaimTime.toString());
  
          if (lastClaimTimeNum === 0 || currentTime >= lastClaimTimeNum + 24 * 60 * 60) {
            // Vérifier si une notification existe déjà
            const existingNotifications = await axiosInstance.get(
              `/api/notifications/user/${userId}?type=faucet_available`
            );
  
            if (existingNotifications.data.length === 0) {
              try {
                if (creatingNotification.current) return;
                creatingNotification.current = true;
  
                await axiosInstance.post("/api/notifications", {
                  type: "faucet_available",
                  sender: "REKT Faucet",
                  content:
                    "Your daily 100 REKT tokens are available! Claim them now.",
                  recipients: [
                    {
                      userId: userId,
                      read: false,
                    },
                  ],
                });
              } catch (err) {
                console.error("Error creating notification:", err);
              } finally {
                creatingNotification.current = false;
              }
            }
          }
        }

        const [invitationsRes, requestsRes, mentionsRes, userRes, faucetRes] = await Promise.all([
          axiosInstance.get(`/api/invitations/user/${userId}`),
          axiosInstance.get(`/api/invitations/requests/${userId}`),
          axiosInstance.get(`/api/notifications/user/${userId}?type=everyone_mention`),
          account ? axiosInstance.get(`/api/users/eth/${account}`) : Promise.resolve({ data: { friendRequests: [] } }),
          axiosInstance.get(`/api/notifications/user/${userId}?type=faucet_available`)
        ]);

        const invitations = Array.isArray(invitationsRes.data)
          ? invitationsRes.data
          : [];
        const requests = Array.isArray(requestsRes.data)
          ? requestsRes.data
          : [];
        const mentions = Array.isArray(mentionsRes.data)
          ? mentionsRes.data
          : [];
        const friendRequests = userRes.data.friendRequests || [];
        const faucetNotifications = Array.isArray(faucetRes.data) // Ajout de cette ligne
        ? faucetRes.data
        : [];

        setNotificationCount(
          invitations.length + 
          requests.length + 
          mentions.length + 
          friendRequests.length +
          faucetNotifications.length
        );
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setNotificationCount(0);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchNotifications();
      const intervalId = setInterval(fetchNotifications, 5000);
      return () => clearInterval(intervalId);
    }
  }, [userId, account, provider]);

  return (
    <div className="relative z-20">
      <button
        onClick={() => setShow(true)}
        className={styles.notificationButton}
        disabled={isLoading}
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          className={`h-[37px] w-[37px] ${
            isLoading ? "text-[#5a5a5a69] hover:text-[#F4B000]/10" : "text-[#5a5a5a69] hover:text-[#F4B000]/10"
          }`}
        />
        {notificationCount > 0 && (
          <span className={`${styles.notificationBadge} new`}>
            {notificationCount}
          </span>
        )}
      </button>

      {show && (
        <div 
          className={styles.overlay}
          onClick={() => setShow(false)}
        >
          <div 
            className={styles.notificationModal}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center m-4">
              <h2 className="text-xl font-semibold">Notifications</h2>
            </div>
            <div className="m-4 max-h-auto">
              <Invitations userId={userId} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationButton;
