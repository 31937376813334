import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../axiosConfig";
import { useWallet } from "../context/WalletContext";
import styles from "../styles/ChatButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faUser,
  faReply,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ModalProfile from "./Profile/ModalProfile";
import { io } from "socket.io-client";
import config from "../config";
import { useNotification } from "./Notifications/Notifications";

const ChatModal = ({ isOpen, onClose, chatType }) => {
  // États essentiels
  const { account } = useWallet();
  const [user, setUser] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [currentChat, setCurrentChat] = useState(chatType || "general");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [matchChats, setMatchChats] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isVotingOpen, setIsVotingOpen] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [selectedProfileModal, setSelectedProfileModal] = useState({
    isOpen: false,
    userId: null,
    eth: null,
  });
  const [replyingTo, setReplyingTo] = useState(null);
  const [isCaptain, setIsCaptain] = useState(false);
  const [showMentionSuggestions, setShowMentionSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const { notify } = useNotification();

  // Refs
  const chatEndRef = useRef(null);
  const chatWindowRef = useRef(null);
  const messageInputRef = useRef(null);
  const mentionSuggestionsRef = useRef(null);
  const socketRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      setShowScrollButton(false);
    }, 100);
  }, []);

  const SOCKET_URL = config.apiBaseUrl;

  useEffect(() => {
    if (!user?._id || !isOpen) return;

    const newSocket = io(SOCKET_URL, {
      auth: { userId: user._id },
      path: "/socket.io",
      transports: ["websocket"],
      secure: true,
      rejectUnauthorized: false,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    newSocket.on("connect", () => {
      console.log("Connected to chat server");
      setIsConnected(true);

      // Authenticate immediately after connection
      newSocket.emit("authenticate", { userId: user._id });

      // Join appropriate rooms
      const rooms = [
        "general",
        teamId ? `clan-${teamId}` : null,
        ...matchChats.map((chat) => `match-${chat.matchId}`),
      ].filter(Boolean);

      newSocket.emit("joinChatRooms", {
        userId: user._id,
        rooms: rooms,
      });
    });

    socketRef.current = newSocket;
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      socketRef.current = null;
    };
  }, [user?._id, isOpen, SOCKET_URL, matchChats, teamId]); // Dépendances minimales pour la connexion socket

  useEffect(() => {
    if (!socket) return;

    // Nettoyer les messages lors du changement de chat
    setMessages([]);

    socket.on("chatHistory", ({ room, messages: historyMessages }) => {
      // Vérifier que l'historique correspond au chat actuel
      if (
        room === currentChat ||
        (room === `clan-${teamId}` && currentChat === "clan") ||
        (room === `match-${currentChat.split("-")[1]}` &&
          currentChat.startsWith("match-"))
      ) {
        setMessages(historyMessages);
        scrollToBottom();
      }
    });

    // Vérifier que le nouveau message correspond au chat actuel
    socket.on("newMessage", (message) => {
      const messageRoom = message.room || currentChat;
      if (
        messageRoom === currentChat ||
        (messageRoom === `clan-${teamId}` && currentChat === "clan") ||
        (messageRoom === `match-${currentChat.split("-")[1]}` &&
          currentChat.startsWith("match-"))
      ) {
        setMessages((prev) => [...prev, message]);
        scrollToBottom();
      }
    });

    socket.on("chatError", (error) => {
      console.error("Chat error:", error);
    });

    socket.on("notification", (notification) => {
      console.log("Received notification:", notification);
    });

    // Émettre joinChatRooms pour le nouveau chat
    socket.emit("joinChatRooms", {
      userId: user?._id,
      rooms: [currentChat === "clan" ? `clan-${teamId}` : currentChat],
    });

    return () => {
      socket.off("chatHistory");
      socket.off("newMessage");
      socket.off("chatError");
      socket.off("notification");
    };
  }, [socket, currentChat, teamId, scrollToBottom, user]);

  useEffect(() => {
    const checkCaptainStatus = async () => {
      if (!user?._id || !teamId) return;
      try {
        const { data } = await axiosInstance.get(`/api/teams/${teamId}`);
        setIsCaptain(data.captain === user._id);
      } catch (error) {
        console.error("Error checking captain status:", error);
        setIsCaptain(false);
      }
    };

    checkCaptainStatus();
  }, [user, teamId]);

  const handleInput = (e) => {
    const textArea = e.target;
    const cursorPos = textArea.selectionStart;
    const text = textArea.value;

    setNewMessage(text);
    setCursorPosition(cursorPos);

    if (socket && isConnected) {
      socket.emit("typing", {
        room: currentChat === "clan" ? `clan-${teamId}` : currentChat,
        isTyping: text.length > 0,
      });
    }

    if (text[cursorPos - 1] === "@" && currentChat === "clan") {
      setShowMentionSuggestions(true);
    } else {
      setShowMentionSuggestions(false);
    }
  };

  const handleKeyDown = (e) => {
    if (showMentionSuggestions && e.key === "Tab") {
      e.preventDefault();
      if (isCaptain) {
        const beforeCursor = newMessage.slice(0, cursorPosition - 1);
        const afterCursor = newMessage.slice(cursorPosition);
        setNewMessage(`${beforeCursor}@everyone${afterCursor}`);
        setShowMentionSuggestions(false);
        messageInputRef.current.focus();
      }
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const mentionSuggestions = (
    <div
      ref={mentionSuggestionsRef}
      className={`${styles.mentionSuggestions} ${
        showMentionSuggestions ? styles.show : ""
      }`}
    >
      {isCaptain ? (
        <div
          className={styles.mentionItem}
          onClick={() => {
            const beforeCursor = newMessage.slice(0, cursorPosition - 1);
            const afterCursor = newMessage.slice(cursorPosition);
            setNewMessage(`${beforeCursor}@everyone${afterCursor}`);
            setShowMentionSuggestions(false);
            messageInputRef.current.focus();
          }}
        >
          <FontAwesomeIcon icon={faUsers} className="mr-2" />
          @everyone
        </div>
      ) : (
        <div className={styles.mentionDisabled}>
          Only team captain can mention everyone
        </div>
      )}
    </div>
  );

  useEffect(() => {
    // Ne scroll au chargement initial des messages que si c'est la première fois
    if (isOpen && messages.length && !chatWindowRef.current.scrollTop) {
      scrollToBottom();
    }
  }, [isOpen, messages, scrollToBottom]);

  const checkScroll = useCallback(() => {
    const chatWindow = chatWindowRef.current;
    if (!chatWindow) return;
    const { scrollTop, scrollHeight, clientHeight } = chatWindow;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
    setShowScrollButton(!isNearBottom);
  }, []);

  const fetchMatchChats = useCallback(async () => {
    if (!teamId) return;
    try {
      const { data } = await axiosInstance.get(
        `/api/matches/teamMatches/${teamId}`
      );
      const activeChats = data.filter((chat) => !chat.winner);
      setMatchChats(activeChats || []);
    } catch (error) {
      console.error("Failed to fetch match chats", error);
      setMatchChats([]);
    }
  }, [teamId]);

  useEffect(() => {
    fetchMatchChats();
  }, [teamId, fetchMatchChats]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!account) return;
      try {
        const { data } = await axiosInstance.get(`/api/users/eth/${account}`);
        setUser(data);
        setTeamId(data.team);
        if (!data.team && currentChat === "clan") {
          setCurrentChat("general");
        }
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    };

    fetchUserData();
  }, [account, currentChat]);

  const sendMessage = async (e) => {
    e?.preventDefault();
    if (!newMessage.trim() || !socket || !isConnected) return;
    if (newMessage.includes("@everyone") && !isCaptain) return;

    try {
      socket.emit("sendMessage", {
        room: currentChat === "clan" ? `clan-${teamId}` : currentChat,
        text: newMessage,
        replyTo: replyingTo
          ? {
              messageId: replyingTo.messageId,
              sender: replyingTo.sender,
              content: replyingTo.content,
            }
          : null,
      });

      setNewMessage("");
      setReplyingTo(null);
    } catch (error) {
      console.error("Failed to send message", error);
    }
  };

  // Gestion des votes
  const handleVote = async (vote) => {
    if (!currentChat.startsWith("match-")) return;

    try {
      const matchId = currentChat.split("-")[1];
      const { data } = await axiosInstance.post(
        `/api/matches/${matchId}/vote`,
        {
          teamId,
          userId: user._id,
          vote,
        }
      );

      const messages = {
        pending: "Vote recorded. Waiting for the other team.",
        disagreement: "Disagreement. Please provide photo evidence.",
        complete: "Match over. The winner has been determined.",
      };

      notify(messages[data.status] || "Error during voting");
      if (data.status === "complete") {
        setCurrentChat("general");
        // Rafraîchir la liste des matchs pour voir les changements
        fetchMatchChats();
      }
    } catch (error) {
      console.error("Failed to vote", error);
      notify("Error during voting");
    }

    // Ajout de l'animation de sortie
    setIsExiting(true);
    setTimeout(() => {
      setIsVotingOpen(false);
      setIsExiting(false);
    }, 300);
  };

  const MessageComponent = ({ msg }) => {
    const isEveryoneMention =
      currentChat === "clan" && msg.content.includes("@everyone");

    const handleAvatarClick = () => {
      if (msg.sender !== "System" && msg.userId) {
        setSelectedProfileModal({
          isOpen: true,
          userId: msg.userId,
          eth: msg.eth,
        });
      }
    };

    const handleReply = () => {
      if (msg._id) {
        setReplyingTo({
          messageId: msg._id.toString(),
          sender: msg.sender,
          content: msg.content,
        });
        messageInputRef.current?.focus();
      }
    };

    const isReplyToCurrentUser =
      msg.replyTo && user && msg.replyTo.sender === user.name;

    return (
      <div
        id={`message-${msg._id}`}
        className={`${styles.message} ${
          msg.sender === "System" ? styles.systemMessage : ""
        } ${isReplyToCurrentUser ? styles.replyToMe : ""}
        ${isEveryoneMention ? styles.everyoneMessage : ""}`}
      >
        <div className={styles.messageContainer}>
          {msg.sender !== "System" && (
            <div className={styles.avatarContainer} onClick={handleAvatarClick}>
              {msg.imageUrl ? (
                <img
                  src={msg.imageUrl}
                  alt={`${msg.sender}'s avatar`}
                  className={styles.avatar}
                  onError={(e) => (e.target.src = "/default-avatar.png")}
                />
              ) : (
                <FontAwesomeIcon icon={faUser} className={styles.avatarIcon} />
              )}
            </div>
          )}
          <div className={styles.messageWrapper}>
            {/* Si c'est une réponse, montrer l'aperçu du message d'origine */}
            {msg.replyTo && (
              <div
                className={styles.replyPreview}
                onClick={() => {
                  const originalMessage = document.getElementById(
                    `message-${msg.replyTo.messageId}`
                  );
                  if (originalMessage) {
                    originalMessage.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                    originalMessage.classList.add(styles.highlighted);
                    setTimeout(
                      () =>
                        originalMessage.classList.remove(styles.highlighted),
                      1500
                    );
                  }
                }}
              >
                <div className={styles.replyHeader}>
                  <FontAwesomeIcon
                    icon={faReply}
                    className={styles.replyIcon}
                  />
                  <span className={styles.repliedToUser}>
                    {msg.replyTo.sender}
                  </span>
                </div>
                <div className={styles.repliedContent}>
                  {msg.replyTo.content}
                </div>
              </div>
            )}

            {/* Message principal */}
            <div className={styles.gridMessage}>
              <span className={styles.username}>{msg.sender}</span>
              <span className={styles.timestamp}>{msg.timestamp}</span>
              {msg.sender !== "System" && (
                <FontAwesomeIcon
                  icon={faReply}
                  className={styles.replyButton}
                  onClick={handleReply}
                />
              )}
            </div>
            <div className={styles.messageContent}>{msg.content}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.modal} ${isOpen ? styles.open : ""}`}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose(messages);
        }
      }}
    >
      <div className={styles.modalContent}>
        <div className={styles.chatContainer}>
          {/* Sidebar avec les bons styles */}
          <div className={styles.sidebar}>
            <div className={styles.chatSelector}>
              <button
                className={`${styles.chatButton} ${
                  currentChat === "general" ? styles.active : ""
                }`}
                onClick={() => setCurrentChat("general")}
              >
                Global
              </button>
              {teamId && (
                <button
                  className={`${styles.chatButton} ${
                    currentChat === "clan" ? styles.active : ""
                  }`}
                  onClick={() => setCurrentChat("clan")}
                >
                  Team
                </button>
              )}
              {matchChats
                .filter((chatInfo) => !chatInfo.winner)
                .map((chatInfo) => (
                  <button
                    key={chatInfo._id}
                    className={`${styles.chatButton} ${
                      currentChat === `match-${chatInfo.matchId}`
                        ? styles.active
                        : ""
                    }`}
                    onClick={() => setCurrentChat(`match-${chatInfo.matchId}`)}
                  >
                    {chatInfo.roundNumber === 1
                      ? "Round 1"
                      : chatInfo.roundNumber === 2
                      ? "Round 2"
                      : "Finale"}{" "}
                    - {chatInfo.teams.map((team) => team.name).join(" vs ")}
                  </button>
                ))}
            </div>
          </div>

          {/* Main content avec les bons styles */}
          <div className={styles.mainContent}>
            <div
              ref={chatWindowRef}
              className={`${styles.chatWindow} ${
                isVotingOpen ? styles.withVotingPanel : ""
              }`}
              onScroll={checkScroll}
            >
              {messages.map((msg, i) => (
                <MessageComponent key={msg._id || i} msg={msg} />
              ))}
              <div ref={chatEndRef} />
              {showScrollButton && (
                <button
                  className={styles.scrollButton}
                  onClick={scrollToBottom}
                  aria-label="Scroll to bottom"
                >
                  <FontAwesomeIcon icon={faChevronDown} size="lg" />
                </button>
              )}
            </div>

            <div className={styles.chatInput}>
              {isVotingOpen && (
                <div
                  className={`${styles.votingPanel} ${
                    isExiting ? styles.exiting : ""
                  }`}
                >
                  <button onClick={() => handleVote("win")}>Win</button>
                  <button onClick={() => handleVote("lose")}>Lose</button>
                </div>
              )}

              {replyingTo && (
                <div className={styles.replyingToPreview}>
                  <div className={styles.replyInfo}>
                    <span>
                      <FontAwesomeIcon
                        icon={faReply}
                        className={styles.replyIcon}
                      />
                      Replying to {replyingTo.sender}
                    </span>
                    <div className={styles.replyToContent}>
                      {replyingTo.content}
                    </div>
                  </div>
                  <button
                    onClick={() => setReplyingTo(null)}
                    className={styles.cancelReply}
                  >
                    ×
                  </button>
                </div>
              )}

              <div className={styles.messageActions}>
                <div className={styles.textareaWrapper}>
                  <textarea
                    ref={messageInputRef}
                    className={styles.messageTextarea}
                    value={newMessage}
                    onChange={handleInput}
                    onKeyDown={handleKeyDown}
                    placeholder={
                      replyingTo
                        ? `Reply to ${replyingTo.sender}...`
                        : "What's up ?"
                    }
                  />
                  {showMentionSuggestions && mentionSuggestions}
                </div>

                {currentChat.startsWith("match-") && isCaptain && (
                  <button
                    id={styles.voteButton}
                    onClick={() => setIsVotingOpen(!isVotingOpen)}
                  >
                    Vote
                  </button>
                )}
                <button id={styles.sendButton} onClick={sendMessage}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <ModalProfile
          isOpen={selectedProfileModal.isOpen}
          onRequestClose={() =>
            setSelectedProfileModal({ isOpen: false, userId: null, eth: null })
          }
          type="user"
          id={selectedProfileModal.userId}
          eth={selectedProfileModal.eth}
        />
      </div>
    </div>
  );
};

export default ChatModal;
