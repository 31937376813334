import React, { useEffect, useState } from "react";
import "../styles/TournamentBracket.css";
import axiosInstance from "../axiosConfig";

const TournamentBracket = ({ tournamentId }) => {
  const [matches, setMatches] = useState([]);
  // const [teamDetails, setTeamDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/tournaments/${tournamentId}/bracket`
        );
        setMatches(response.data.matches);
        
        // Collect unique team IDs
        const teamIds = new Set();
        response.data.matches.forEach(match => {
          match.teams.forEach(team => {
            if (team?._id) {
              teamIds.add(team._id);
            }
          });
        });

        // Fetch team details for all teams
        const teamDetailsPromises = Array.from(teamIds).map(teamId =>
          axiosInstance.get(`/api/teams/${teamId}`)
        );

        const teamResponses = await Promise.all(teamDetailsPromises);
        const teamDetailsMap = {};
        teamResponses.forEach(response => {
          const team = response.data;
          teamDetailsMap[team._id] = team;
        });

        // setTeamDetails(teamDetailsMap);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch tournament data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchMatches();
  }, [tournamentId]);

  const isTeamWinner = (match, team) => {
    if (!match || !team) return false;
    if (!match?.winner) return false;
    return team._id && match.winner === team._id.toString();
  };

  const getMatchStatus = (match) => {
    if (!match || match.placeholder) return "pending";
    if (match.winner) return "completed";
    if (match.teams.some((team) => team !== null)) return "in_progress";
    return "pending";
  };

  const generateBracket = (matches) => {
    // Si pas de matches, créer un bracket vide pour 8 équipes (3 rounds)
    if (!matches || matches.length === 0) {
      const totalRounds = 3; // Pour 8 équipes
      const bracket = [];

      for (let roundNumber = 1; roundNumber <= totalRounds; roundNumber++) {
        const expectedMatches = Math.pow(2, totalRounds - roundNumber);
        const roundMatches = [];

        for (let i = 0; i < expectedMatches; i++) {
          roundMatches.push({
            _id: `placeholder-${roundNumber}-${i}`,
            teams: [null, null],
            roundNumber,
            placeholder: true,
          });
        }

        bracket.push({
          roundNumber,
          matches: roundMatches,
        });
      }

      return bracket;
    }

    // Le reste de votre fonction generateBracket existante...
    const round1Matches = matches.filter((m) => m.roundNumber === 1);
    const totalTeams = round1Matches.reduce(
      (count, match) =>
        count + match.teams.filter((team) => team !== null).length,
      0
    );

    const totalRounds = Math.ceil(Math.log2(totalTeams));
    const bracket = [];

    // Set pour tracker les équipes déjà placées dans chaque round
    const teamsInRound = new Set();

    for (let roundNumber = 1; roundNumber <= totalRounds; roundNumber++) {
      teamsInRound.clear(); // Réinitialiser pour chaque round
      let roundMatches = matches.filter(
        (match) => match.roundNumber === roundNumber
      );

      if (roundNumber === 1) {
        roundMatches = round1Matches;
      } else {
        const previousRoundMatches = matches.filter(
          (m) => m.roundNumber === roundNumber - 1
        );
        const newRoundMatches = [];

        for (let i = 0; i < previousRoundMatches.length; i += 2) {
          const winner1 = previousRoundMatches[i]?.winner;
          const winner2 = previousRoundMatches[i + 1]?.winner;

          // Trouver le match qui contient un des gagnants et qui n'a pas déjà été utilisé
          let matchForThisPair = roundMatches.find((match) => {
            const containsWinner = match.teams.some((team) => {
              if (!team) return false;
              const teamId = team._id.toString();
              if (teamsInRound.has(teamId)) return false;
              if (teamId === winner1 || teamId === winner2) {
                teamsInRound.add(teamId);
                return true;
              }
              return false;
            });
            return containsWinner;
          });

          // Si pas de match trouvé, créer un placeholder
          if (!matchForThisPair) {
            matchForThisPair = {
              _id: `placeholder-${roundNumber}-${i / 2}`,
              teams: [null, null],
              roundNumber,
              placeholder: true,
            };
          }

          newRoundMatches.push(matchForThisPair);
        }

        roundMatches = newRoundMatches;
      }

      const expectedMatches = Math.pow(2, totalRounds - roundNumber);

      while (roundMatches.length < expectedMatches) {
        roundMatches.push({
          _id: `placeholder-${roundNumber}-${roundMatches.length}`,
          teams: [null, null],
          roundNumber,
          placeholder: true,
        });
      }

      bracket.push({
        roundNumber,
        matches: roundMatches,
      });
    }

    if (bracket.length > 0) {
      const lastRound = bracket[bracket.length - 1];
      const previousRound = bracket[bracket.length - 2];

      if (lastRound && previousRound) {
        lastRound.matches = lastRound.matches.map((match) => {
          const teams = match.teams.filter((t) => t !== null);
          if (teams.length > 0) {
            // Créer un nouveau tableau teams avec les équipes correctement positionnées
            const newTeams = [null, null];

            teams.forEach((team) => {
              // Vérifier la position de chaque équipe dans le tour précédent
              const fromTopMatch = previousRound.matches[0].teams.some(
                (t) => t && t._id.toString() === team._id.toString()
              );
              // Placer chaque équipe dans le bon slot sans écraser l'autre
              newTeams[fromTopMatch ? 0 : 1] = team;
            });

            return {
              ...match,
              teams: newTeams,
            };
          }
          return match;
        });
      }
    }

    return bracket;
  };

  const getConnectorStyles = (
    roundIndex,
    matchIndex,
    totalRounds,
    position
  ) => {
    if (position === "top") {
      return {
        top: `30%`,
        height: `50%`,
      };
    } else {
      return {
        bottom: `15%`,
        height: `65%`,
      };
    }
  };

  const renderTeam = (team, match, index) => {
    if (!team) return <div className="team tbd">TBD</div>;

    const isWinner = isTeamWinner(match, team);
    const status = getMatchStatus(match);

    if (team === null) {
      return (
        <div className={`team tbd ${status}`}>
          <span className="team-name">TBD</span>
        </div>
      );
    }

    // const fullTeamDetails = teamDetails[team._id] || team;

    return (
      <div
        className={`team ${isWinner ? "winner" : ""} ${status}`}
        title={team.name}
      >
                {/* {fullTeamDetails?.logoUrl && (
          <img
            src={fullTeamDetails.logoUrl}
            alt={`${team.name} logo`}
            className="w-10 h-10 rounded-md flex-shrink-0"
            onError={(e) => {
              console.error('Error loading image:', e);
              e.target.style.display = 'none';
            }}
          />
        )} */}
        <span className="team-name truncate flex-1 m">{team.name}</span>

      </div>
    );
  };

  const renderConnectors = (roundIndex, matchIndex, match, bracket) => {
    if (roundIndex >= bracket.length - 1) return null;

    // Détermine le véritable vainqueur
    const hasWinner = match?.winner;
    const winnerTeam = hasWinner
      ? match.teams.find((team) => team?._id?.toString() === match.winner)
      : null;
    const winnerIndex = winnerTeam
      ? match.teams.findIndex((team) => team?._id?.toString() === match.winner)
      : -1;

    if (roundIndex === 0) {
      const isTopMatch = matchIndex % 2 === 0;

      return (
        <>
          <div
            className="connector-container connector-top"
            style={getConnectorStyles(
              roundIndex,
              matchIndex,
              bracket.length,
              "top"
            )}
          >
            <div
              className={`connector-line connector-horizontal ${
                winnerIndex === 1 ? "winner" : ""
              }`}
            ></div>
            <div
              className={`connector-line connector-vertical ${
                winnerIndex === 0
                  ? "bg-gradient-to-b from-[#3c8a40] from-50% to-[#333] to-50%"
                  : winnerIndex === 1
                  ? "bg-gradient-to-b from-[#333] from-50% to-[#3c8a40] to-50%"
                  : "bg-[#333]"
              }`}
              style={{
                width: "2px",
                height: "4rem",
              }}
            ></div>
          </div>
          <div
            className="connector-container connector-bottom"
            style={getConnectorStyles(
              roundIndex,
              matchIndex,
              bracket.length,
              "bottom"
            )}
          >
            <div
              className={`connector-line connector-horizontal ${
                winnerIndex === 0 ? "winner" : ""
              }`}
            ></div>
            <div
              className={`connector-line connector-vertical ${
                winnerIndex === 0
                  ? "bg-gradient-to-b from-[#3c8a40] from-50% to-[#333] to-50%"
                  : winnerIndex === 1
                  ? "bg-gradient-to-b from-[#333] from-50% to-[#3c8a40] to-50%"
                  : "bg-[#333]"
              }`}
              style={{
                width: "2px",
                height: "4.9rem",
              }}
            ></div>
          </div>

          {isTopMatch && (
            <>
              <div
                className={`connector-line ${
                  winnerIndex === 1 ? "winner" : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  height: "3rem",
                  width: "2px",
                  top: "50%",
                }}
              />
              <div
                className={`connector-line ${
                  winnerIndex === 1 ? "winner" : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  width: "7rem",
                  height: "2px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </>
          )}

          {!isTopMatch && (
            <>
              <div
                className={`connector-line ${
                  (matchIndex === 1 && winnerIndex === 0) ||
                  (matchIndex === 3 && winnerIndex === 1)
                    ? "winner"
                    : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  height: "3rem",
                  width: "2px",
                  top: "14%",
                }}
              />
              <div
                className={`connector-line ${
                  (matchIndex === 1 && winnerIndex === 0) ||
                  (matchIndex === 3 && winnerIndex === 1)
                    ? "winner"
                    : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  width: "7rem",
                  height: "2px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </>
          )}
        </>
      );
    }

    if (roundIndex === 1) {
      const isTopMatch = matchIndex % 2 === 0;
      return (
        <>
          <div
            className="connector-container connector-top"
            style={getConnectorStyles(
              roundIndex,
              matchIndex,
              bracket.length,
              "top"
            )}
          >
            <div
              className={`connector-line connector-horizontal ${
                winnerIndex === 1 ? "winner" : ""
              }`}
            ></div>
            <div
              className={`connector-line connector-vertical ${
                winnerIndex === 0
                  ? "bg-gradient-to-b from-[#3c8a40] from-50% to-[#333] to-50%"
                  : winnerIndex === 1
                  ? "bg-gradient-to-b from-[#333] from-50% to-[#3c8a40] to-50%"
                  : "bg-[#333]"
              }`}
              style={{
                width: "2px",
                height: "4rem",
              }}
            ></div>
          </div>
          <div
            className="connector-container connector-bottom"
            style={getConnectorStyles(
              roundIndex,
              matchIndex,
              bracket.length,
              "bottom"
            )}
          >
            <div
              className={`connector-line connector-horizontal ${
                winnerIndex === 0 ? "winner" : ""
              }`}
            ></div>
            <div
              className={`connector-line connector-vertical ${
                winnerIndex === 0
                  ? "bg-gradient-to-b from-[#3c8a40] from-50% to-[#333] to-50%"
                  : winnerIndex === 1
                  ? "bg-gradient-to-b from-[#333] from-50% to-[#3c8a40] to-50%"
                  : "bg-[#333]"
              }`}
              style={{
                width: "2px",
                height: "5rem",
              }}
            ></div>
          </div>

          {isTopMatch && (
            <>
              <div
                className={`connector-line ${
                  winnerIndex === 0 || winnerIndex === 1 ? "winner" : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  height: "6rem",
                  width: "2px",
                  top: "50%",
                }}
              />
              <div
                className={`connector-line ${
                  winnerIndex === 0 || winnerIndex === 1 ? "winner" : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  width: "7rem",
                  height: "2px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </>
          )}

          {!isTopMatch && (
            <>
              <div
                className={`connector-line ${
                  winnerIndex === 0 || winnerIndex === 1 ? "winner" : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  height: "6rem",
                  width: "2px",
                  top: "-23%",
                }}
              />
              <div
                className={`connector-line ${
                  winnerIndex === 0 || winnerIndex === 1 ? "winner" : ""
                }`}
                style={{
                  position: "absolute",
                  right: "-12rem",
                  width: "7rem",
                  height: "2px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </>
          )}
        </>
      );
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const bracket = generateBracket(matches);

  return (
    <div className="tournament-bracket">
    {bracket.map((round, roundIndex) => (
      <div className="flex flex-col" key={round.roundNumber}>
        <div className="round">
          {round.matches.map((match, matchIndex) => (
            <div
              key={`${match._id}-${matchIndex}`}
              className={`match match-${getMatchStatus(match)}`}
            >
              <div className="team-container">
                {match.teams.map((team, teamIndex) => (
                  <React.Fragment key={teamIndex}>
                    {renderTeam(team, match, teamIndex)}
                    {teamIndex === 0 && <div className="vs">vs</div>}
                  </React.Fragment>
                ))}
              </div>
              {renderConnectors(roundIndex, matchIndex, match, bracket)}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
  );
};

export default TournamentBracket;
