import React, { useState, useEffect, useCallback, useMemo } from "react";
import MatchCard from "./MatchCard";
import SelectedBets from "./SelectedBets";
import ActiveBets from "./ActiveBets";
import GameTabs from "./GameTabs";
import styles from "../../styles/Bet.module.css";
import { Contract, parseUnits, MaxUint256, getBigInt } from "ethers";
import contractABI from "../ABI/Betting";
import tokenContractABI from "../ABI/Rekt";
import axiosInstance from "../../axiosConfig";
import { io } from "socket.io-client";
import { useWallet } from "../../context/WalletContext";
import { useNotification } from "../Notifications/Notifications";
import { normalizeGameName } from "../../utils/gameUtils";
import { getTotalStaked } from "../Staking/Stakepool";
import Particles from "../../utils/particlesUtils";
import SEOManager from "../SEO/SEOManager";

const useActiveBetsFilter = (activeBets, allMatches, winner) => {
  const [filteredBets, setFilteredBets] = useState([]);
  const { account } = useWallet("");

  useEffect(() => {
    const checkAndUpdateBets = async () => {
      if (!activeBets) {
        setFilteredBets([]);
        return;
      }

      // Stocker les paris à désactiver
      const betsToDeactivate = [];

      const filtered = activeBets.filter((activeBet) => {
        // Trouver le match correspondant
        const match = allMatches.find(
          (m) => m.pandascoreId?.toString() === activeBet.betId?.toString()
        );

        // Si pas de match trouvé, retourner false
        if (!match) {
          return false;
        }

        // Vérifier le retrait manuel
        if (!activeBet.isActive) {
          return false;
        }

        // Vérifier si le bettingContractAddress a été réutilisé
        const hasNewerMatch = allMatches.some(
          (otherMatch) =>
            otherMatch.bettingContractAddress ===
              match.bettingContractAddress &&
            new Date(otherMatch.date) > new Date(match.date) &&
            otherMatch.pandascoreId?.toString() !==
              match.pandascoreId?.toString()
        );

        if (hasNewerMatch && activeBet.isActive) {
          betsToDeactivate.push(activeBet);
          return false;
        }

        // Pour les matchs terminés
        if (match.status === "finished") {
          const winningTeam = match.winner;

          if (winningTeam) {
            const userWon = winningTeam.name === activeBet.team;
            return userWon;
          }

          return false;
        }

        // Pour les matchs non terminés, on garde le pari
        return true;
      });

      // Si on a des paris à désactiver, on les traite en une seule requête
      if (betsToDeactivate.length > 0 && window.ethereum?.selectedAddress) {
        try {
          await axiosInstance.put(`/api/users/${account}/bets`, {
            bets: betsToDeactivate.map((bet) => ({
              betId: bet.betId,
              title: bet.title,
              amount: bet.amount,
              game: bet.game,
              team: bet.team,
              odd: bet.odd,
              isActive: false,
              manuallyWithdrawn: false,
              betElo: bet.betElo || 0,
            })),
          });
        } catch (error) {
          console.error("Erreur lors de la désactivation des paris:", error);
        }
      }

      setFilteredBets(filtered);
    };

    checkAndUpdateBets();
  }, [activeBets, allMatches, account]); // La dépendance à winner a été retirée car elle n'est pas utilisée

  return filteredBets;
};

// Custom hook pour gérer les matchs filtrés
const useFilteredMatches = (allMatches, activeTab) => {
  return useMemo(() => {
    return allMatches
      .filter((match) => {
        if (activeTab === "all") return true;
        const normalizedGameSlug = normalizeGameName(match.game);
        return normalizedGameSlug === activeTab;
      })
      .filter(
        (match) =>
          match.isActive ||
          [
            "not_started",
            "running",
            "finished",
            "canceled",
            "postponed",
            "paused",
            "interrupted",
            "rescheduled",
            "deleted",
          ].includes(match.status)
      );
  }, [allMatches, activeTab]);
};

// Adresse et ABI de ton contrat Betting.sol
const contractAddress = process.env.REACT_APP_BETTING_CONTRACT_ADDRESS;
const tokenContractAddress = process.env.REACT_APP_REKT_CONTRACT_ADDRESS;
const POLL_INTERVAL = 120000;

function Bet() {
  // État pour gérer les paris sélectionnés et actifs
  const [activeTab, setActiveTab] = useState("all");
  const [selectedBets, setSelectedBets] = useState([]);
  const [activeBets, setActiveBets] = useState([]);
  const [activeMatches, setActiveMatches] = useState([]);
  const [betAmount, setBetAmount] = useState("");
  const [matches, setMatches] = useState([]);
  const [allMatches, setAllMatches] = useState([]);
  const { account, wallet } = useWallet("");
  const [isPaused, setIsPaused] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [winner, setWinner] = useState(new Map());
  const [socket, setSocket] = useState(null);
  const [totalStaked, setTotalStaked] = useState("0");
  const [betAmountError, setBetAmountError] = useState("");
  const { notify } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [maxBetAmounts, setMaxBetAmounts] = useState({});

  const loadTotalStaked = useCallback(async () => {
    if (!wallet) return;
    try {
      const total = await getTotalStaked(wallet);
      setTotalStaked(total);
    } catch (error) {
      console.error("Erreur lors du chargement du total staké:", error);
    }
  }, [wallet]);

  useEffect(() => {
    loadTotalStaked();
    const intervalId = setInterval(() => {
      loadTotalStaked();
    }, 15000); // Rafraîchir toutes les 15 secondes

    return () => clearInterval(intervalId);
  }, [loadTotalStaked]);

  useEffect(() => {
    // Initialiser Socket.IO avec gestion des erreurs et reconnexion
    const newSocket = io(process.env.REACT_APP_API_URL, {
      reconnectionDelayMax: 10000,
      reconnection: true,
      reconnectionAttempts: 10,
      timeout: 20000,
      withCredentials: true,
      transports: ["websocket", "polling"],
      path: "/socket.io",
      forceNew: true,
    });

    newSocket.on("connect", () => {});

    newSocket.on("connect_error", (error) => {});

    newSocket.on("disconnect", (reason) => {});

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    const handleMatchUpdate = (updatedMatch) => {
      setActiveMatches((prev) =>
        prev.map((match) => {
          if (
            match.id === updatedMatch.id ||
            match.pandascoreId === updatedMatch.id
          ) {
            return {
              ...match,
              status: updatedMatch.status,
              team1: {
                ...match.team1,
                score: updatedMatch.team1.score || match.team1.score || "0",
                id: updatedMatch.team1.id,
              },
              team2: {
                ...match.team2,
                score: updatedMatch.team2.score || match.team2.score || "0",
                id: updatedMatch.team2.id,
              },
              results: [
                { score: updatedMatch.team1.score || match.team1.score || "0" },
                { score: updatedMatch.team2.score || match.team2.score || "0" },
              ],
              games: updatedMatch.games || match.games,
            };
          }
          return match;
        })
      );
    };

    // Créer un Map des abonnements actuels pour éviter les doublons
    const currentSubscriptions = new Set();

    // S'abonner uniquement aux nouveaux matchs
    activeMatches.forEach((match) => {
      const matchId = match.id || match.pandascoreId;
      if (matchId && !currentSubscriptions.has(matchId)) {
        socket.emit("subscribe:match", matchId);
        currentSubscriptions.add(matchId);
      }
    });

    socket.on("match:update", handleMatchUpdate);

    // Nettoyage
    return () => {
      currentSubscriptions.forEach((matchId) => {
        socket.emit("unsubscribe:match", matchId);
      });
      socket.off("match:update");
    };
  }, [socket, activeMatches]);

  useEffect(() => {
    if (!socket) return;

    const handleOddsUpdate = (data) => {
      setMatches((prevMatches) =>
        prevMatches.map((match) => {
          if (match.pandascoreId === data.matchId) {
            return {
              ...match,
              odds: data.odds,
            };
          }
          return match;
        })
      );
    };

    socket.on("odds:update", handleOddsUpdate);

    // Nettoyage
    return () => {
      socket.off("odds:update", handleOddsUpdate);
    };
  }, [socket, activeMatches]);

  // Fonction de conversion
  const convertWeiToDecimal = (weiValue) => {
    return (parseFloat(weiValue) / 1e18).toFixed(2);
  };

  const getContract = useCallback(
    async (specificAddress = null) => {
      const addressToUse = specificAddress || contractAddress;

      if (!addressToUse) {
        console.error("Adresse du contrat non définie !");
        return null;
      }

      if (wallet) {
        try {
          // Utiliser directement le wallet qui est déjà connecté au provider
          return new Contract(addressToUse, contractABI, wallet);
        } catch (error) {
          console.error("Erreur lors de la création du contrat:", error);
          return null;
        }
      } else {
        console.error("Wallet non disponible");
        return null;
      }
    },
    [wallet]
  );

  const getMaxBetAmount = useCallback(
    async (contractAddress) => {
      try {
        const contract = await getContract(contractAddress);
        if (contract) {
          const maxBet = await contract.maxBetAmount();
          return maxBet;
        }
      } catch (error) {
        console.error("Error getting max bet amount:", error);
        return 0;
      }
    },
    [getContract]
  );

  // Fonction pour récupérer les cotes d'équipe A et B à partir du contrat
  const getOddsFromContract = useCallback(
    async (betContractAddress = null) => {
      if (!betContractAddress) {
        console.error("Adresse du contrat du pari non définie!");
        return [0, 0];
      }

      const contract = new Contract(betContractAddress, contractABI, wallet);
      try {
        const oddsTeamA = await contract.oddsTeamA();
        const oddsTeamB = await contract.oddsTeamB();
        return [oddsTeamA, oddsTeamB];
      } catch (error) {
        console.error("Erreur lors de la récupération des cotes :", error);
        return [0, 0];
      }
    },
    [wallet]
  );

  useEffect(() => {
    // Charger les matchs actifs
    const fetchAllMatches = async () => {
      try {
        const response = await axiosInstance.get("/api/bets/all-matches");
        setAllMatches(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des matchs actifs:",
          error
        );
      }
    };

    fetchAllMatches();
  }, []);

  useEffect(() => {
    // Charger les matchs actifs
    const fetchActiveMatches = async () => {
      try {
        const response = await axiosInstance.get("/api/bets/active");
        setMatches(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des matchs actifs:",
          error
        );
      }
    };

    fetchActiveMatches();
  }, []);

  // Fonction pour charger les paris actifs de l'utilisateur
  const loadActiveBets = useCallback(async () => {
    if (!account) return;
    try {
      await axiosInstance.post(`/api/users/${account}/update-bets`);
      const response = await axiosInstance.get(`/api/users/${account}/active`);
      setActiveBets(response.data);
    } catch (error) {
      console.error("Erreur lors du chargement des paris actifs :", error);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      loadActiveBets();
    }
  }, [loadActiveBets, account]);

  const loadActiveMatches = useCallback(async () => {
    if (!isInitialized) return;

    try {
      const response = await axiosInstance.get("/api/bets/synchronized", {
        params: {
          _t: Date.now(), // Cache buster si nécessaire
        },
      });

      const synchronizedMatches = response.data;

      const maxBets = {};
      await Promise.all(
        synchronizedMatches.map(async (match) => {
          if (match.bettingContractAddress) {
            const maxBet = await getMaxBetAmount(match.bettingContractAddress);
            maxBets[match.bettingContractAddress] = maxBet;
          }
        })
      );
      setMaxBetAmounts(maxBets);

      // Mise à jour du state avec les nouvelles données
      setActiveMatches(synchronizedMatches);

      // Récupérer les cotes smart contract pour les matchs actifs
      const betsWithOdds = await Promise.all(
        synchronizedMatches.map(async (match) => {
          if (match.isActive && match.bettingContractAddress) {
            try {
              const odds = await getOddsFromContract(
                match.bettingContractAddress
              );
              return {
                ...match,
                odds: {
                  team1: convertWeiToDecimal(odds[0].toString()),
                  team2: convertWeiToDecimal(odds[1].toString()),
                },
              };
            } catch (error) {
              console.error(`Error getting odds for match ${match.id}:`, error);
              // En cas d'erreur, garder les cotes existantes
              return match;
            }
          }
          return match;
        })
      );

      setMatches(betsWithOdds);
    } catch (error) {
      console.error("Error loading active matches:", error);
    }
  }, [isInitialized, getOddsFromContract, getMaxBetAmount]);

  const getBetPlaceholder = () => {
    if (selectedBets.length > 0) {
      const selectedMatch = matches.find(
        (match) =>
          match.pandascoreId.toString() === selectedBets[0].matchId.toString()
      );
      if (selectedMatch?.bettingContractAddress) {
        const maxBet = convertWeiToDecimal(
          maxBetAmounts[selectedMatch.bettingContractAddress]?.toString() || "0"
        );
        return `Max: ${maxBet} REKT`;
      }
    }
    return "Enter bet amount";
  };

  const ODDS_UPDATE_INTERVAL = 15000;

  // UseEffect pour le polling
  useEffect(() => {
    // Premier chargement
    loadActiveMatches();

    const oddsIntervalId = setInterval(() => {
      loadActiveMatches();
    }, ODDS_UPDATE_INTERVAL);

    // Mettre en place le polling moins fréquent pour les autres données
    const matchesIntervalId = setInterval(() => {
      loadActiveMatches();
    }, POLL_INTERVAL);

    // Cleanup
    return () => {
      clearInterval(oddsIntervalId);
      clearInterval(matchesIntervalId);
    };
  }, [loadActiveMatches]);

  // Fonction pour gérer le retrait des paris
  const handleWithdrawBet = async (betId, team, amount, match) => {
    if (!match.bettingContractAddress) {
      notify("Erreur: no contract was found", "error");
      return;
    }

    const contract = await getContract(match.bettingContractAddress);

    if (!contract) {
      notify("Erreur: Impossible to connect", "error");
      return;
    }

    if (!match.isActive && match.status !== "finished") {
      notify("Not active.", "info");
      return;
    }

    try {
      const amountInWei = parseUnits(amount.toString(), 18);
      const isEnded = await contract.matchEnded();
      let tx;

      if (isEnded && match.status !== "finished" && match.status !== "canceled") {
        console.error("Incohérence d'état détectée");
        notify("Erreur.", "error");
        return;
      }

      let isWinning = false;
      let isEarlyWithdrawal = false;
      let shouldContinue = true;

      if (isEnded && (match.status === "finished" || match.status === "canceled")) {
        const result = await contract.matchResult();
        isWinning =
          (result.toString() === "1" && team === match.team1?.name) ||
          (result.toString() === "2" && team === match.team2?.name);
        tx = await contract.withdrawAfterMatch();
      } else {
        // Create and append modal
        const modal = document.createElement("div");
        modal.innerHTML = `
          <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div class="bg-[#1D1F24] rounded-lg max-w-md w-full p-6 shadow-xl border border-white/10">
              <h2 class="text-xl font-bold text-red-600 mb-4">
                ⚠️ Attention - Early Withdrawal
              </h2>
              <div class="space-y-3">
                <p class="text-white">
                  You are about to make a withdrawal before the end of the match.
                </p>
                <p class="text-red-500 font-semibold">
                  A 80%+ penalty will be applied to your stake !
                </p>
              </div>
              <div class="flex gap-3 justify-between mt-6">
                <button id="cancelWithdraw" class="px-4 py-2 bg-gray-800 hover:bg-gray-700 text-white rounded-md transition-colors">
                  Cancel
                </button>
                <button id="confirmWithdraw" class="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        `;
        document.body.appendChild(modal);
        document.body.style.overflow = "hidden";

        // Wait for user response
        await new Promise((resolve) => {
          modal.querySelector("#confirmWithdraw").onclick = () => {
            isEarlyWithdrawal = true;
            shouldContinue = true;
            document.body.style.overflow = "unset";
            modal.remove();
            resolve();
          };

          modal.querySelector("#cancelWithdraw").onclick = () => {
            shouldContinue = false;
            document.body.style.overflow = "unset";
            modal.remove();
            resolve();
          };
        });

        if (!shouldContinue) {
          return;
        }

        // Process the early withdrawal
        if (team === match.team1.name) {
          tx = await contract.withdrawBetOnTeamA(amountInWei);
        } else if (team === match.team2.name) {
          tx = await contract.withdrawBetOnTeamB(amountInWei);
        }
      }

      // Wait for the transaction
      await tx.wait();

      // Update the bet status in the database
      const response = await axiosInstance.post(
        `/api/users/${account}/withdraw-bet`,
        {
          betId,
          team,
          amount,
          isWinning,
          isEarlyWithdrawal,
        }
      );

      if (!response.data) {
        throw new Error("Failed to update bet status");
      }

      if (match.status === "finished") {
        notify(`Success !`, "success");
      } else {
        notify(`Success !`, "success");
      }

      await loadActiveBets();
      await checkWithdrawalsPaused();
    } catch (error) {
      console.error("Erreur lors du retrait du pari", error);
      notify("Try again.", "error");
    }
  };

  const handleSelectBet = useCallback(
    (matchId, title, team, odd, game, status, isActive) => {
      setSelectedBets((prevBets) => {
        // Si on clique sur le même pari et la même équipe, on désélectionne
        if (
          prevBets.length === 1 &&
          prevBets[0].matchId === matchId &&
          prevBets[0].team === team
        ) {
          return [];
        }

        // Pour tout autre cas (équipe adverse ou nouveau match), on sélectionne le nouveau pari
        return [
          {
            matchId,
            title,
            team,
            odd,
            game: game.replace("-2", "2"),
            status,
            isActive,
          },
        ];
      });
    },
    []
  );

  async function getTokenContract() {
    if (!tokenContractAddress || !wallet) {
      console.error(
        "Adresse du contrat de token non définie ou wallet non disponible!"
      );
      return null;
    }
    return new Contract(tokenContractAddress, tokenContractABI, wallet);
  }

  const checkAllowance = async (
    tokenContract,
    spenderAddress,
    amount,
    userAddress
  ) => {
    try {
      const allowance = await tokenContract.allowance(
        userAddress,
        spenderAddress
      );
      const allowanceBigInt = getBigInt(allowance);
      const amountBigInt = getBigInt(amount);

      if (allowanceBigInt < amountBigInt) {
        console.log("Demande d'approbation nécessaire");
        const approvalTx = await tokenContract.approve(
          spenderAddress,
          MaxUint256
        );
        await approvalTx.wait();
        return true;
      }

      console.log("Approval existant suffisant");
      return true;
    } catch (error) {
      console.error("Erreur lors de l'approbation:", error);
      return false;
    }
  };

  // Fonction pour placer un pari
  const handlePlaceBet = async () => {
    setIsLoading(true);
    if (selectedBets.length > 0 && betAmount) {
      const maxBet = parseFloat(totalStaked) * 0.0001;

      if (parseFloat(betAmount) > maxBet) {
        setBetAmountError(`Max ${maxBet.toFixed(2)}`);
        notify(
          `Bet amount exceeds maximum allowed (${maxBet.toFixed(2)})`,
          "error"
        );
        return;
      }

      try {
        const tokenContract = await getTokenContract();
        const amountInUnits = parseUnits(betAmount, 18);

        // Récupérer les informations du match sélectionné
        const selectedBet = selectedBets[0];
        const matchInfo = filteredMatches.find(
          (match) =>
            match.pandascoreId.toString() === selectedBet.matchId.toString()
        );

        if (!matchInfo || !matchInfo.bettingContractAddress) {
          notify("Erreur: No contract.", "error");
          return;
        }

        // Utiliser le contrat spécifique au match
        const contract = await getContract(matchInfo.bettingContractAddress);
        if (!contract) {
          notify("Erreur: Impossible to connect.", "error");
          return;
        }

        const approved = await checkAllowance(
          tokenContract,
          matchInfo.bettingContractAddress,
          amountInUnits,
          account
        );

        if (approved) {
          // Continuer avec le pari
        }

        let tx;
        const teams = selectedBets.map((bet) => bet.team);

        // Récupérer les matchs du jeu concerné
        // const gameToFetch = normalizeGameName(selectedBet.game);
        const pandascoreResponse = await axiosInstance.get(`/api/bets/active`);

        let flattenedMatches2 = [];
        if (
          typeof pandascoreResponse.data === "object" &&
          !Array.isArray(pandascoreResponse.data)
        ) {
          flattenedMatches2 = Object.values(pandascoreResponse.data)
            .map((tournament) =>
              (tournament.matches || []).map((match) => ({
                ...match,
                tournamentName: tournament.name,
              }))
            )
            .flat();
        } else if (Array.isArray(pandascoreResponse.data)) {
          flattenedMatches2 = pandascoreResponse.data;
        }

        const availableMatches = flattenedMatches2.filter(
          (match) => match.status !== "running"
        );

        if (!Array.isArray(availableMatches)) {
          console.error(
            "availableMatches n'est pas un tableau ou est undefined"
          );
          return;
        }

        // Vérifie si tous les paris concernent la même équipe et si le match est disponible pour les paris
        if (teams.every((team) => team === selectedBets[0].team)) {
          const matchToBet = availableMatches.find((match) => {
            return (
              match.pandascoreId.toString() === selectedBet.matchId.toString()
            );
          });

          if (matchToBet) {
            console.log(
              matchToBet,
              teams[0],
              matchToBet.team1.name,
              teams,
              matchToBet.team2.name
            );
            // Continuer avec la logique de placement de pari seulement si le match est trouvé
            if (teams[0] === matchToBet.team1.name) {
              // Parier sur l'équipe A
              tx = await contract.placeBetOnTeamA(amountInUnits);
            } else {
              // Parier sur l'équipe B
              tx = await contract.placeBetOnTeamB(amountInUnits);
            }

            await tx.wait();
          } else {
            console.error(
              "Le match sélectionné n'est pas disponible pour les paris."
            );
          }

          const updatedBets = selectedBets.map((bet) => {
            const existingBet = activeBets.find((activeBet) => {
              return (
                activeBet.betId === bet.matchId.toString() &&
                activeBet.team === bet.team
              );
            });

            if (existingBet) {
              // Si le pari existe, on met à jour le montant
              return {
                ...existingBet,
                amount: parseFloat(betAmount),
              };
            } else {
              // Si c'est un nouveau pari
              return {
                betId: bet.matchId,
                title: bet.title,
                amount: betAmount,
                game: bet.game.replace("-2", "2"),
                team: bet.team,
                odd: bet.odd,
                isActive: bet.isActive,
              };
            }
          });

          await axiosInstance.put(`/api/users/${account}/bets`, {
            bets: updatedBets,
          });

          notify("Bet successfully placed !", "success");
          setActiveBets([...activeBets, ...selectedBets]);
          setSelectedBets([]);
          setBetAmount("");
          loadActiveBets();
          setIsLoading(false);
        } else {
          notify("All on the same team.", "warning");
          return;
        }
      } catch (error) {
        console.error("Erreur lors du placement du pari", "error" + error);
      }
    } else {
      notify("Select bet before.", "warning");
    }
  };

  const handleBetAmountChange = (e) => {
    const amount = e.target.value;
    setBetAmount(amount);

    if (amount) {
      const maxBet = parseFloat(totalStaked) * 0.0001;
      if (parseFloat(amount) > maxBet) {
        setBetAmountError(`Max ${maxBet.toFixed(2)}`);
      } else {
        setBetAmountError("");
      }
    } else {
      setBetAmountError("");
    }
  };

  const filteredMatches = useFilteredMatches(matches, activeTab);
  const activeBetsFiltered = useActiveBetsFilter(
    activeBets,
    allMatches,
    winner
  );

  // Ajouter un effet pour charger les résultats des matchs
  useEffect(() => {
    const loadMatchResults = async () => {
      const newWinner = new Map();

      // Filtrer les matchs terminés
      const finishedMatches = matches.filter(
        (match) => match.status === "finished"
      );

      for (const match of finishedMatches) {
        try {
          const contract = await getContract(match.bettingContractAddress);
          if (contract) {
            const result = await contract.matchResult();
            newWinner.set(match.pandascoreId.toString(), result);
          }
        } catch (error) {
          console.error(
            `Erreur lors du chargement du résultat pour le match ${match.pandascoreId}:`,
            error
          );
        }
      }

      setWinner(newWinner);
    };

    if (matches.length > 0) {
      loadMatchResults();
    }
  }, [matches, getContract]);

  // Fonction pour vérifier si les retraits sont en pause
  const checkWithdrawalsPaused = useCallback(async () => {
    const newPausedStates = {};
    const localBetsResponse = await axiosInstance.get("/api/bets");
    const activeContracts = localBetsResponse.data
      .filter((bet) => bet.isActive)
      .map((bet) => bet.bettingContractAddress);

    for (const contractAddress of activeContracts) {
      try {
        const contract = await getContract(contractAddress);
        if (contract) {
          const isPaused = await contract.paused();
          newPausedStates[contractAddress] = isPaused;
        }
      } catch (error) {
        console.error(`Erreur pour le contrat ${contractAddress}:`, error);
      }
    }
    setIsPaused(newPausedStates);
  }, [getContract]);

  useEffect(() => {
    const initializeApp = async () => {
      await checkWithdrawalsPaused();
      setIsInitialized(true);
    };
    initializeApp();

    const intervalId = setInterval(() => {
      checkWithdrawalsPaused();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [checkWithdrawalsPaused]);

  return (
    <>
      {" "}
      <SEOManager
        title={`REKT Esport - Betting Platform`}
        description={`Place bets on your favorite esport matches and tournaments on REKT. Predict outcomes and win rewards.`}
        ogTitle={`REKT Esport | Betting`}
        ogDescription="Join REKT's esport betting platform. Bet on matches, tournaments and earn rewards with your predictions."
      />
      <div>
        <div className="rondBizarreOrangeQuiClignote" />
        <Particles />
        <div id="root">
          <div className="bg-[#1D1F24] min-h-screen text-white overflow-x-hidden z-5">
            <section className="min-h-screen relative">
              {isInitialized ? (
                <div>
                  <div className={styles.app}>
                    <main className={styles.container}>
                      <div className={styles.mainContent}>
                        <section className={styles.matchesSection}>
                          <GameTabs
                            activeTab={activeTab}
                            onTabClick={setActiveTab}
                          />
                          <div id="matches-container">
                            <div className={styles.matchCardsContainer}>
                              {filteredMatches && filteredMatches.length > 0 ? (
                                filteredMatches
                                  .sort((a, b) => {
                                    // Ordre de priorité des statuts
                                    const statusOrder = {
                                      running: 0,
                                      not_started: 1,
                                      paused: 2,
                                      interrupted: 3,
                                      postponed: 4,
                                      rescheduled: 5,
                                      finished: 6,
                                      canceled: 7,
                                    };

                                    // Comparer par statut
                                    const statusCompare =
                                      (statusOrder[a?.status] ?? 999) -
                                      (statusOrder[b?.status] ?? 999);

                                    // Si les statuts sont identiques, trier par date
                                    if (statusCompare === 0) {
                                      return (
                                        new Date(a?.begin_at || 0) -
                                        new Date(b?.begin_at || 0)
                                      );
                                    }

                                    return statusCompare;
                                  })
                                  .map((match) => {
                                    if (!match || !match.id) return null;

                                    const matchWithOdds = matches.find(
                                      (m) =>
                                        m.pandascoreId === match.pandascoreId
                                    );
                                    const matchOdds = matchWithOdds?.odds || {
                                      team1: "1.93",
                                      team2: "1.93",
                                    };

                                    return (
                                      <div
                                        key={match.id || match._id}
                                        className="relative"
                                      >
                                        <MatchCard
                                          match={{
                                            id: match.pandascoreId || match.id,
                                            title: match.name || "",
                                            league: match.leagueName,
                                            tournamentName:
                                              match.tournamentName,
                                            time: match.relative_time || "",
                                            begin_at:
                                              match.begin_at || match.date,
                                            team1: {
                                              name: match.team1?.name || "TBD",
                                              logo: match.team1?.logo || "",
                                              score: match.team1?.score || "0",
                                              results:
                                                match.team1?.score || "0",
                                            },
                                            team2: {
                                              name: match.team2?.name || "TBD",
                                              logo: match.team2?.logo || "",
                                              score: match.team2?.score || "0",
                                              results:
                                                match.team2?.score || "0",
                                            },
                                            results: [
                                              {
                                                score:
                                                  match.team1?.score || "0",
                                              },
                                              {
                                                score:
                                                  match.team2?.score || "0",
                                              },
                                            ],
                                            stream:
                                              match.streams_list?.map(
                                                (stream) => stream.embed_url
                                              ) || [],
                                            status: match.status || "unknown",
                                            odds: matchOdds,
                                            game: normalizeGameName(
                                              match.videogame?.slug ||
                                                match.game ||
                                                ""
                                            ),
                                            number_of_games:
                                              match.number_of_games,
                                          }}
                                          onSelectBet={handleSelectBet}
                                          selectedTeam={
                                            selectedBets.find(
                                              (bet) => bet.matchId === match.id
                                            )?.team
                                          }
                                          betExists={activeBets.some(
                                            (bet) =>
                                              bet.betId ===
                                                (match.pandascoreId ||
                                                  match.id) && bet.isActive
                                          )}
                                          pausedContracts={
                                            isPaused?.[
                                              match.bettingContractAddress
                                            ]
                                          }
                                          filteredMatches={filteredMatches}
                                        />
                                      </div>
                                    );
                                  })
                              ) : (
                                <div className="text-center p-4">
                                  <p>No match running</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </section>
                        <section className={`${styles.betslipSection}`}>
                          <div id={styles.betslip}>
                            <SelectedBets selectedBets={selectedBets} />
                            <div className={styles.betInput}>
                              <div className="mr-2">
                                <input
                                  type="number"
                                  id="bet-amount"
                                  value={betAmount}
                                  onChange={handleBetAmountChange}
                                  placeholder={getBetPlaceholder()}
                                />
                                {betAmountError && (
                                  <div className="text-red-500 text-sm mt-1 font-bold flex">
                                    {betAmountError}
                                  </div>
                                )}
                              </div>
                              {isLoading ? (
                                <button
                                  className={`${styles.disabledButton}`}
                                  disabled={!!betAmountError}
                                >
                                  Betting...
                                </button>
                              ) : (
                                <button
                                  className={`${
                                    !!betAmountError || !betAmount
                                      ? styles.disabledButton
                                      : styles.placeBet
                                  }`}
                                  onClick={handlePlaceBet}
                                  disabled={!!betAmountError}
                                >
                                  Bet
                                </button>
                              )}
                            </div>
                          </div>
                          <div id={styles.myBets}>
                            <div>
                              <h3>
                                {activeBetsFiltered.length} selection
                                {activeBetsFiltered.length > 1 ? "s" : ""}
                              </h3>
                              <hr className="border-t border-white/60 my-3 w-full" />
                            </div>
                            <div className="min-h-[400px]">
                              {activeBetsFiltered.length > 0 && (
                                <div>
                                  {allMatches.map((match) => (
                                    <ActiveBets
                                      key={match.pandascoreId || match.id}
                                      match={match}
                                      activeBets={activeBetsFiltered}
                                      onWithdraw={handleWithdrawBet}
                                      pausedContracts={isPaused}
                                      isLoading={isLoading}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.betInput}></div>
                          <div></div>
                        </section>
                      </div>
                    </main>
                  </div>
                </div>
              ) : (
                // Remplacer le div de loading par :
                <div className={`${styles.app}`}>
                  <main className={styles.container}>
                    <div className={styles.mainContent}>
                      <section className={styles.matchesSection}>
                        <div className="opacity-50">
                          <GameTabs
                            activeTab={activeTab}
                            onTabClick={setActiveTab}
                          />
                        </div>
                        <div id="matches-container">
                          <div className={styles.matchCardsContainer}>
                            {[...Array(5)].map((_, index) => (
                              <MatchCard
                                key={`skeleton-${index}`}
                                loading={true}
                              />
                            ))}
                          </div>
                        </div>
                      </section>
                      <section className={`${styles.betslipSection}`}>
                        <div className="opacity-50">
                          <div id={styles.betslip}>
                            <div className="h-48 bg-gray-800/50 rounded-lg animate-pulse"></div>
                          </div>
                          <div id={styles.myBets}>
                            <div className="h-96 bg-gray-800/50 rounded-lg animate-pulse mt-4"></div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </main>
                </div>
              )}
            </section>
            <footer className={styles.footer}>
              <p>
                &copy; 2024 Rekt eSport. All rights reserved. Play responsibly.
                18+
              </p>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bet;
