// ModalProfile.js
import React, { useEffect } from "react";
import Profile from "./Profile";
import Team from "./Team";
import { createPortal } from "react-dom";

const ModalProfile = ({ isOpen, onRequestClose, type, id, userId, eth }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };

  const modalContent = 
    type === "user" ? (
    <div
      className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-[9999] p-2 sm:p-4"
      onClick={handleOverlayClick}
      style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <div className="bg-[#ffffff1a] rounded-2xl w-full max-w-6xl max-h-[80%] relative z-[10000] overflow-y-auto">
        <Profile type={type} id={id} userId={userId} />
      </div>
    </div>
  ) : (
    <div
    className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-[9999] p-2 sm:p-4"
    onClick={handleOverlayClick}
    style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
  >
    <div className="bg-[#ffffff1a] rounded-[20px] w-full max-w-6xl max-h-[80%] relative z-[10000] overflow-y-auto">
      <Team type={type} id={id} userId={userId} eth={eth} />
    </div>
  </div>
  )

  return createPortal(modalContent, document.body);
};

export default ModalProfile;
