import React, { useState } from 'react';

const TutorialSection = ({ title, videoUrl, steps }) => (
  <div className="mb-12">
    <h2 className="text-2xl font-bold text-[#F4B000] mb-6">{title}</h2>
    <div className="bg-[#1D1F24] rounded-xl overflow-hidden mb-6">
      {videoUrl ? (
        <iframe 
        title={`${title} tutorial video`}
        className="w-full aspect-video"
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      ) : (
        <div className="w-full aspect-video bg-black/40 flex items-center justify-center">
          <p className="text-white/50">Video tutorial coming soon</p>
        </div>
      )}
    </div>
    <div className="space-y-4">
      {steps.map((step, index) => (
        <div 
          key={index}
          className="flex gap-4 p-4 bg-[#1D1F24] rounded-xl border border-white/5"
        >
          <div className="w-8 h-8 flex-shrink-0 bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] rounded-lg flex items-center justify-center font-bold text-white">
            {index + 1}
          </div>
          <p className="text-white/90 my-auto">{step}</p>
        </div>
      ))}
    </div>
  </div>
);

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-white/10 last:border-none">
      <button
        className="w-full py-4 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-white">{question}</span>
        <span className={`text-2xl text-[#F4B000] transition-transform ${isOpen ? 'rotate-45' : ''}`}>+</span>
      </button>
      {isOpen && (
        <div className="pb-4 text-white/70">
          {answer}
        </div>
      )}
    </div>
  );
};

const MetaMaskTutorial = () => {
  const desktopSteps = [
    "Go to the Chrome Web Store and search for the MetaMask extension",
    "Click 'Add to Chrome' to install the extension",
    "Once installed, click the puzzle icon in the toolbar to pin MetaMask",
    "Open MetaMask and follow the steps to create your wallet"
  ];

  const bscTestnetSteps = [
    "Open MetaMask and click on the network dropdown at the top",
    "Click 'Add Network' and then 'Add a network manually'",
    "Enter the following details for BSC Testnet:",
    "Network Name: BSC Testnet",
    "New RPC URL: https://data-seed-prebsc-1-s1.binance.org:8545/",
    "Chain ID: 97",
    "Currency Symbol: BNB",
    "Block Explorer URL: https://testnet.bscscan.com"
  ];

  const connectionSteps = [
    "Click the 'Connect Wallet' button on our site",
    "Select MetaMask from the connection options",
    "Confirm the connection in the MetaMask popup",
    "Your wallet is now connected!"
  ];

  const faqItems = [
    {
      question: "What is MetaMask?",
      answer: "MetaMask is a digital wallet that allows you to store and manage your cryptocurrencies. It's also a gateway to Web3 applications like ours."
    },
    {
      question: "Is it secure?",
      answer: "MetaMask is one of the most secure and popular wallets. Your private keys are encrypted and stored locally. Always make sure to back up your recovery phrase and never share it."
    },
    {
      question: "Can't connect, what should I do?",
      answer: "Make sure MetaMask is installed and unlocked. If the issue persists, try refreshing the page or restarting your browser. You can also try disconnecting and reconnecting to MetaMask."
    },
    {
      question: "Can I use another wallet?",
      answer: "Currently, we only support MetaMask to ensure the best possible experience for our users."
    },
    {
      question: "How do I protect my wallet?",
      answer: "Never share your secret phrase, use a strong password, enable two-factor authentication if available, and be cautious of malicious websites and phishing attempts."
    }
  ];

  const handleSupportClick = () => {
    window.location.href = 'https://discord.gg/TEExEGc7xy';
  };

  return (
    <div className="min-h-screen bg-[#15171A] text-white py-16 px-4">
      <div className="max-w-3xl mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="w-20 h-20 mx-auto mb-6 bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] rounded-2xl flex items-center justify-center">
            <svg className="w-12 h-12" viewBox="0 0 24 24" fill="none">
              <path d="M21.17 3L13.06 8.94L14.58 4.85L21.17 3Z" fill="white" />
              <path d="M2.83 3L10.86 9L9.42 4.85L2.83 3Z" fill="white" />
              <path d="M18.11 16.58L15.83 20.17L20.51 21.5L21.86 16.66L18.11 16.58Z" fill="white" />
              <path d="M2.14 16.66L3.49 21.5L8.17 20.17L5.89 16.58L2.14 16.66Z" fill="white" />
            </svg>
          </div>
          <h1 className="text-4xl font-bold mb-4">How to use MetaMask</h1>
          <p className="text-white/70 text-xl">
            A complete guide to install and connect your MetaMask wallet
          </p>
        </div>

        {/* Tutorial Sections */}
        <TutorialSection
          title="Desktop Installation"
          videoUrl="https://www.youtube.com/embed/c4BLlhBYl2Q"
          steps={desktopSteps}
        />

        <TutorialSection
          title="Adding BSC Testnet"
          videoUrl="https://www.youtube.com/embed/lF2xW4OfFdk"
          steps={bscTestnetSteps}
        />

        <TutorialSection
          title="How to Connect"
          videoUrl="https://www.youtube.com/embed/fqm-1YZSNLA"
          steps={connectionSteps}
        />

        {/* FAQ Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-[#F4B000] mb-6">Frequently Asked Questions</h2>
          <div className="bg-[#1D1F24] rounded-xl p-6">
            {faqItems.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        </div>

        {/* Help Section */}
        <div className="text-center bg-[#1D1F24] rounded-xl p-8">
          <h2 className="text-xl font-bold mb-4">Need more help?</h2>
          <p className="text-white/70 mb-6">
            Our support team is here to help you with any issues
          </p>
          <button onClick={handleSupportClick} className="bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] px-8 py-3 rounded-xl font-semibold hover:opacity-90 transition-opacity">
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default MetaMaskTutorial;