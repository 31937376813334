import { useState, useEffect, useCallback } from "react";
import { stakeTokens, getWalletBalance } from "./Stakepool";
import { useWallet } from "../../context/WalletContext";

import rektLogo from "../../REKT_Logo.webp";

import styles from "../../styles/Staking.module.css";
import { useNotification } from "../Notifications/Notifications";

export const Stake = () => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState("0");
  const { account, wallet } = useWallet();
  const { notify } = useNotification();

  const loadWalletBalance = useCallback(async () => {
    if (!account) return;

    try {
      const walletBalance = await getWalletBalance(wallet);
      setBalance(Number(walletBalance).toFixed(18));
    } catch (error) {
      console.error("Error loading wallet balance:", error);
    }
  }, [account, wallet]);

  useEffect(() => {
    loadWalletBalance();
    // Rafraîchir le solde toutes les 30 secondes
    const interval = setInterval(loadWalletBalance, 30000);
    return () => clearInterval(interval);
  }, [account, loadWalletBalance]);

  const handleStake = async () => {
    if (!account) {
      console.error(
        "Account not available, please connect wallet."
      );
      return;
    }

    const parsedAmount = parseFloat(amount);

    // Vérifie que le montant est valide
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      console.error("Invalid amount for staking:", amount);
      return;
    }

    if (parsedAmount > parseFloat(balance)) {
      return;
    }

    setLoading(true); // Démarre le chargement

    try {
      await stakeTokens(wallet, Number(amount).toFixed(18));
      notify("Tokens staked successfully!");
      setAmount("");
      loadWalletBalance();
    } catch (error) {
      console.error("Staking error", error);
    } finally {
      setLoading(false); // Arrête le chargement
    }
  };

  const handleSetMax = () => {
    const maxAmount = Number(balance).toFixed(18);
    setAmount(Number(maxAmount));
  };

  return (
    <div className="pt-2">
      <h2>Stake Tokens</h2>
      <div className={styles.balanceInfo}>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
           Balance: {Number(balance - (amount || 0)).toFixed(2)}
          <img
            src={rektLogo}
            alt="REKT Logo"
            style={{ width: "15px", height: "auto", marginLeft: "5px" }}
          />
        </p>
      </div>
      <div className={styles.stake}>
        <div className={styles.inputRow}>
          <input
            type="number"
            value={amount === "" ? "" : parseFloat(amount)}
            onChange={(e) => {
              const value = e.target.value;

              // Permettre à l'utilisateur de supprimer et vider l'input
              if (value === "") {
                setAmount("");
                return;
              }

              const numericValue = parseFloat(value);

              // Si la valeur numérique est valide et ne dépasse pas le stakedBalance
              if (!isNaN(numericValue) && numericValue <= balance) {
                setAmount(numericValue);
              }
            }}
            onKeyDown={(e) => {
              // Empêche l'ajout de chiffres si la valeur est déjà au maximum
              if (
                parseFloat(e.target.value) >= balance &&
                !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(
                  e.key
                )
              ) {
                e.preventDefault();
              }
            }}
            placeholder="Amount"
            max={balance}
            id={styles.input}
          />
          <button
            onClick={handleSetMax}
            className={styles.maxButton}
          >
            MAX
          </button>
        </div>
        <button onClick={handleStake} disabled={loading} id={styles.button}>
          {loading ? "Staking..." : "Stake"}
        </button>
      </div>
    </div>
  );
};
