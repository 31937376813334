import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";
import { useWallet } from "../../context/WalletContext";
import { toast } from "react-toastify";
import { useNotification } from "../Notifications/Notifications";

const Profile = ({ type, id, userId }) => {
  const [profile, setProfile] = useState(null);
  const { account } = useWallet();
  const [userTeam, setUserTeam] = useState(null);
  const [isInviting, setIsInviting] = useState(false);
  const [detailedBets, setDetailedBets] = useState([]);
  const [rankings, setRankings] = useState({
    betRank: "N/A",
    tournamentRank: "N/A",
    totalPlayers: 0,
  });
  const [activeView, setActiveView] = useState("profile");
  const [friendshipStatus, setFriendshipStatus] = useState(null);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const { notify } = useNotification();

  useEffect(() => {
    const fetchUserTeam = async () => {
      if (!account) return;
      try {
        const { data } = await axiosInstance.get(`/api/users/eth/${account}`);
        if (data.team) {
          const teamResponse = await axiosInstance.get(
            `/api/teams/${data.team}`
          );
          setUserTeam(teamResponse.data);
        }
      } catch (error) {
        console.error("Error fetching user team:", error);
      }
    };
    fetchUserTeam();
  }, [account]);

  useEffect(() => {
    const checkFriendshipStatus = async () => {
      if (!account || !profile?.eth) return;

      try {
        // Utiliser l'adresse ETH au lieu de l'ID
        const response = await axiosInstance.get(
          `/api/users/${account}/friends/status/${profile.eth}`
        );
        setFriendshipStatus(response.data.status);
      } catch (error) {
        console.error("Error checking friendship status:", error);
      }
    };

    if (profile) {
      checkFriendshipStatus();
    }
  }, [account, profile]);

  // Correction de la fonction d'envoi de demande d'ami
  const handleFriendRequest = async () => {
    if (!account || !profile?.eth) return;

    setIsSendingRequest(true);
    try {
      await axiosInstance.post(`/api/users/${account}/friends/request`, {
        friendEth: profile.eth, // Utiliser l'adresse ETH
      });

      setFriendshipStatus("pending");
      toast.success("Friend request sent!");
    } catch (error) {
      console.error("Error sending friend request:", error);
      toast.error(
        error.response?.data?.message || "Failed to send friend request"
      );
    } finally {
      setIsSendingRequest(false);
    }
  };
  
  // Mettre à jour handleRemoveFriend
  const handleRemoveFriend = async () => {
    if (!account || !profile?.eth) return;
  
    try {
      const response = await axiosInstance.post(`/api/users/${account}/friends/remove`, {
        friendEth: profile.eth
      });
  
      if (response.data.success) {
        setFriendshipStatus(null);
        toast.success("Friend removed successfully");
      }
    } catch (error) {
      console.error("Error removing friend:", error);
      toast.error(error.response?.data?.message || "Failed to remove friend");
    }
  };

  // Ajoutez cette fonction pour gérer l'invitation
  const handleInvite = async () => {
    if (!account || !userTeam || !profile?.eth) return;

    setIsInviting(true);
    try {
      await axiosInstance.post("/api/invitations/invite", {
        from: account,
        to: profile.eth,
        team: userTeam._id,
        role: "member",
      });
      notify("Invitation sent successfully!");
    } catch (error) {
      console.error("Error sending invitation:", error);
      notify(error.response?.data?.error || "Error sending invitation");
    } finally {
      setIsInviting(false);
    }
  };

  const getRequiredXPForNextLevel = (level) => {
    const baseXP = 100;
    const multiplier = 1.5;
    return Math.floor(baseXP * Math.pow(multiplier, level - 1));
  };

  const currentLevel = profile?.experience?.level || 1;
  const currentXP = profile?.experience?.currentXP || 0;
  const requiredXP = getRequiredXPForNextLevel(currentLevel);
  const progressPercentage = (currentXP / requiredXP) * 100;

  const Stats = () => {
    const lcm = (a, b) => Math.abs(a * b) / gcd(a, b);
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const round = (num) => Math.round(num * 100) / 100;

    const calculateTournamentWinRate = (tournaments) => {
      if (!tournaments || tournaments.length === 0) return 0;

      const winningTournaments = tournaments.filter((tournament) => {
        // On vérifie si le tournamentElo est positif, ce qui indique une position dans le top 3
        return tournament.tournamentElo > 0;
      });

      return (winningTournaments.length / tournaments.length) * 100;
    };

    const [tooltip, setTooltip] = React.useState({
      visible: false,
      x: 0,
      y: 0,
      value: null,
      label: "",
    });

    const data = [
      {
        name: "BS",
        base: 2,
        value: Math.max(0, calculateBettingScore(profile?.bets) || 0),
      },
      {
        name: "VS",
        base: 2,
        value: Math.max(0, calculateTournamentScore(profile?.bets) || 0),
      },
      {
        name: "WR/B",
        base: 100,
        value: Math.max(
          0,
          statusTest === 0 ? 0 : (winnerTest / statusTest) * 100
        ),
      },
      {
        name: "WR/V",
        base: 100,
        value: calculateTournamentWinRate(profile?.tournaments),
      },
      {
        name: "LVL",
        base: 100,
        value: Math.max(0, profile?.experience?.level),
      },
    ];

    const base = data.map((item) => item.base).reduce(lcm);
    const normalizer = Object.fromEntries(
      data.map((item) => [item.name, base / item.base])
    );

    const normalize = (label, n) => {
      const normalized = round(((n * normalizer[label]) / base) * 100);
      // Ajuster l'échelle pour commencer à 20%
      return 20 + Math.min(Math.max(normalized, 0), 100) * 0.8;
    };

    const normalizedData = data.map((item, i) => ({
      ...item,
      normalizedValue: normalize(item.name, item.value),
      angle: (Math.PI * 2 * i) / data.length,
    }));

    const getPoint = (angle, value) => {
      const radius = value * 0.85; // Réduire légèrement le rayon pour mieux centrer
      return {
        x: Math.cos(angle - Math.PI / 2) * radius,
        y: Math.sin(angle - Math.PI / 2) * radius,
      };
    };

    const createPolygonPath = (data) => {
      const points = data.map((item) => {
        const point = getPoint(item.angle, item.normalizedValue);
        return `${point.x},${point.y}`;
      });
      return `M ${points.join(" L ")} Z`;
    };

    const handleMouseOver = (e, item) => {
      const x = e.clientX;
      const y = e.clientY;

      setTooltip({
        visible: true,
        x,
        y,
        value: item.value,
        label: item.name,
      });
    };

    const handleMouseOut = () => {
      setTooltip({ ...tooltip, visible: false });
    };

    return (
      <div className="space-y-6">
        <div className="relative w-full aspect-square max-w-[310px] mx-auto flex items-center justify-center bg-black/20 rounded-lg p-2 mt-6">
          <svg viewBox="-100 -109 200 200" className="w-full h-full">
            {/* Grille de fond avec un rayon légèrement réduit */}
            {[20, 40, 60, 80, 100].map((level) => (
              <polygon
                key={`grid-${level}`}
                points={Array.from({ length: data.length }, (_, i) => {
                  const angle = (Math.PI * 2 * i) / data.length;
                  const point = getPoint(angle, level);
                  return `${point.x},${point.y}`;
                }).join(" ")}
                fill="none"
                stroke="rgba(255,255,255,0.1)"
                strokeWidth="0.5"
              />
            ))}

            {/* Lignes des axes */}
            {data.map((_, i) => {
              const angle = (Math.PI * 2 * i) / data.length;
              const point = getPoint(angle, 100);
              return (
                <line
                  key={`axis-${i}`}
                  x1="0"
                  y1="0"
                  x2={point.x}
                  y2={point.y}
                  stroke="rgba(255,255,255,0.1)"
                  strokeWidth="0.5"
                />
              );
            })}

            {/* Forme des données */}
            <path
              d={createPolygonPath(normalizedData)}
              fill="rgba(136, 132, 216, 0.4)"
              stroke="rgb(136, 132, 216)"
              strokeWidth="1"
            />

            {/* Points interactifs */}
            {normalizedData.map((item, i) => {
              const point = getPoint(item.angle, item.normalizedValue);
              return (
                <circle
                  key={`point-${i}`}
                  cx={point.x}
                  cy={point.y}
                  r="3"
                  fill="rgb(136, 132, 216)"
                  className="cursor-pointer hover:r-4 transition-all duration-200"
                  onMouseOver={(e) => handleMouseOver(e, item)}
                  onMouseOut={handleMouseOut}
                />
              );
            })}

            {/* Labels avec position ajustée */}
            {normalizedData.map((item, i) => {
              const point = getPoint(item.angle, 108);
              return (
                <text
                  key={`label-${i}`}
                  x={point.x}
                  y={point.y}
                  textAnchor="middle"
                  fill="rgba(255,255,255,0.7)"
                  fontSize="12"
                  dominantBaseline="middle"
                  className="select-none"
                >
                  {item.name}
                </text>
              );
            })}
          </svg>

          {/* Tooltip avec position fixe */}
          {tooltip.visible && (
            <div
              className="fixed bg-black/80 px-2 py-1 rounded text-white text-xs pointer-events-none z-50"
              style={{
                left: `${tooltip.x}px`,
                top: `${tooltip.y - 30}px`,
              }}
            >
              <div className="font-medium">{tooltip.label}</div>
              <div className="text-[#8884d8]">{round(tooltip.value)}</div>
            </div>
          )}
        </div>

        {/* Nouvelle barre de progression de niveau */}
        <div className="space-y-2">
          <div className="flex justify-between items-center text-sm text-white/70">
            <div>Level {currentLevel}</div>
            <div>
              {currentXP} / {requiredXP} XP
            </div>
          </div>
          <div className="h-2 w-full bg-white/10 rounded-full overflow-hidden">
            <div
              className="h-full bg-gradient-to-r from-[#bfc8d4] to-[#2563EB] transition-all duration-300"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
          {/* Total XP */}
          <div className="text-center text-sm text-white/50">
            Total XP: {profile?.experience?.totalXP || 0}
          </div>

          <>
            {profile.eth !== account &&
              !profile.team &&
              !userTeam?.members?.some(
                (m) => m.user === userId && m.role === "captain"
              ) && (
                <div className="mt-4">
                  <button
                    onClick={handleInvite}
                    disabled={isInviting}
                    className={`
          w-full py-2 px-4 rounded-lg
          bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]
          text-white font-medium
          transition-all duration-200
          hover:opacity-90
          ${isInviting ? "opacity-50 cursor-not-allowed" : ""}
      `}
                  >
                    {isInviting ? "Sending invitation..." : "Invite to Team"}
                  </button>
                </div>
              )}
          </>
        </div>
      </div>
    );
  };

  const calculateBettingScore = (bets) => {
    if (!bets || bets.length === 0) return 1.0;

    // Prendre les 50 derniers paris
    const recentBets = bets.slice(-50);

    let totalEloGained = 0;
    let totalRektLost = 0;

    recentBets.forEach((bet) => {
      if (bet.betElo > 0) {
        totalEloGained += bet.betElo - bet.amount;
      } else {
        totalRektLost += bet.amount || 0;
      }
    });

    const averageEloGained = totalEloGained / recentBets.length;
    const averageRektLost = totalRektLost / recentBets.length;

    // Le vrai ratio sans plafond
    return averageRektLost === 0
      ? 1.0
      : +(averageEloGained / averageRektLost).toFixed(2);
  };

  const calculateTournamentScore = (tournaments) => {
    if (!tournaments || tournaments.length === 0) return 1.0;

    // Prendre les 10 derniers tournois
    const recentTournaments = tournaments.slice(-10);

    let totalEloGained = 0;
    let totalRektLost = 0;

    recentTournaments.forEach((tournament) => {
      if (tournament.tournamentElo > 0) {
        totalEloGained += tournament.tournamentElo - tournament.entryFee;
      } else {
        // Pour les tournois sans gain d'Elo, on considère l'entry fee comme perte
        // Si pas d'entry fee dans les données, on prend une valeur par défaut de 100
        totalRektLost += tournament.entryFee || 0;
      }
    });

    const averageEloGained = totalEloGained / recentTournaments.length;
    const averageRektLost = totalRektLost / recentTournaments.length;

    return averageRektLost === 0
      ? 1.0
      : +(averageEloGained / averageRektLost).toFixed(2);
  };

  const calculateTimeAgo = (timestamp) => {
    if (!timestamp) return "Invalid date";

    try {
      // Convertir le format ISO avec timezone en objet Date
      const date = new Date(timestamp.replace("+00:00", "Z"));

      if (isNaN(date.getTime())) {
        console.error("Invalid date from timestamp:", timestamp);
        return "Invalid date";
      }

      const now = new Date();
      const seconds = Math.floor((now - date) / 1000);

      const intervals = [
        { label: "year", seconds: 31536000 },
        { label: "month", seconds: 2592000 },
        { label: "day", seconds: 86400 },
        { label: "hour", seconds: 3600 },
        { label: "minute", seconds: 60 },
        { label: "second", seconds: 1 },
      ];

      for (const interval of intervals) {
        const count = Math.floor(seconds / interval.seconds);
        if (count >= 1) {
          return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
        }
      }
      return "Just now";
    } catch (error) {
      console.error(
        "Error calculating time ago:",
        error,
        "timestamp:",
        timestamp
      );
      return "Date invalide";
    }
  };

  useEffect(() => {
    const fetchBetsDetails = async () => {
      if (!profile?.bets) return;

      try {
        // Récupérer seulement les pandascoreIds des paris de l'utilisateur
        const pandascoreIds = profile.bets
          .map((bet) => bet.betId)
          .filter((id) => id); // Filtrer les ids null/undefined

        if (pandascoreIds.length === 0) return;

        // Récupérer les détails des paris
        const response = await axiosInstance.get("/api/bets/finished", {
          params: {
            betIds: pandascoreIds.join(","),
          },
        });

        // Combiner les données des paris de l'utilisateur avec les détails des matchs
        const betsWithDetails = profile.bets.map((userBet) => {
          const matchDetails = response.data.find(
            (match) => match.pandascoreId === userBet.betId
          );

          const timeAgo = userBet.createdAt.includes("ago")
            ? userBet.createdAt
            : calculateTimeAgo(userBet.createdAt);

          return {
            ...matchDetails,
            amount: userBet.amount,
            chosenTeam: userBet.team,
            odd: userBet.odd,
            createdAt: timeAgo,
          };
        });

        setDetailedBets(betsWithDetails);
      } catch (error) {
        console.error("Error fetching bets details:", error);
      }
    };

    if (profile?.bets) {
      fetchBetsDetails();
    }
  }, [profile]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axiosInstance.get(`/api/profile/${type}/${id}`);
        // Si l'utilisateur a une équipe, récupérer le nom de l'équipe
        if (data.team) {
          try {
            const teamResponse = await axiosInstance.get(
              `/api/teams/${data.team}`
            );
            data.teamName = teamResponse.data.name;
          } catch (error) {
            console.error("Error fetching team name:", error);
          }
        }
        setProfile(data);

        if (data?.eth) {
          fetchRankings(data.eth);
          if (data.bets) {
            fetchBetsDetails(data.bets);
          }
        }
      } catch (error) {
        console.error("Error fetching profile", error);
      }
    };
    fetchProfile();
  }, [type, id]);

  const fetchRankings = async (eth) => {
    try {
      const response = await axiosInstance.get("/api/leaderboards/rankings");
      const { bettingRanking, tournamentRanking, totalPlayers } = response.data;

      const betPlayer = bettingRanking.find((p) => p.eth === eth);
      const tournamentPlayer = tournamentRanking.find((p) => p.eth === eth);

      setRankings({
        betRank: betPlayer?.rank || "N/A",
        tournamentRank: tournamentPlayer?.rank || "N/A",
        totalPlayers,
        betElo: betPlayer?.betElo || 0,
        tournamentElo: tournamentPlayer?.tournamentElo || 0,
      });
    } catch (error) {
      console.error("Error fetching rankings:", error);
    }
  };

  const fetchBetsDetails = async (bets) => {
    try {
      const pandascoreIds = bets.map((bet) => bet.betId).filter((id) => id);

      if (pandascoreIds.length === 0) return;

      const response = await axiosInstance.get("/api/bets/finished", {
        params: { betIds: pandascoreIds.join(",") },
      });

      const betsWithDetails = bets.map((userBet) => {
        const matchDetails = response.data.find(
          (match) => match.pandascoreId === userBet.betId
        );
        return {
          ...matchDetails,
          amount: userBet.amount,
          chosenTeam: userBet.team,
          odd: userBet.odd,
        };
      });

      setDetailedBets(betsWithDetails);
    } catch (error) {
      console.error("Error fetching bets details:", error);
    }
  };

  const formatGameName = (game) => {
    const gameNames = {
      "dota-2": "Dota 2",
      "league-of-legends": "League of Legends",
      "cs-go": "CS:GO",
      valorant: "Valorant",
      rl: "Rocket League",
    };
    return gameNames[game] || game;
  };

  const getRecentBets = (bets, limit = 50) => {
    if (!bets || !Array.isArray(bets)) return [];

    return bets
      .filter((bet) => bet.status === "finished")
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, limit)
      .map((bet) => {
        const totalAmountWon = parseFloat(bet.amount) * parseFloat(bet.odd);
        const totalAmountLoss = parseFloat(bet.amount);
        const hasWon = bet.winner && bet.chosenTeam === bet.winner.name;

        return {
          title: `${bet.team1.name} vs ${bet.team2.name}`,
          game: formatGameName(bet.game),
          amountWon: totalAmountWon.toFixed(2),
          amountLoss: totalAmountLoss.toFixed(2),
          hasWon,
          timeAgo: bet.createdAt,
        };
      });
  };

  const getRecentTournaments = (tournaments, limit = 5) => {
    if (!tournaments || !Array.isArray(tournaments)) return [];

    return tournaments
      .sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
        return dateB - dateA;
      })
      .slice(0, limit)
      .map((tournament) => ({
        title: tournament.game,
        place: `Elo: ${tournament.tournamentElo.toFixed(1)}`,
        date: tournament.createdAt,
      }));
  };

  const statusTest = detailedBets?.filter(
    (bet) => bet?.status === "finished"
  ).length;

  const winnerTest = detailedBets?.filter(
    (bet) => bet?.winner?.name === bet?.chosenTeam
  ).length;

  if (!profile)
    return <div className="text-white text-center p-4">Loading...</div>;

  return (
    <div className="p-4 sm:p-6">
      <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6">
        {/* Profile Section */}
        <div className="md:col-span-1 space-y-4">
          {/* Profile Card */}
          <div className="bg-white/5 rounded-2xl p-4 sm:p-6 relative overflow-hidden">
            {/* Gradient Border Top */}
            <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />
            <div className="flex space-x-4 mb-0 justify-between">
              <button
                onClick={() => setActiveView("profile")}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeView === "profile"
                    ? "bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] text-white"
                    : "bg-white/5 text-white/70 hover:bg-white/10"
                }`}
              >
                Profile
              </button>
              <button
                onClick={() => setActiveView("stats")}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeView === "stats"
                    ? "bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] text-white"
                    : "bg-white/5 text-white/70 hover:bg-white/10"
                }`}
              >
                Stats
              </button>
            </div>

            {activeView === "profile" ? (
              <div>
                {/* Profile Image */}
                <div className="relative w-24 h-24 sm:w-32 sm:h-32 mx-auto mb-4 sm:mb-6">
                  {profile.imageUrl ? (
                    <img
                      src={profile.imageUrl}
                      alt="Profile"
                      className="w-full h-full rounded-full object-cover border-2 border-[#F4B000]/30"
                    />
                  ) : (
                    <div className="w-full h-full rounded-full bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] flex items-center justify-center text-2xl sm:text-3xl text-white border-2 border-[#F4B000]/30">
                      {profile.name
                        ? profile.name.substring(0, 2).toUpperCase()
                        : ""}
                    </div>
                  )}
                </div>

                {/* Profile Info */}
                <div className="text-center space-y-3">
                  <h3 className="text-xl font-bold text-white flex flex-col">
                    {profile.name} {/* Team Badge - Nouveau */}
                    {profile.team && (
                      <div className="px-4 rounded-full inline-block">
                        <div className="flex items-center justify-center space-x-2">
                          <p className="text-white/90 text-sm font-medium">
                            <span className="text-[#ffffff4b]">
                              {profile.teamName || "Loading..."}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </h3>

                  <div className="px-4 py-1.5 bg-white/5 rounded-full inline-block mb-0">
                    <p className="text-white/70 text-sm font-medium">
                      Level {profile.experience.level}
                    </p>
                  </div>
                  <div className="pt-0">
                    <p className="text-white/50 text-sm break-all font-mono bg-white/5 p-2 rounded-lg">
                      {profile.eth}
                    </p>
                  </div>
                </div>

                {/* Bio Section */}
                {profile.bio && (
                  <div className="mt-3 pt-6 border-t border-white/10">
                    <p className="text-white/70 text-sm leading-relaxed text-center italic">
                      "{profile.bio}"
                    </p>
                  </div>
                )}
                <>
                  {profile.eth !== account &&
                    !profile.team &&
                    !userTeam?.members?.some(
                      (m) => m.user === userId && m.role === "captain"
                    ) && (
                      <div className="mt-4">
                        <button
                          onClick={handleInvite}
                          disabled={isInviting}
                          className={`
                            w-full py-2 px-4 rounded-lg
                            bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]
                            text-white font-medium
                            transition-all duration-200
                            hover:opacity-90
                            ${isInviting ? "opacity-50 cursor-not-allowed" : ""}
                          `}
                        >
                          {isInviting
                            ? "Sending invitation..."
                            : "Invite to Team"}
                        </button>
                      </div>
                    )}

                  {/* Nouveau bouton d'ami */}
                  <div className="mt-2">
                    {friendshipStatus === null && profile.eth !== account && (
                      <button
                        onClick={handleFriendRequest}
                        disabled={isSendingRequest}
                        className={`
                          w-full py-2 px-4 rounded-lg
                          bg-gradient-to-r from-[#4F46E5] to-[#7C3AED]
                          text-white font-medium
                          transition-all duration-200
                          hover:opacity-90
                          ${
                            isSendingRequest
                              ? "opacity-50 cursor-not-allowed"
                              : ""
                          }
                        `}
                      >
                        {isSendingRequest ? "Sending Request..." : "Add Friend"}
                      </button>
                    )}

                    {friendshipStatus === "pending" && (
                      <button
                        disabled
                        className="w-full py-2 px-4 rounded-lg bg-gray-500 text-white font-medium opacity-50 cursor-not-allowed"
                      >
                        Friend Request Pending
                      </button>
                    )}

                    {friendshipStatus === "accepted" && (
                      <button
                        onClick={handleRemoveFriend}
                        className="w-full py-2 px-4 rounded-lg bg-red-500 text-white font-medium hover:bg-red-600 transition-colors"
                      >
                        Remove Friend
                      </button>
                    )}
                  </div>
                </>
                <div className="bg-white/5 rounded-2xl p-4 sm:p-4 relative overflow-hidden mt-[10px]">
                  <div className="space-y-6">
                    <div className="flex justify-between items-center">
                      <span className="text-white/70 text-sm">
                        Member Since
                      </span>
                      <span className="text-white font-medium">
                        {new Date(profile.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Stats />
            )}
          </div>
        </div>

        {/* Separator */}
        <div className="hidden md:block w-px bg-white/10 h-full" />

        {/* Stats & History Section */}
        <div className="md:col-span-2 space-y-4 sm:space-y-6">
          {/* Stats Grid */}
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
            {[
              {
                title: "Betting Score",
                value: calculateBettingScore(profile?.bets).toFixed(2) || "0",
              },
              {
                title: "Versus Score",
                value:
                  calculateTournamentScore(profile?.bets).toFixed(2) || "0",
              },
              { title: "Betting Rank", value: `#${rankings.betRank}` },
              { title: "Versus Rank", value: `#${rankings.tournamentRank}` },
            ].map((stat, i) => (
              <div
                key={i}
                className="bg-white/5 rounded-2xl p-3 sm:p-4 relative overflow-hidden flex flex-col"
              >
                <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />
                <div className="text-white/70 text-xs sm:text-sm mb-1 sm:mb-2">
                  {stat.title}
                </div>
                <div className="text-xl sm:text-2xl font-bold text-[#F4B000] mt-auto">
                  {stat.value}
                </div>
              </div>
            ))}
          </div>

          {/* Recent Activity Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
            {/* Recent Bets */}
            <div className="bg-white/5 rounded-2xl p-4 sm:p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4 text-white">
                  Recent Bets
                </h3>
                <h3 className="text-[#d6d4d4] text-lg font-semibold mb-3">
                  {detailedBets.length
                    ? `${(statusTest === 0
                        ? 0
                        : (winnerTest / statusTest) * 100
                      ).toFixed(1)}%`
                    : ""}
                </h3>
              </div>
              <div className="space-y-3 sm:space-y-4 max-h-[300px] sm:max-h-[350px] overflow-y-auto">
                {getRecentBets(detailedBets).length > 0 ? (
                  getRecentBets(detailedBets).map((bet, i) => (
                    <div key={i} className="p-3 sm:p-4 bg-white/5 rounded-lg">
                      <div className="flex flex-col min-h-[80px]">
                        <div className="text-white font-medium text-sm sm:text-base mb-2">
                          {bet.title}
                        </div>
                        <div className="text-white/70 text-xs">{bet.game}</div>
                        <div className="flex-grow flex flex-col justify-end">
                          <div className="flex justify-end">
                            <div className="text-white/70 text-xs flex-grow flex flex-col justify-end">
                              {bet.timeAgo}
                            </div>
                            <div
                              className={`text-sm sm:text-base ${
                                bet.hasWon ? "text-green-500" : "text-red-500"
                              }`}
                            >
                              {bet.hasWon
                                ? "+" + bet.amountWon
                                : "-" + bet.amountLoss}{" "}
                              REKT
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-white/50 text-center py-8">No bets</div>
                )}
              </div>
            </div>

            {/* Recent Tournaments */}
            <div className="bg-white/5 rounded-2xl p-4 sm:p-6">
              <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4 text-white">
                Recent Tournaments
              </h3>
              <div className="space-y-3 sm:space-y-4 max-h-[300px] sm:max-h-[450px] overflow-y-auto">
                {getRecentTournaments(profile.tournaments).length > 0 ? (
                  getRecentTournaments(profile.tournaments).map(
                    (tournament, i) => (
                      <div
                        key={i}
                        className="flex items-center justify-between p-3 sm:p-4 bg-white/5 rounded-lg"
                      >
                        <div>
                          <div className="text-white font-medium text-sm sm:text-base">
                            {tournament.title}
                          </div>
                          <div className="text-white/50 text-xs sm:text-sm">
                            {tournament.time}
                          </div>
                        </div>
                        <div className="text-[#F4B000] text-sm sm:text-base">
                          {tournament.place}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="text-white/50 text-center py-8">
                    No tournaments
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
