import { useState, useEffect, useCallback } from "react";
import {   getStakedBalance, 
    getRewardRate, 
    getRewardPeriodEnd, 
    getPendingRewards,
    getTotalStaked  } from "./Stakepool";
import { useWallet } from "../../context/WalletContext";

import styles from "../../styles/Staking.module.css";

export const StakingStats = () => {
    const [stats, setStats] = useState({
      userStaked: "0",
      totalStaked: "0",
      apr: "0",
      periodEnd: "0",
      pendingRewards: "0"
    });
    const [, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { account, wallet } = useWallet();
  
    const loadStats = useCallback(async () => {
        if (!account) return;
    
        setLoading(true);
        setError(null);
    
        try {    
          console.log("Loading staking stats...");
    
          // Chargement des données avec plus de logs
          try {
            const userStaked = await getStakedBalance(wallet);
            console.log("User staked loaded:", userStaked);
    
            const totalStaked = await getTotalStaked(wallet);
            console.log("Total staked loaded:", totalStaked);
    
            const apr = await getRewardRate(wallet);
            console.log("APR loaded:", (apr / 1e18));
    
            const periodEnd = await getRewardPeriodEnd(wallet);
            console.log("Period end loaded:", periodEnd);
    
            const rewards = await getPendingRewards(wallet);
            console.log("Rewards loaded:", rewards);
    
            const now = Math.floor(Date.now() / 1000);
            const timeRemaining = Math.max(0, parseInt(periodEnd) - now);
    
            setStats({
              userStaked: userStaked || "0",
              totalStaked: totalStaked || "0",
              apr: apr || "0",
              periodEnd: timeRemaining,
              pendingRewards: rewards || "0"
            });
          } catch (error) {
            console.error("Error during data loading:", error);
            throw error;
          }
        } catch (error) {
          console.error("Error in loadStats:", error);
          setError("Erreur lors du chargement des statistiques");
        } finally {
          setLoading(false);
        }
      }, [account, wallet]);
  
    useEffect(() => {
      loadStats();
      const interval = setInterval(loadStats, 30000);
      return () => clearInterval(interval);
    }, [account, loadStats]);
  
    const formatTimeRemaining = (seconds) => {
      if (seconds === 0) return "Période terminée";
      const days = Math.floor(seconds / (24 * 3600));
      const hours = Math.floor((seconds % (24 * 3600)) / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${days}j ${hours}h ${minutes}m`;
    };
  
    if (error) {
      return (
        <div className={styles.stakingStats}>
          <p className={styles.error}>{error}</p>
          <button onClick={loadStats} className={styles.retryButton}>
            Réessayer
          </button>
        </div>
      );
    }

  return (
    <div className={styles.stakingStats}>
      <div className={styles.statsGrid}>
        <div className={styles.statItem}>
          <h3>APR</h3>
          <p>{(parseFloat(stats.apr) / 1e18).toFixed(2)}%</p>
        </div>
        <div className={styles.statItem}>
          <h3>Staked</h3>
          <p>{parseFloat(stats.totalStaked).toFixed(2)}</p>
        </div>
        <div className={styles.statItem}>
          <h3>Rewards</h3>
          <p>{parseFloat(stats.pendingRewards).toFixed(4)}</p>
        </div>
        <div className={styles.statItem}>
          <h3>Time remaining</h3>
          <p>{formatTimeRemaining(stats.periodEnd)}</p>
        </div>
      </div>
    </div>
  );
};