// Dans gameUtils.js

const GAME_NAMES = {
  DOTA2: 'dota2',
  LOL: 'lol',
  CSGO: 'csgo',
  VALORANT: 'valorant',
  RL: 'rl',
  APEX: 'apex',
  BS: 'bs',
  CR: 'cr',
  COC: 'coc',
};

const GAME_MAPPINGS = {
  'dota-2': GAME_NAMES.DOTA2,
  'dota2': GAME_NAMES.DOTA2,
  'rocket-league': GAME_NAMES.RL,
  'rl': GAME_NAMES.RL,
  'csgo': GAME_NAMES.CSGO,
  'cs-go': GAME_NAMES.CSGO,
  'cs2': GAME_NAMES.CSGO,
  'cs-2': GAME_NAMES.CSGO,
  'counter-strike': GAME_NAMES.CSGO,
  'lol': GAME_NAMES.LOL,
  'league-of-legends': GAME_NAMES.LOL,
  'valorant': GAME_NAMES.VALORANT,
  'val': GAME_NAMES.VALORANT
};

const normalizeGameName = (game) => {
  if (!game || game === 'all') return game;
  
  const normalizedGame = game.toLowerCase().trim();
  return GAME_MAPPINGS[normalizedGame] || normalizedGame;
};

// Fonction pour vérifier si un jeu est valide
const isValidGame = (game) => {
  if (game === 'all') return true;
  const normalizedGame = normalizeGameName(game);
  return Object.values(GAME_NAMES).includes(normalizedGame);
};

module.exports = {
  GAME_NAMES,
  GAME_MAPPINGS,
  normalizeGameName,
  isValidGame
};