import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/ChatButton.module.css";

const ChatButton = ({ onClick, notificationCount }) => {
  return (
    <button id={styles.chatButton} onClick={onClick}>
      <FontAwesomeIcon 
        icon={faComment} 
        className='text-[#5a5a5a69] hover:text-[#F4B000]/10 h-[32px] w-[32px] align-middle'
      />
      {notificationCount > 0 && (
        <span className={styles.notificationBadge}>
          {notificationCount}
        </span>
      )}
    </button>
  );
};

export default ChatButton;