import React, { useState, useEffect, useCallback, useRef } from "react";
import { Loader2, Box, DollarSign, Tag } from "lucide-react";
import { useNotification } from "../Notifications/Notifications";
import axiosInstance from "../../axiosConfig";
import BoxNFT from "../ABI/boxNFT";
import Marketplace from "../ABI/Marketplace";
import { useWallet } from "../../context/WalletContext";
import { Contract, ZeroAddress, parseEther, Interface } from "ethers";

// Créer un composant Box séparé
const BoxCard = ({ box, onList, isApproving }) => {
  const [price, setPrice] = useState("");
  const [processing, setProcessing] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("BNB");

  const boxTypeColors = {
    BRONZE: "bg-[#CD7F32]",
    SILVER: "bg-[#C0C0C0]",
    GOLD: "bg-[#FFD700]",
    DIAMOND: "bg-[#B9F2FF]",
  };

  const handleBoxList = async () => {
    setProcessing(true);
    try {
      await onList(box.tokenId, price);
    } finally {
      setProcessing(false);
      setPrice(""); // Reset le prix après listing
    }
  };

  return (
    <div className="bg-[#252832] rounded-2xl p-6 hover:bg-[#2A2E38] transition-all duration-300">
      <div className="flex items-center mb-4 gap-3">
        <div className="bg-[#F4B000]/10 p-3 rounded-xl">
          <Tag className="w-6 h-6 text-[#F4B000]" />
        </div>
        <div>
          <h3 className="text-lg font-semibold">Box #{box.tokenId}</h3>
          <span
            className={`px-3 py-1 rounded-full text-white text-sm ${
              boxTypeColors[box.boxType]
            }`}
          >
            {box.boxType}
          </span>
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex gap-4">
          <input
            type="number"
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="flex-1 bg-[#1D1F24] p-3 rounded-xl border border-[#F4B000]/20 focus:border-[#F4B000] outline-none"
          />
          <select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            className="bg-[#1D1F24] p-3 rounded-xl border border-[#F4B000]/20 focus:border-[#F4B000] outline-none appearance-none"
          >
            <option value="BNB">BNB</option>
          </select>
        </div>
        <button
          className="w-full py-2 rounded-xl bg-[#F4B000] text-black hover:bg-[#F4B000]/90 font-semibold transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50"
          disabled={processing}
          onClick={handleBoxList}
        >
          {(processing || isApproving) && (
            <Loader2 className="w-4 h-4 animate-spin" />
          )}
          {isApproving ? "Approving..." : processing ? "Listing..." : "Sell"}
        </button>
      </div>
    </div>
  );
};

// Version unique et complète du ListingCard
const ListingCard = ({ listing, showBuy, onBuy, onCancel, boxTypeColors }) => {
  const [processing, setProcessing] = useState(false);

  const handleAction = async () => {
    setProcessing(true);
    try {
      if (showBuy) {
        await onBuy(listing.listingId);
      } else {
        await onCancel(listing.listingId);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="bg-[#252832] rounded-2xl p-6 hover:bg-[#2A2E38] transition-all duration-300">
      <div className="flex items-center mb-4 gap-3">
        <div className="bg-[#F4B000]/10 p-3 rounded-xl">
          <Box className="w-6 h-6 text-[#F4B000]" />
        </div>
        <div>
          <h3 className="text-lg font-semibold">Box #{listing.tokenId}</h3>
          <p className="text-sm text-[#9da5b1]">
            Listed by: {listing.seller.slice(0, 6)}...{listing.seller.slice(-4)}
          </p>
        </div>
        <span
          className={`ml-auto px-3 py-1 rounded-full text-white text-sm ${
            boxTypeColors[listing.boxType]
          }`}
        >
          {listing.boxType}
        </span>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <DollarSign className="w-5 h-5 text-[#F4B000]" />
        <span className="text-xl font-bold text-[#F4B000]">
          {listing.price} {listing.currency}
        </span>
      </div>
      <button
        className={`w-full py-2 rounded-xl ${
          showBuy
            ? "bg-[#F4B000] text-black hover:bg-[#F4B000]/90"
            : "bg-red-500/20 text-red-500 hover:bg-red-500/30"
        } font-semibold transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50`}
        disabled={processing}
        onClick={handleAction}
      >
        {processing && <Loader2 className="w-4 h-4 animate-spin" />}
        {showBuy ? "Buy Now" : "Cancel Selling"}
      </button>
    </div>
  );
};

const MarketplacePage = () => {
  const [activeTab, setActiveTab] = useState("buy");
  const [marketplaceData, setMarketplaceData] = useState({
    activeListings: [],
    myListings: [],
    myBoxes: [],
  });
  const [approving, setApproving] = useState(false);
  const { notify } = useNotification("");
  const { wallet, provider } = useWallet();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const loadingRef = useRef(false);

  const boxTypeColors = {
    BRONZE: "bg-[#CD7F32]",
    SILVER: "bg-[#C0C0C0]",
    GOLD: "bg-[#FFD700]",
    DIAMOND: "bg-[#B9F2FF]",
  };

  const MARKETPLACE_ADDRESS = process.env.REACT_APP_MARKETPLACE_ADDRESS;
  const BOX_NFT_ADDRESS = process.env.REACT_APP_BOX_NFT_CONTRACT_ADDRESS;

  const loadMarketplaceData = useCallback(async () => {
    console.log("loadMarketplaceData called");
    if (loadingRef.current) return;
    try {
      loadingRef.current = true;
      // Récupérer les listings même si pas d'adresse
      const listingsResponse = await axiosInstance.get(
        "/api/marketplace/listings"
      );
      let newData = {
        activeListings: listingsResponse.data,
        myListings: [],
        myBoxes: [],
      };

      // Ne récupérer les boxes que si on a une adresse
      if (wallet.address) {
        const boxesResponse = await axiosInstance.get(
          `/api/boxes/${wallet.address}`
        );
        // Filter listings for current user
        const userListings = listingsResponse.data.filter(
          (listing) =>
            listing.seller.toLowerCase() === wallet.address.toLowerCase()
        );
        newData.myListings = userListings;
        newData.myBoxes = boxesResponse.data;
      }
      setMarketplaceData(newData);
    } catch (error) {
      notify("Failed to load marketplace data");
    } finally {
      loadingRef.current = false;
    }
  }, [notify, wallet?.address]);

  useEffect(() => {
    const load = async () => {
      if (loadingRef.current) return;
      await loadMarketplaceData();
    };

    load();
  }, [loadMarketplaceData, shouldRefresh]);

  const checkAndApproveMarketplace = async (tokenId) => {
    if (!wallet || !provider) {
      notify("Please connect your wallet");
      return false;
    }

    try {
      // Créer l'interface à partir de l'ABI
      const boxInterface = new Interface(BoxNFT);

      // Créer le contrat avec l'interface
      const boxContract = new Contract(BOX_NFT_ADDRESS, boxInterface, wallet);

      console.log("Checking token:", tokenId);

      const owner = await boxContract.ownerOf(tokenId);
      console.log("Box owner:", owner);

      if (owner.toLowerCase() !== wallet.address.toLowerCase()) {
        notify("You don't own this box");
        return false;
      }

      const approvedAddress = await boxContract.getApproved(tokenId);
      console.log("Current approval:", approvedAddress);

      if (approvedAddress.toLowerCase() !== MARKETPLACE_ADDRESS.toLowerCase()) {
        setApproving(true);
        const tx = await boxContract.approve(MARKETPLACE_ADDRESS, tokenId);
        await tx.wait();
        notify("Marketplace approved successfully");
      }

      return true;
    } catch (error) {
      console.error("Approval error:", error);
      notify(error.message || "Failed to approve marketplace");
      return false;
    } finally {
      setApproving(false);
    }
  };

  const handleList = async (tokenId, currentPrice) => {
    if (!tokenId) {
      console.error("No tokenId provided:", tokenId);
      notify("Invalid box selection");
      return;
    }

    if (!currentPrice) {
      notify("Please enter a price");
      return;
    }

    if (!wallet?.address) {
      notify("Please connect your wallet");
      return;
    }

    try {
      const isApproved = await checkAndApproveMarketplace(tokenId);
      if (!isApproved) {
        console.log("Marketplace approval failed");
        return;
      }

      const marketplaceContract = new Contract(
        MARKETPLACE_ADDRESS,
        Marketplace,
        wallet
      );

      const priceInWei = parseEther(currentPrice.toString());

      const tx = await marketplaceContract.listItem(
        BOX_NFT_ADDRESS,
        tokenId,
        priceInWei,
        ZeroAddress
      );

      console.log("Marketplace transaction sent:", tx.hash);
      const receipt = await tx.wait();
      console.log("Transaction confirmed");

      // Récupérer l'ID du listing depuis l'événement indexé
      const listingId = receipt.logs?.[1].topics[1];
      // Convertir de bytes32 à nombre
      const formattedListingId = parseInt(listingId, 16).toString();

      // 3. Mettre à jour la base de données
      await axiosInstance.post("/api/marketplace/list", {
        tokenId,
        price: currentPrice,
        address: wallet.address,
        listingId: formattedListingId,
        transactionHash: tx.hash,
      });

      notify("Box listed successfully");
    } catch (error) {
      console.error("Listing error:", error);
      if (error.response?.data) {
        console.error("Server error details:", error.response.data);
      }
      notify(
        error.response?.data?.message || error.message || "Failed to list box"
      );
    } finally {
    }
  };

  const handleBuy = async (listingId) => {
    if (!wallet?.address) {
      notify("Please connect your wallet");
      return;
    }

    try {
      // 1. Interaction avec la blockchain
      const marketplaceContract = new Contract(
        MARKETPLACE_ADDRESS,
        Marketplace,
        wallet
      );

      // Récupérer le listing pour voir le prix
      const listing = await marketplaceContract.listings(listingId);

      // Effectuer l'achat
      const tx = await marketplaceContract.buyItem(listingId, {
        value: listing.price, // Pour le paiement en BNB
      });

      console.log("Buy transaction sent:", tx.hash);
      await tx.wait();
      console.log("Buy transaction confirmed");

      // Récupérer le type de box depuis le contrat NFT
      const nftContract = new Contract(BOX_NFT_ADDRESS, BoxNFT, wallet);
      const boxInfo = await nftContract.getBox(listing.tokenId.toString());
      const boxType = ["BRONZE", "SILVER", "GOLD", "DIAMOND"][boxInfo.boxType];

      // 2. Mise à jour de la base de données avec les infos de la box
      await axiosInstance.post("/api/marketplace/buy", {
        listingId,
        address: wallet.address,
        transactionHash: tx.hash,
        tokenId: listing.tokenId.toString(),
        boxType,
      });

      notify("Box purchased successfully");
      setTimeout(() => {
        setShouldRefresh((prev) => !prev);
      }, 100);
    } catch (error) {
      console.error("Buy error:", error);
      notify(error.message || "Failed to buy box");
    }
  };

  const handleCancel = async (listingId) => {
    if (!wallet?.address) {
      notify("Please connect your wallet");
      return;
    }

    try {
      // 1. Récupérer les infos du listing avant de l'annuler
      const marketplaceContract = new Contract(
        MARKETPLACE_ADDRESS,
        Marketplace,
        wallet
      );
      const listing = await marketplaceContract.listings(listingId);

      // 2. Annuler le listing
      const tx = await marketplaceContract.cancelListing(listingId);
      console.log("Cancel transaction sent:", tx.hash);
      await tx.wait();
      console.log("Cancel transaction confirmed");

      // Récupérer le type de box depuis le contrat NFT
      const nftContract = new Contract(BOX_NFT_ADDRESS, BoxNFT, wallet);
      const boxInfo = await nftContract.getBox(listing.tokenId.toString());
      const boxType = ["BRONZE", "SILVER", "GOLD", "DIAMOND"][boxInfo.boxType];

      // 3. Mise à jour de la base de données avec les infos de la box
      await axiosInstance.post("/api/marketplace/cancel", {
        listingId,
        address: wallet.address,
        transactionHash: tx.hash,
        tokenId: listing.tokenId.toString(),
        boxType,
      });

      notify("Listing cancelled successfully");
      setTimeout(() => {
        setShouldRefresh((prev) => !prev);
      }, 100);
    } catch (error) {
      console.error("Cancel error:", error);
      notify(error.message || "Failed to cancel listing");
    }
  };

  const { activeListings, myListings, myBoxes } = marketplaceData;

  return (
    <div className="min-h-screen bg-[#1D1F24] text-white md:pl-[120px] md:pt-28 md:p-12 py-20 px-4">
      <div className="mb-8">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          NFT <span className="text-[#F4B000]">Marketplace</span>
        </h1>
        <p className="text-[#9da5b1] text-sm md:text-base">
          Buy and sell your Rekt Boxes
        </p>
      </div>

      <div className="flex mb-6 bg-[#252832] rounded-xl p-1">
        {["Buy", "Sell", "My Listings"].map((tab) => (
          <button
            key={tab}
            className={`flex-1 py-3 px-4 rounded-lg font-semibold transition-all duration-300 ${
              activeTab === tab.toLowerCase()
                ? "bg-[#1D1F24] text-[#F4B000]"
                : "text-[#9da5b1] hover:text-white"
            }`}
            onClick={() => setActiveTab(tab.toLowerCase())}
          >
            {tab}
          </button>
        ))}
      </div>

      <>
        {activeTab === "sell" && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {myBoxes.map((box) => (
              <BoxCard
                key={box.tokenId}
                box={box}
                onList={handleList}
                isApproving={approving} // On passe l'état à l'enfant
              />
            ))}
          </div>
        )}

        {activeTab === "buy" && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {activeListings.map((listing) => (
              <ListingCard
                key={listing.listingId}
                listing={listing}
                showBuy={true}
                onBuy={handleBuy}
                onCancel={handleCancel}
                boxTypeColors={boxTypeColors}
              />
            ))}
          </div>
        )}

        {activeTab === "my listings" && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {myListings.map((listing) => (
              <ListingCard
                key={listing.listingId}
                listing={listing}
                showBuy={false}
                onBuy={handleBuy}
                onCancel={handleCancel}
                boxTypeColors={boxTypeColors}
              />
            ))}
          </div>
        )}
      </>
    </div>
  );
};

export default MarketplacePage;
