const gameSettings = {
    valorant: { minSize: 5, maxSizeWithCoach: 6, maxSizeWithoutCoach: 5 },
    rl: { minSize: 1, maxSizeWithCoach: 4, maxSizeWithoutCoach: 3 },
    lol: { minSize: 5, maxSizeWithCoach: 6, maxSizeWithoutCoach: 5 },
    // fortnite: { minSize: 1, maxSizeWithCoach: 5, maxSizeWithoutCoach: 4 },
    // olympusgame: { minSize: 1, maxSizeWithCoach: 2, maxSizeWithoutCoach: 1 },
    csgo: { minSize: 5, maxSizeWithCoach: 6, maxSizeWithoutCoach: 5 },
    dota2: { minSize: 5, maxSizeWithCoach: 6, maxSizeWithoutCoach: 5 },
  };
  
  module.exports = gameSettings;  