import React, {useEffect} from "react";
import styles from "./Whitepaper.module.css";
import TokenomicsVisualization from "./TokenomicsVisualisation";
import RoadmapWeb3 from "./RoadmapWeb3-2";
import Particles from '../../utils/particlesUtils'
import SEOManager from "../SEO/SEOManager";

const WhitePaper = () => {
  const sections = [
    { id: "introduction", title: "Introduction" },
    { id: "problem", title: "Mission" },
    { id: "solution", title: "Team" },
    { id: "technology", title: "Esport" },
    { id: "bet", title: "Bet" },
    { id: "leaderboard", title: "Leaderboard" },
    { id: "marketplace", title: "Marketplace" },
    { id: "conclusion", title: "Economy" },
    { id: "roadmap", title: "Roadmap" },
  ];

  useEffect(() => {
    // Ajoute la classe quand le composant monte
    document.body.classList.add('whitepaper-active');
    
    // Nettoie quand le composant démonte
    return () => {
      document.body.classList.remove('whitepaper-active');
    };
  }, []);

  return (
    <><SEOManager
    title={`REKT Esport - Whitepaper`}
    description={`Explore REKT's vision for Web3 gaming. Learn about our tokenomics, ecosystem and roadmap for the future of esport.`}
    ogTitle={`REKT Esport | Whitepaper`}
    ogDescription="Dive into REKT's comprehensive whitepaper. Discover our blockchain-based esport platform, token utility and ecosystem development plans."
  />
    <div className="whitepaperPage min-h-screen bg-[#1D1F24] pt-16 md:pt-24 md:px-24 lg:pl-[105px] lg:pr-1 md:pr-0">
      <div className="rondBizarreOrangeQuiClignote2"></div>
      <Particles />
      <div
        className={`${styles.containerWhitepaper} min-h-screen z-1 relative`}
      >
        <main className={styles.contentWhitepaper}>
          <h1><strong>REKT INC</strong></h1>
          <div className={styles.imagePlaceholder}>Overview</div>

          <section id="introduction">
            <h2>1. Introduction</h2>
            <p>
              REKT Inc is an innovative platform dedicated to{" "}
              <span className={styles.highlight}>eSport</span> and gaming,
              combining an esports structure with{" "}
              <span className={styles.highlight}>tournaments</span>,{" "}
              <span className={styles.highlight}>bets</span> and a governance
              token ($REKT) focused on Play-to-Earn and traditional video games.
              Our goal is to create a dynamic and engaged community while
              facilitating transparent and secure transactions within our
              ecosystem.
            </p>
          </section>

          <section id="problem">
            <h2>2. Mission</h2>
            <p>
              Our mission at REKT Inc is to revolutionize the esports and gaming
              industry by integrating blockchain technology into every facet of
              our platform. We aim to provide innovative and secure solutions
              that cater to the needs of players, teams, and fans alike. By
              leveraging the power of decentralized finance (DeFi) and
              blockchain, we strive to create a transparent and fair environment
              where users can participate in tournaments, place bets on their
              favorite esports events, and engage in governance activities.
            </p>
            <p>
              We are committed to fostering a vibrant community that is driven
              by engagement and collaboration. Through our $REKT governance
              token, we empower our users to have a direct say in the future
              development of the platform, ensuring that it evolves in a way
              that best serves their interests. Our platform also aims to bridge
              the gap between traditional gaming and the burgeoning Play-to-Earn
              model, offering users new opportunities to earn rewards and
              monetize their gaming skills.
            </p>
            <p>
              By providing a seamless and intuitive user experience, robust
              security measures, and a fair economic model, REKT Inc aspires to
              become the leading platform for esports enthusiasts and blockchain
              innovators. We believe that by combining the best aspects of both
              worlds, we can drive the next generation of esports and gaming.
            </p>
          </section>

          <section id="solution">
            <h2>3. Team and our goal</h2>
            <p>
              We have assembled a dedicated and diverse team of professionals
              who are passionate about esports, gaming, and blockchain
              technology. Our collective expertise drives our mission to create
              a revolutionary platform that caters to the needs of our users.
            </p>
            <div className={styles.memberSection}>
              <h3>Founders</h3>
              <div className={styles.teamMember}>
                <strong>Luc W.</strong>{" "}
                <a
                  href="https://www.linkedin.com/in/luc-w-180469315/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                    alt="LinkedIn"
                    className={styles.logoLinkedin}
                  />
                </a>
              </div>
              <div className={styles.teamMember}>
                <strong>Benjamin B.</strong>{" "}
                <a
                  href="https://www.linkedin.com/in/benjamin-b-766ba722b/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                    alt="LinkedIn"
                    className={styles.logoLinkedin}
                  />
                </a>
              </div>
            </div>
            <div className={styles.memberSection}>
              <h3>Developers</h3>
              <div className={styles.teamMember}>
                <strong>Luc W.</strong>{" "}
                <a
                  href="https://www.linkedin.com/in/luc-woillet-180469315/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                    alt="LinkedIn"
                    className={styles.logoLinkedin}
                  />
                </a>
              </div>
              <div className={styles.teamMember}>
                <strong>Malay</strong>
              </div>
            </div>
            <div className={styles.memberSection}>
              <h3>CMO</h3>
              <div className={styles.teamMember}>
                <strong>Baptiste C.</strong>{" "}
                <a
                  href="https://www.linkedin.com/in/baptiste-chesnel-/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                    alt="LinkedIn"
                    className={styles.logoLinkedin}
                  />
                </a>
              </div>
            </div>
            <div className={styles.memberSection}>
              <h3>Managers</h3>
              <div className={styles.teamMember}>
                <strong>Gor M.</strong>{" "}
                <a
                  href="https://www.linkedin.com/in/gor-mardumov-b82262315/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                    alt="LinkedIn"
                    className={styles.logoLinkedin}
                  />
                </a>
              </div>
            </div>
            <div className={styles.memberSection}>
              <p>
                <strong>Esports and Tournament Coordinators:</strong> REKT Inc
                is a French esports organization that works closely with the
                French Federation of Video Games (FFJV). This team organizes and
                manages esports events and tournaments on our platform, ensuring
                that competitions are fair, exciting, and rewarding for
                participants. Their expertise in esports, coupled with our
                strong connection to the FFJV, helps us provide high-quality
                events that attract top talent and engage our community.
              </p>
            </div>
          </section>

          <section id="technology">
            <div className={styles.imagePlaceholder}>Ecosystem</div>
            <h2>4. Esport</h2>
            <p>
              REKT Inc is committed to revolutionizing the esports industry by
              leveraging blockchain technology to create a dynamic and engaging
              platform for both professional and amateur players.
            </p>
            <h3>E-Sports Competitions :</h3>
            <p>
              Our platform will also host regular amateur tournaments, allowing
              gamers of all skill levels to compete. Participants in these
              competitions can earn rewards in $REKT tokens, fostering a
              competitive and rewarding environment for everyone.
            </p>
            <h3>Token Allocation :</h3>
            <p>
              As part of the esports section of REKT, 7.5% of tokens generated
              in the in-game economy are allocated specifically to support
              esports competitions and player rewards. These tokens are used
              for:
            </p>
            <ul>
              <li>
                <strong>Prizes and Rewards:</strong> Tokens are distributed as
                prizes to the winners of tournaments and esports events, as well
                as to the highest ranked players on the leaderboards.
              </li>
              <li>
                <strong>Organization of Events:</strong> Tokens are used to
                organize and promote tournaments, special events, and other
                esports activities.
              </li>
              <li>
                <strong>Player Support:</strong> Tokens can be used to provide
                financial support to talented players who actively participate
                in REKT esports competitions.
              </li>
            </ul>
          </section>

          <section id="bet">
            <h2>5. Bet</h2>
            <p>
              The Bet section of our platform is designed to provide a seamless
              and engaging betting experience for users. It integrates various
              games and competitions, offering opportunities to place bets and
              potentially earn rewards.
            </p>
            <div className={styles.grid}>
              <div className={styles.card}>
                <h3>Bet Placement</h3>
                <p>
                  The betting interface is intuitive and user-friendly, making
                  it easy for users to place bets. We offer various bet types,
                  including single bets, multiple bets, and system bets, to
                  cater to different user preferences.
                </p>
              </div>
              <div className={styles.card}>
                <h3>Security and Fairness</h3>
                <p>
                  All bets are recorded on the blockchain, ensuring transparency
                  and immutability. Smart contracts automate bet execution and
                  payouts, reducing the risk of fraud.
                </p>
              </div>
              <div className={styles.card}>
                <h3>Data Integrity</h3>
                <p>
                  We use reliable sources for real-time game data to ensure the
                  integrity of betting outcomes. Advanced algorithms detect and
                  prevent any form of cheating or manipulation.
                </p>
              </div>
            </div>
          </section>

          <section id="leaderboard">
            <h2>6. Leaderboard</h2>
            <p>
              The Leaderboards section of our platform highlights the top
              performers across various aspects of the ecosystem, including
              betting and tournament participation. By recognizing and rewarding
              the top 50 users in each category, we aim to incentivize
              engagement and foster a competitive yet rewarding environment.
            </p>
            <h3>Tournament Leaderboard</h3>
            <p>
              <strong>Top Teams and Players:</strong>This leaderboard highlights
              the best performing teams and individual players across various
              games in tournaments.
            </p>
            <p>
              <strong>Rewards:</strong> The top 50 teams and players receive
              exclusive rewards, including rare NFTs, bonus tokens, and special
              in-game items.
            </p>
            <h3>Bet Leaderboard</h3>
            <p>
              <strong>Performance Gains:</strong> This leaderboard tracks users
              based on their performance and success in betting activities.
            </p>
            <p>
              <strong>Metrics:</strong> Users are ranked according to metrics
              such as total winnings, winning streaks, and bet accuracy.
            </p>
            <p>
              <strong>Rewards:</strong> The top 50 users receive exclusive
              rewards, including rare NFTs and bonus tokens.
            </p>
          </section>

          <section id="marketplace">
            <h2>7. Marketplace</h2>
            <p>
              The Marketplace section of our platform is a dynamic hub where
              users can buy, sell, and trade various digital and physical items.
              Leveraging blockchain technology, our marketplace offers unique,
              verifiable digital assets (NFTs) and exclusive merchandise,
              enhancing the overall user experience and engagement.
              Additionally, users can equip their avatars with NFT items to gain
              performance bonuses in tournaments, betting activities, and
              leaderboard rewards.
            </p>
            <div className={styles.grid}>
              <div className={styles.card}>
                <h3>NFT Items</h3>
                <p>
                  <strong>Digital Merchandise:</strong> Users can purchase NFTs
                  representing digital merchandise such as T-shirts, caps, and
                  other accessories.
                </p>
                <p>
                  <strong>Rarity Levels:</strong> Each NFT item is available in
                  different rarity levels, ranging from common to legendary,
                  providing exclusivity and value.
                </p>
                <p>
                  <strong>Performance Boosts:</strong> Certain rare NFT items
                  offer performance boosts in tournaments, betting activities,
                  and leaderboard rewards for amateur teams.
                </p>
              </div>
              <div className={styles.card}>
                <h3>Physical Merchandise</h3>
                <p>
                  <strong>Player-Branded Items:</strong> The marketplace offers
                  real-world T-shirts and accessories featuring your favorite
                  players.
                </p>
                <p>
                  <strong>Exclusive Collections:</strong> Limited edition items
                  and collections are available, ensuring fans can own a piece
                  of exclusive merchandise.
                </p>
              </div>
              <div className={styles.card}>
                <h3>Avatars and NFT Integration</h3>
                <p>
                  Each user can create and customize their own avatar. Users
                  must equip their avatars with purchased NFT items to activate
                  performance bonuses on tournaments and bets rewards. The
                  equipped items are visually represented on the user's avatar,
                  showcasing their collection and enhancing their digital
                  identity.
                </p>
              </div>
            </div>
          </section>

          <section id="conclusion">
            <div className={styles.imagePlaceholder}>Economy</div>
            <h2>8. Tokenomics</h2>
            <TokenomicsVisualization />
          </section>

          <section id="roadmap">
            <h2 className="pt-6">9. Roadmap</h2>
            <RoadmapWeb3 />

            <p className="pt-6">
              Note: This whitepaper provides an overview of Rekt and can be used
              as a basis for developing more detailed plans regarding the
              development, launch, and management of the project.
            </p>
          </section>
        </main>

        <aside className={styles.sidebarWhitepaper}>
          <h2>Summary</h2>
          <ul>
            {sections.map((section) => (
              <li key={section.id}>
                <a href={`#${section.id}`}>{section.title}</a>
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </div>
    </>
  );
};

export default WhitePaper;
