import React from "react";

function SelectedBets({ selectedBets }) {

  return (
    <div>
      {selectedBets.length === 0 ? (
        <div key="header">
        <h3>0 selection</h3>
        <hr className="border-t border-white/60 my-3 w-full"></hr>
        </div>
      ) : (
        <div key="bets-list">
          <h3>
            {selectedBets.length} selection{selectedBets.length > 1 ? "s" : ""}
          </h3>
          <hr className="border-t border-white/60 my-3 w-full"></hr>
          {selectedBets.map((bet) => (
            <div key={`${bet.matchId}-${bet.team}-${bet.odd}`} className="flex flex-col border-[#635e5ea2] border-[1px] rounded-2xl shadow-sm rounded-4 mb-3 p-2 font-bold">
              <div
                key={bet.matchId}
                className="flex-1 flex-col text-start pl-2"
              >
                <p>
                  {bet.title.includes(":")
                    ? bet.title.split(":").slice(1).join(":").trim()
                    : bet.title}
                </p>
                <p>{bet.team}</p>
                <p>{bet.odd}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectedBets;
