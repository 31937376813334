import React, { useState, useEffect } from 'react';
import { formatEther } from 'ethers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useWallet } from "../context/WalletContext";

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { wallet, SUPPORTED_TOKENS } = useWallet();
  const [isWhitepaper, setIsWhitepaper] = useState(false);

  useEffect(() => {
    const checkWhitepaper = () => {
      setIsWhitepaper(document.body.classList.contains('whitepaper-active'));
    };

    // Vérifie au montage
    checkWhitepaper();

    // Observer les changements de classe sur le body
    const observer = new MutationObserver(checkWhitepaper);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!wallet?.address) {
        console.log("Wallet address is missing");
        return;
      }
      
      try {
        setIsLoading(true);
        console.log("Fetching transactions for address:", wallet.address);
        
        const urlBNB = `https://api-testnet.bscscan.com/api?module=account&action=txlist&address=${wallet.address}&startblock=0&endblock=99999999&sort=desc`;
        const urlToken = `https://api-testnet.bscscan.com/api?module=account&action=tokentx&address=${wallet.address}`;
        
        const [responseBNB, responseToken] = await Promise.all([fetch(urlBNB), fetch(urlToken)]);
        const dataBNB = await responseBNB.json();
        const dataToken = await responseToken.json();

        if (dataBNB.status !== "1" && dataToken.status !== "1") {
          throw new Error("Failed to fetch transactions");
        }

        const transactionsBNB = dataBNB.status === "1" ? dataBNB.result : [];
        const transactionsToken = dataToken.status === "1" ? dataToken.result : [];

        const allTransactions = [...transactionsBNB, ...transactionsToken].map(tx => {
            if (tx.tokenSymbol) {
              return {
                ...tx,
                value: tx.value,
                tokenDecimal: tx.tokenDecimal || 18,
                tokenSymbol: tx.tokenSymbol,
              };
            } else {
              return {
                ...tx,
                value: tx.value,
                tokenDecimal: 18,
                tokenSymbol: "BNB",
              };
            }
          });

        console.log(`Total transactions found: ${allTransactions.length}`);
        const filteredTransactions = allTransactions.filter(tx => 
          tx.from?.toLowerCase() === wallet.address.toLowerCase() || 
          tx.to?.toLowerCase() === wallet.address.toLowerCase()
        );
        const sortedTransactions = filteredTransactions.sort((a, b) => b.timeStamp - a.timeStamp);

        setTransactions(sortedTransactions.slice(0, 50));
        setError(null);
      } catch (err) {
        console.error("Transaction fetch error:", err);
        setError("Failed to fetch transactions");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [wallet, SUPPORTED_TOKENS]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    return new Date(timestamp * 1000).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatAmount = (amount) => {
    try {
      const value = formatEther(amount);
      const numValue = parseFloat(value);
      return numValue;
    } catch (err) {
      console.error("Error formatting amount:", err);
      return 0.0;
    }
  };

  const TransactionItem = ({ tx }) => {
    const isSent = tx.from?.toLowerCase() === wallet?.address?.toLowerCase();
    const isReceived = tx.to && tx.to?.toLowerCase() === wallet?.address?.toLowerCase();

    const tokenSymbol = tx.tokenSymbol ? tx.tokenSymbol : "BNB";
    
    return (
      <div className={`px-2 py-4 border-b ${isWhitepaper ? "border-[#6C63FF]/20" : "border-[#F4B000]/20"} last:border-b-0`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className={`${isWhitepaper ? "text-[#6387ff]" : "text-[#F4B000]"} w-8 h-8 rounded-full flex items-center justify-center bg-white/5`}>
              <FontAwesomeIcon 
                icon={faArrowRight} 
                className={isSent ? "" : "rotate-180"} 
              />
            </div>
            <div>
              <div className="text-white text-sm font-medium">
              {isSent ? "Sent" : isReceived ? "Received" : "Unknown"}
              </div>
              <div className="text-white/50 text-xs">
                {formatDate(tx.timeStamp)}
              </div>
            </div>
          </div>
          <div className="text-right">
            <div className={`text-sm font-medium ${isReceived ? "text-[#5dc454]" : "text-[#c54040]"}`}>
            {formatAmount(tx.value, tx.tokenDecimal).toFixed(2)} {tokenSymbol}
            </div>
            <div 
              className="text-xs text-white/50 hover:text-white/80 cursor-pointer" 
              onClick={() => window.open(`https://testnet.bscscan.com/tx/${tx.hash}`, '_blank')}
            >
              {tx.hash.slice(0, 6)}...{tx.hash.slice(-4)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <FontAwesomeIcon icon={faSpinner} className="text-white/50 w-6 h-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1">
        {error ? (
          <div className="text-center py-8 text-red-400">
            {error}
          </div>
        ) : transactions.length === 0 ? (
          <div className="text-center py-8 text-white/50">
            No transactions found
          </div>
        ) : (
          <div className={`bg-white/5 border-y-1 ${isWhitepaper ? "border-[#6C63FF]/20" : "border-[#F4B000]/20"} h-[543px] overflow-y-scroll`}>
            {transactions.map((tx, index) => (
              <TransactionItem key={`${tx.hash}-${index}`} tx={tx} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionHistory;