import { useState, useEffect, useCallback } from "react";
import { claimRewards, getPendingRewards } from "./Stakepool";
import { useWallet } from "../../context/WalletContext";

import rektLogo from "../../REKT_Logo.webp";

import styles from "../../styles/Staking.module.css";
import { useNotification } from "../Notifications/Notifications";

export const ClaimRewards = () => {
  const [loading, setLoading] = useState(false);
  const [pendingRewards, setPendingRewards] = useState("0");
  const { account, wallet } = useWallet();
  const { notify } = useNotification();

  const loadPendingRewards = useCallback(async () => {
    if (!account) return;

    try {
      const rewards = await getPendingRewards(wallet);
      setPendingRewards(rewards);
    } catch (error) {
      console.error("Error loading pending rewards:", error);
    }
  }, [account, wallet]);

  useEffect(() => {
    loadPendingRewards();
    const interval = setInterval(loadPendingRewards, 30000);
    return () => clearInterval(interval);
  }, [account, loadPendingRewards]);

  const handleClaim = async () => {
    if (!account) {
      return;
    }

    if (parseFloat(pendingRewards) <= 0) {
      return;
    }

    setLoading(true);

    try {
      await claimRewards(wallet);
      notify("Rewards claimed successfully!");
      loadPendingRewards(); // Recharger les récompenses après la réclamation
    } catch (error) {
      console.error("Claiming rewards error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <hr className={styles.divider} />
      <h2>Claim Rewards</h2>
      <div className={styles.claim}>
        <div className={styles.rewardsInfo}>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Rewards : {parseFloat(pendingRewards).toFixed(4)}{" "}
            <img
              src={rektLogo}
              alt="REKT Logo"
              style={{ width: "15px", height: "auto", marginLeft: "5px" }}
            />
          </p>
        </div>
        <button
          onClick={handleClaim}
          disabled={loading || parseFloat(pendingRewards) <= 0}
          id={styles.buttonClaim}
        >
          {loading ? "Claiming..." : "Claim Rewards"}
        </button>
      </div>
    </div>
  );
};
