import React, { useEffect } from "react";

export default function Tokenomics() {
  useEffect(() => {
    let charts = {
      distribution: null,
      vesting: null
    };

    let resizeTimeout = null;

    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/chart.js";
    script.async = true;
    script.onload = () => {
      const createDistributionChart = () => {
        if (charts.distribution) {
          charts.distribution.destroy();
        }

        const ctx = document.getElementById("tokenDistribution")?.getContext("2d");
        if (!ctx) return;

        charts.distribution = new window.Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Community",
              "Treasury (Vesting 3 years)",
              "Tournaments Rewards",
              "ICO (Vesting 1 year)",
              "Airdrops",
              "Advisors",
            ],
            datasets: [
              {
                data: [40, 30, 10, 8.2, 10, 1.8],
                backgroundColor: [
                  "#FF6B6B",
                  "#4ECDC4",
                  "#6C63FF",
                  "#FF6584",
                  "#FFD93D",
                  "#6C5CE7",
                ],
                hoverBackgroundColor: [
                  "#FF6B6B",
                  "#4ECDC4",
                  "#6C63FF",
                  "#FF6584",
                  "#FFD93D",
                  "#6C5CE7",
                ],
                borderWidth: 0,
                offset: 0,
                hoverOffset: 0,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "right",
                labels: {
                  color: "#fff",
                },
              },
            },
          },
        });
      };

      const recreateVestingCanvas = () => {
        const container = document.querySelector('.vesting-chart-container');
        if (!container) return;

        // Supprimer l'ancien canvas s'il existe
        const oldCanvas = document.getElementById("vestingSchedule");
        if (oldCanvas) {
          oldCanvas.remove();
        }

        // Créer un nouveau canvas
        const newCanvas = document.createElement('canvas');
        newCanvas.id = "vestingSchedule";
        container.appendChild(newCanvas);
      };

      const createVestingChart = () => {
        if (window.innerWidth < 820) return;

        if (charts.vesting) {
          charts.vesting.destroy();
          charts.vesting = null;
        }

        recreateVestingCanvas();

        const ctx = document.getElementById("vestingSchedule")?.getContext("2d");
        if (!ctx) return;

        charts.vesting = new window.Chart(ctx, {
          type: "line",
          data: {
            labels: [
              "TGE",
              "Month 4",
              "Month 8",
              "Month 12",
              "Month 16",
              "Month 20",
              "Month 24",
              "Month 28",
              "Month 32",
              "Month 36",
            ],
            datasets: [
              {
                label: "Community (40%)",
                data: [10, 20, 30, 45, 60, 75, 85, 95, 100, 100],
                borderColor: "#FF6B6B",
                backgroundColor: "rgba(255, 107, 107, 0.1)",
                tension: 0.4,
                fill: true,
              },
              {
                label: "Treasury (30%)",
                data: [0, 10, 20, 35, 50, 65, 80, 90, 95, 100],
                borderColor: "#4ECDC4",
                backgroundColor: "rgba(78, 205, 196, 0.1)",
                tension: 0.4,
                fill: true,
              },
              {
                label: "Tournament (10%)",
                data: [5, 15, 30, 50, 70, 85, 95, 100, 100, 100],
                borderColor: "#6C63FF",
                backgroundColor: "rgba(108, 99, 255, 0.1)",
                tension: 0.4,
                fill: true,
              },
              {
                label: "ICO (8.2%)",
                data: [20, 35, 50, 70, 85, 95, 100, 100, 100, 100],
                borderColor: "#FF6584",
                backgroundColor: "rgba(255, 101, 132, 0.1)",
                tension: 0.4,
                fill: true,
              },
              {
                label: "Airdrops (10%)",
                data: [15, 30, 45, 60, 75, 90, 100, 100, 100, 100],
                borderColor: "#FFD93D",
                backgroundColor: "rgba(255, 217, 61, 0.1)",
                tension: 0.4,
                fill: true,
              },
              {
                label: "Advisors (1.8%)",
                data: [0, 0, 20, 40, 60, 80, 90, 95, 100, 100],
                borderColor: "#6C5CE7",
                backgroundColor: "rgba(108, 92, 231, 0.1)",
                tension: 0.4,
                fill: true,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                labels: {
                  color: "#fff",
                },
              },
              title: {
                display: true,
                text: "Vesting Schedule (%)",
                color: "#fff",
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  color: "rgba(255, 255, 255, 0.1)",
                },
                ticks: {
                  color: "#fff",
                },
              },
              x: {
                grid: {
                  color: "rgba(255, 255, 255, 0.1)",
                },
                ticks: {
                  color: "#fff",
                },
              },
            },
          },
        });
      };

      // Initialisation des graphiques
      createDistributionChart();
      createVestingChart();

      const handleResize = () => {
        if (resizeTimeout) {
          clearTimeout(resizeTimeout);
        }

        const vestingContainer = document.querySelector('.vesting-container');
        if (window.innerWidth < 820) {
          if (charts.vesting) {
            charts.vesting.destroy();
            charts.vesting = null;
          }
          if (vestingContainer) {
            vestingContainer.style.display = 'none';
          }
        }

        resizeTimeout = setTimeout(() => {
          if (window.innerWidth >= 820) {
            if (vestingContainer) {
              vestingContainer.style.display = 'block';
              createVestingChart();
            }
          }
        }, 250);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        if (resizeTimeout) {
          clearTimeout(resizeTimeout);
        }
        if (charts.distribution) {
          charts.distribution.destroy();
        }
        if (charts.vesting) {
          charts.vesting.destroy();
        }
      };
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <style>{`
        @media (max-width: 819px) {
          .vesting-container {
            display: none !important;
          }
        }
      `}</style>
      <div className="m-0 p-0 text-white min-h-auto flex flex-col items-center">
        <div className="max-w-[1200px] w-full p-0">
          <div className="bg-white/5 rounded-[20px] p-8 backdrop-blur-[10px] border border-white/10 mb-8 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-[#6C63FF]/10 to-[#FF6584]/10 -z-10" />
            <canvas id="tokenDistribution"></canvas>
          </div>

          <div className="vesting-container bg-white/5 rounded-[20px] p-8 backdrop-blur-[10px] border border-white/10 mb-8 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-[#6C63FF]/10 to-[#FF6584]/10 -z-10" />
            <div className="vesting-chart-container">
              <canvas id="vestingSchedule"></canvas>
            </div>
          </div>

          <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-6 mt-8">
            <div className="bg-gradient-to-r from-[#6C63FF]/10 to-[#FF6584]/10 rounded-[15px] p-6 text-center transition-transform hover:-translate-y-[5px]">
              <div className="text-2xl font-bold text-[#94A3B8] bg-clip-text mb-2">
                700,000,000
              </div>
              <div className="text-[#94A3B8] text-base">Total Supply</div>
            </div>
            <div className="bg-gradient-to-r from-[#6C63FF]/10 to-[#FF6584]/10 rounded-[15px] p-6 text-center transition-transform hover:-translate-y-[5px]">
              <div className="text-2xl font-bold text-[#94A3B8] bg-clip-text mb-2">
                8.2%
              </div>
              <div className="text-[#94A3B8] text-base">ICO Allocation</div>
            </div>
            <div className="bg-gradient-to-r from-[#6C63FF]/10 to-[#FF6584]/10 rounded-[15px] p-6 text-center transition-transform hover:-translate-y-[5px]">
              <div className="text-2xl font-bold text-[#94A3B8] bg-clip-text mb-2">
                3 Years
              </div>
              <div className="text-[#94A3B8] text-base">Treasury Vesting</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}