import React, { useState, useEffect } from "react";
import { useWallet } from "../../context/WalletContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faSpinner,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

const TRANSAK_STATUS = {
  INITIAL: "initial",
  LOADING: "loading",
  KYC_PENDING: "kyc_pending",
  ERROR: "error",
};

const TransakButton = ({ onSuccess }) => {
  const { wallet } = useWallet();
  const [status, setStatus] = useState(TRANSAK_STATUS.INITIAL);
  const [error, setError] = useState(null);
  // const [transakWindow, setTransakWindow] = useState(null);
  const [isWhitepaper, setIsWhitepaper] = useState(false);

  useEffect(() => {
    const checkWhitepaper = () => {
      setIsWhitepaper(document.body.classList.contains('whitepaper-active'));
    };

    // Vérifie au montage
    checkWhitepaper();

    // Observer les changements de classe sur le body
    const observer = new MutationObserver(checkWhitepaper);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  // // Gestionnaire de messages
  // const handleMessage = useCallback(
  //   (e) => {
  //     console.log("Received message from Transak:", e.data);

  //     if (e.data?.status === "TRANSAK_ORDER_SUCCESSFUL") {
  //       setStatus(TRANSAK_STATUS.INITIAL);
  //       if (onSuccess) {
  //         onSuccess(e.data.orderId);
  //       }
  //       transakWindow?.close();
  //     } else if (e.data?.status === "TRANSAK_KYC_STARTED") {
  //       setStatus(TRANSAK_STATUS.KYC_PENDING);
  //     } else if (e.data?.status === "TRANSAK_WIDGET_CLOSE") {
  //       setStatus(TRANSAK_STATUS.INITIAL);
  //     } else if (e.data?.status?.includes("ERROR")) {
  //       setStatus(TRANSAK_STATUS.ERROR);
  //       setError(e.data.message || "An error occurred");
  //     }
  //   },
  //   [onSuccess, transakWindow]
  // );

  // Nettoyage des écouteurs d'événements
  // useEffect(() => {
  //   window.addEventListener("message", handleMessage);
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, [handleMessage]);

  const openTransakWidget = () => {
    if (!wallet?.address) return;
    setStatus(TRANSAK_STATUS.LOADING);
    setError(null);

    const redirectURL = encodeURIComponent(window.location.origin);

    const params = new URLSearchParams({
      apiKey: "9506ca6b-938b-40f1-ae2c-8b4254eced69",
      environment: "STAGING",
      defaultCryptoCurrency: "BNB",
      walletAddress: wallet.address,
      themeColor: "F4B000",
      defaultNetwork: "bsc_testnet",
      network: "bsc_testnet",
      cryptoCurrencyCode: "BNB",
      disableWalletAddressForm: "true",
      isTestNet: "true",
      hideMenu: "true",
      exchangeScreenTitle: "Buy Test BNB",
      hostURL: redirectURL,
      redirectURL: redirectURL,
      partnerOrderId: `${Date.now()}-${wallet.address.slice(0, 8)}`,
    }).toString();

    const width = 500;
    const height = 650;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    try {
      const newWindow = window.open(
        `https://staging-global.transak.com/?${params}`,
        "Transak",
        `width=${width},height=${height},left=${left},top=${top},status=1,scrollbars=1`
      );

      // setTransakWindow(newWindow);

      // Vérifier la fermeture de la fenêtre
      const checkWindow = setInterval(() => {
        if (newWindow?.closed) {
          clearInterval(checkWindow);
          setStatus(TRANSAK_STATUS.INITIAL);
          // setTransakWindow(null);
        }
      }, 500);
    } catch (err) {
      console.error("Error opening Transak:", err);
      setStatus(TRANSAK_STATUS.ERROR);
      setError("Failed to open payment window. Please try again.");
    }
  };

  // Texte du bouton selon l'état
  const buttonContent = () => {
    switch (status) {
      case TRANSAK_STATUS.LOADING:
        return (
          <>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            <span>Opening payment...</span>
          </>
        );
      case TRANSAK_STATUS.KYC_PENDING:
        return (
          <>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            <span>KYC in progress...</span>
          </>
        );
      case TRANSAK_STATUS.ERROR:
        return (
          <>
            <FontAwesomeIcon icon={faWarning} className="text-red-400" />
            <span>Try again</span>
          </>
        );
      default:
        return (
          <>
            <FontAwesomeIcon icon={faCreditCard} />
            <span>Buy Crypto</span>
          </>
        );
    }
  };

    // const isDisabled = !wallet || status === TRANSAK_STATUS.LOADING || status === TRANSAK_STATUS.KYC_PENDING;
  const isDisabled =
    wallet ||
    status === TRANSAK_STATUS.LOADING ||
    status === TRANSAK_STATUS.KYC_PENDING;

  const gradientButton = isWhitepaper ? "bg-gradient-to-r from-[#6C63FF] to-[#FF6584]": "bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]";

  return (
    <div className="relative w-full mt-6">
      <button
        onClick={openTransakWidget}
        disabled={isDisabled}
        className={`
          w-full py-2 px-4 rounded-xl text-white text-sm 
          transition-all duration-200
          flex items-center justify-center space-x-2
          ${
            isDisabled
              ? "bg-gray-500/20 cursor-not-allowed opacity-50"
              : `${gradientButton} hover:opacity-90`
          }
        `}
      >
        {buttonContent()}
      </button>

      <div className="absolute -top-3 -right-3 bg-yellow-500/20 text-yellow-500 text-xs px-2 py-1 rounded-full border border-yellow-500/40">
        SOON
      </div>

      {error && (
        <div className="text-xs text-red-400 text-center mt-1">{error}</div>
      )}

      {!wallet && !error && (
        <div className="text-xs text-white/60 text-center mt-1">
          Please connect your wallet first
        </div>
      )}

      {status === TRANSAK_STATUS.KYC_PENDING && (
        <div className="text-xs text-white/60 text-center mt-1">
          Please complete KYC in the opened window
        </div>
      )}
    </div>
  );
};

export default TransakButton;
