import React, { useEffect, useState, useCallback } from "react";
import axiosInstance from "../../axiosConfig";
import { useNotification } from "./Notifications";
import styles from "../../styles/NotificationButton.module.css";
import { useWallet } from "../../context/WalletContext";
import { ethers } from "ethers";
import FaucetCountdown from "./FaucetCountDown";

const FAUCET_ADDRESS = "0x9655e22670Da4Ae0b74B0de547e7EaF38799c590";
const FAUCET_ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "requestTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "lastRequestTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

function FriendRequests({ userId }) {
  const [friendRequests, setFriendRequests] = useState([]);
  const { account } = useWallet();
  const { notify } = useNotification();

  useEffect(() => {
    const fetchFriendRequests = async () => {
      try {
        if (!account) return;
        const response = await axiosInstance.get(`/api/users/eth/${account}`);
        const requests = response.data.friendRequests || [];

        // Récupérer les détails de chaque utilisateur qui a envoyé une demande
        const detailedRequests = await Promise.all(
          requests.map(async (request) => {
            const userResponse = await axiosInstance.get(
              `/api/users/eth/${request.from}`
            );
            return {
              ...request,
              fromUser: userResponse.data,
            };
          })
        );

        setFriendRequests(detailedRequests);
      } catch (error) {
        console.error("Error fetching friend requests:", error);
      }
    };

    fetchFriendRequests();
  }, [account]);

  const handleAcceptFriend = async (requestId) => {
    try {
      await axiosInstance.post(`/api/users/${account}/friends/accept`, {
        requestId,
      });
      setFriendRequests((prev) => prev.filter((req) => req._id !== requestId));
      notify("Friend request accepted");
    } catch (error) {
      console.error("Error accepting friend request:", error);
      notify("Failed to accept friend request");
    }
  };

  const handleRejectFriend = async (requestId) => {
    try {
      await axiosInstance.post(`/api/users/${account}/friends/reject`, {
        requestId,
      });
      setFriendRequests((prev) => prev.filter((req) => req._id !== requestId));
      notify("Friend request rejected");
    } catch (error) {
      console.error("Error rejecting friend request:", error);
      notify("Failed to reject friend request");
    }
  };

  return (
    <div>
      {friendRequests.length > 0 && (
        <div>
          <h3 className="font-semibold mb-2">Friend Requests</h3>
          <ul className={styles.invitationSchema}>
            {friendRequests.map((request) => (
              <li
                key={request._id}
                className="bg-[#00000025] hover:scale-[1.03] transition-transform mb-3 rounded-xl"
              >
                <div className="p-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      {request.fromUser.imageUrl ? (
                        <img
                          src={request.fromUser.imageUrl}
                          alt="Profile"
                          className="w-10 h-10 rounded-full object-cover"
                        />
                      ) : (
                        <div className="w-10 h-10 rounded-full bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] flex items-center justify-center text-white">
                          {request.fromUser.name?.substring(0, 2).toUpperCase()}
                        </div>
                      )}
                      <div>
                        <span className="font-medium text-white">
                          {request.fromUser.name}
                        </span>
                        <span className="text-sm text-white/50 block">
                          {request.fromUser.eth.substring(0, 6)}...
                          {request.fromUser.eth.substring(38)}
                        </span>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleAcceptFriend(request._id)}
                        className="px-3 py-1 bg-[#F4B000] text-white rounded-lg hover:opacity-90 transition-opacity"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleRejectFriend(request._id)}
                        className="px-3 py-1 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

const Invitations = ({ userId }) => {
  const [invitations, setInvitations] = useState([]);
  const [joinRequests, setJoinRequests] = useState([]);
  const [everyoneMentions, setEveryoneMentions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [friendNotifications, setFriendNotifications] = useState([]);
  const [faucetNotifications, setFaucetNotifications] = useState([]);
  const { account, wallet, provider } = useWallet();
  const { notify } = useNotification();
  const [nextClaimTime, setNextClaimTime] = useState(null);

  useEffect(() => {
    const checkLastClaim = async () => {
      try {
        if (!account || !provider) return;
  
        const faucetContract = new ethers.Contract(FAUCET_ADDRESS, FAUCET_ABI, provider);
        const lastClaimTime = await faucetContract.lastRequestTime(account);
        const lastClaimTimeNum = parseInt(lastClaimTime.toString());
  
        if (lastClaimTimeNum > 0) {
          setNextClaimTime(lastClaimTimeNum);
        }
      } catch (error) {
        console.error('Error checking last claim time:', error);
      }
    };
  
    checkLastClaim();
  }, [account, provider]);

  useEffect(() => {
    const fetchFaucetNotifications = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/notifications/user/${userId}?type=faucet_available`
        );
        setFaucetNotifications(
          Array.isArray(response.data) ? response.data : []
        );
      } catch (error) {
        console.error("Error fetching faucet notifications:", error);
      }
    };

    if (userId) {
      fetchFaucetNotifications();
    }
  }, [userId]);

  useEffect(() => {
    const fetchFriendNotifications = async () => {
      if (!account) return;
      try {
        const response = await axiosInstance.get(
          `/api/notifications/user/${userId}?type=friend_accept`
        );
        setFriendNotifications(
          Array.isArray(response.data) ? response.data : []
        );
      } catch (error) {
        console.error("Error fetching friend notifications:", error);
      }
    };

    fetchFriendNotifications();
  }, [account, userId]);

  const fetchAllNotifications = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `/api/notifications/user/${userId}?type=everyone_mention`
      );

      setEveryoneMentions(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/invitations/user/${userId}`
        );
        console.log("Raw invitations response:", response);
        console.log("Invitations data:", response.data);

        // Assurons-nous que nous avons un tableau
        const invitationsArray = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.invitations)
          ? response.data.invitations
          : [];

        console.log("Processed invitations array:", invitationsArray);
        setInvitations(invitationsArray);
      } catch (error) {
        console.error("Error fetching invitations:", error);
        console.error("Error details:", error.response?.data);
      }
    };

    const fetchJoinRequests = async () => {
      console.log("Fetching join requests for user:", userId);
      try {
        const response = await axiosInstance.get(
          `/api/invitations/requests/${userId}`
        );
        console.log("Raw requests response:", response);
        console.log("Requests data:", response.data);

        // Assurons-nous que nous avons un tableau
        const requestsArray = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.requests)
          ? response.data.requests
          : [];

        console.log("Processed requests array:", requestsArray);
        setJoinRequests(requestsArray);
      } catch (error) {
        console.error("Error fetching join requests:", error);
        console.error("Error details:", error.response?.data);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchInvitations();
      fetchJoinRequests();
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchAllNotifications();
    }
  }, [userId, fetchAllNotifications]);

  const handleClaimFaucet = async (notificationId) => {
    try {
      if (!wallet) {
        notify("Wallet not connected");
        return;
      }

      const faucetContract = new ethers.Contract(
        FAUCET_ADDRESS,
        FAUCET_ABI,
        wallet
      );

      // Appeler le smart contract
      const tx = await faucetContract.requestTokens(account);

      // Animation pendant la transaction
      notify("Transaction pending...");

      // Attendre la confirmation
      const receipt = await tx.wait();

      if (receipt.status === 1) {
        const currentTime = Math.floor(Date.now() / 1000);
        setNextClaimTime(currentTime);
        const showFloatingText = () => {
          const amount = document.createElement("div");
          amount.textContent = "+100 REKT";
          amount.style.position = "fixed";
          amount.style.color = "#F4B000";
          amount.style.fontWeight = "bold";
          amount.style.fontSize = "24px";
          amount.style.zIndex = "9999";
          amount.style.left = "50%";
          amount.style.transform = "translateX(-50%)";
          amount.style.transition = "all 1s ease-out";
          document.body.appendChild(amount);

          requestAnimationFrame(() => {
            const startY = window.innerHeight - 100;
            amount.style.top = `${startY}px`;

            requestAnimationFrame(() => {
              amount.style.top = `${startY - 100}px`;
              amount.style.opacity = "0";
            });

            setTimeout(() => {
              document.body.removeChild(amount);
            }, 1000);
          });
        };

        // Marquer la notification comme lue
        await axiosInstance.post(`/api/notifications/${notificationId}/read`, {
          userId,
        });

        // Mettre à jour le state local
        setFaucetNotifications((prev) =>
          prev.filter((n) => n._id !== notificationId)
        );

        // Déclencher l'animation
        showFloatingText();

        notify("Successfully claimed 100 REKT tokens!");
      }
    } catch (error) {
      console.error("Error claiming faucet:", error);
      if (error.message.includes("24h")) {
        notify("You can only claim once every 24 hours");
      } else if (error.message.includes("Empty")) {
        notify("Faucet is empty");
      } else {
        notify("Failed to claim tokens");
      }
    }
  };

  const markMentionAsRead = async (notificationId) => {
    try {
      const response = await axiosInstance.post(
        `/api/notifications/${notificationId}/read`,
        {
          userId: userId,
        }
      );

      if (response.status === 200) {
        // Mettre à jour le state localement au lieu de refetch
        setEveryoneMentions((prev) =>
          prev.filter((n) => n._id !== notificationId)
        );
      } else {
        throw new Error("Failed to mark as read");
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
      notify("Failed to dismiss notification");
    }
  };

  const markAllMentionsAsRead = async () => {
    try {
      // Marquer toutes les notifications comme lues
      await Promise.all(
        everyoneMentions.map((mention) =>
          axiosInstance.post(`/api/notifications/${mention._id}/read`, {
            userId,
          })
        )
      );

      // Mettre à jour le state local
      setEveryoneMentions([]);
      notify("All mentions dismissed");
    } catch (error) {
      console.error("Error dismissing all mentions:", error);
      notify("Failed to dismiss all mentions");
    }
  };

  const acceptJoinRequest = async (requestId, role) => {
    try {
      await axiosInstance.post(`/api/invitations/acceptRequest/${requestId}`, {
        role,
      });
      setJoinRequests((prev) =>
        prev.filter((request) => request._id !== requestId)
      );
      notify("Join request accepted");
    } catch (error) {
      console.error("Error accepting join request:", error);
      notify("Failed to accept join request");
    }
  };

  const rejectJoinRequest = async (requestId) => {
    try {
      await axiosInstance.post(`/api/invitations/rejectRequest/${requestId}`);
      setJoinRequests((prev) =>
        prev.filter((request) => request._id !== requestId)
      );
      notify("Join request rejected");
    } catch (error) {
      console.error("Error rejecting join request:", error);
      notify("Failed to reject join request");
    }
  };

  const acceptInvitation = async (invitationId) => {
    try {
      const invitation = invitations.find((inv) => inv._id === invitationId);
      if (!invitation) {
        notify("Invitation not found");
        return;
      }

      const response = await axiosInstance.post(
        `/api/invitations/accept/${invitationId}`,
        {
          role: invitation.role || "member",
        }
      );

      if (response.data.success) {
        setInvitations((prevInvitations) =>
          prevInvitations.filter((inv) => inv._id !== invitationId)
        );
        notify("Invitation accepted successfully");
        // Rafraîchir les données de l'utilisateur si nécessaire
        if (typeof window !== "undefined" && window.refreshUserData) {
          window.refreshUserData();
        }
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
      notify(error.response?.data?.error || "Failed to accept invitation");
    }
  };

  const rejectInvitation = async (invitationId) => {
    try {
      const response = await axiosInstance.post(
        `/api/invitations/reject/${invitationId}`
      );

      if (response.data.success) {
        setInvitations((prevInvitations) =>
          prevInvitations.filter((inv) => inv._id !== invitationId)
        );
        notify("Invitation rejected");
      }
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      notify(error.response?.data?.error || "Failed to reject invitation");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <div>
      {nextClaimTime && (
        <div className="bg-[#00000025] p-3 rounded-xl mb-3">
          <FaucetCountdown startTime={nextClaimTime} />
        </div>
      )}
      {invitations.length === 0 &&
      joinRequests.length === 0 &&
      everyoneMentions.length === 0 &&
      faucetNotifications.length === 0 ? (
        <FriendRequests userId={userId} />
      ) : (
        <div>
          <FriendRequests userId={userId} />
          {everyoneMentions.length > 0 && (
            <div>
              <div className="flex flex-row justify-between align-baseline">
                <h3 className="font-semibold mb-2">Team Mentions</h3>
                <button
                  onClick={markAllMentionsAsRead}
                  className="text-xs bg-gray-100 hover:bg-gray-200 text-gray-700 px-2 py-1 rounded-md transition-colors"
                >
                  Dismiss All
                </button>
              </div>
              <ul className={styles.invitationSchema}>
                {everyoneMentions.map((mention) => (
                  <li
                    key={mention._id}
                    className="bg-[#00000025] hover:scale-[1.03] transition-transform mb-3 rounded-xl"
                  >
                    <div className="p-3">
                      <div className="text-sm text-gray-500 mb-1">
                        {formatTimestamp(mention.createdAt)}
                      </div>
                      <div className="font-medium">
                        {mention.sender} mentioned everyone in team chat
                      </div>
                      <div className="text-sm text-gray-600 mt-1">
                        {mention.content}
                      </div>
                      <div className="flex justify-end mt-2">
                        <button
                          onClick={() => markMentionAsRead(mention._id)}
                          className={styles.invitationButton}
                        >
                          Dismiss
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {faucetNotifications.length > 0 && (
            <div>
              <h3 className="font-semibold mb-2">Faucet Available</h3>
              <ul className={styles.invitationSchema}>
                {faucetNotifications.map((notification) => (
                  <li
                    key={notification._id}
                    className="bg-[#00000025] hover:scale-[1.03] transition-transform mb-3 rounded-xl"
                  >
                    <div className="p-3">
                      <div className="text-sm text-gray-500 mb-1">
                        {formatTimestamp(notification.createdAt)}
                      </div>
                      <div className="font-medium text-white">
                        {notification.content}
                      </div>
                      <div className="flex justify-end mt-2">
                        <button
                          onClick={() => handleClaimFaucet(notification._id)}
                          className="px-4 py-2 bg-[#F4B000] text-white rounded-lg hover:opacity-90 transition-all duration-300 hover:scale-105 animate-pulse"
                        >
                          Claim Tokens
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {invitations.length > 0 && (
            <div>
              <h3 className="font-semibold mb-2">Invitations</h3>
              <ul className={styles.invitationSchema}>
                {invitations.map((invitation) => (
                  <li key={invitation._id}>
                    <span className="block mb-1">
                      {invitation.from?.name || "Unknown"} invited you to join{" "}
                      {invitation.team?.name || "Unknown Team"}
                    </span>
                    <div className="flex gap-2 justify-center">
                      <button
                        onClick={() => acceptInvitation(invitation._id)}
                        className={styles.invitationButton}
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => rejectInvitation(invitation._id)}
                        className={styles.invitationButton}
                      >
                        Reject
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {joinRequests.length > 0 && (
            <div>
              <h3 className="font-semibold mb-2 mt-4">Join Requests</h3>
              <ul className={styles.invitationSchema}>
                {joinRequests.map((request) => (
                  <li key={request._id} className="mb-2">
                    <span className="block mb-1">
                      {request.from?.name || "Unknown"} requested to join{" "}
                      {request.team?.name || "Unknown Team"}
                    </span>
                    <div className="flex gap-2">
                      <button
                        onClick={() => acceptJoinRequest(request._id, "member")}
                        className={styles.invitationButton}
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => rejectJoinRequest(request._id)}
                        className={styles.invitationButton}
                      >
                        Reject
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {friendNotifications.length > 0 && (
            <div>
              <h3 className="font-semibold mb-2">Friend Updates</h3>
              <ul className={styles.invitationSchema}>
                {friendNotifications.map((notification) => (
                  <li
                    key={notification._id}
                    className="bg-[#00000025] hover:scale-[1.03] transition-transform mb-3 rounded-xl"
                  >
                    <div className="p-3">
                      <div className="font-medium text-white">
                        {notification.content}
                      </div>
                      <div className="text-sm text-white/50 mt-1">
                        {formatTimestamp(notification.createdAt)}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Invitations;
