import React from 'react';
import { Helmet } from 'react-helmet';
import image from './logo192.webp'

const SEOManager = ({
  title = "REKT - Esport, Tournaments, Betting and Web3 Platform",
  shortTitle = "Rekt Esport - Define Your Skills",
  description = "Participate in esport tournaments, place bets and stake on REKT. Join our gaming community and earn rewards.",
  ogDescription = "Innovative esport platform combining tournaments, betting and staking. Connect your wallet and start the adventure!",
  ogTitle = "REKT - Esport Gaming Platform",
  url = "https://rektinc.io",
  imageUrl = image
}) => {
  return (
    <Helmet>
      {/* Basic Metadata */}
      <title>{title}</title>
      <meta name="title" content={shortTitle} />
      <meta name="description" content={description} />
      
      {/* OpenGraph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={`${url}${imageUrl}`} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={ogTitle} />
      <meta property="twitter:description" content={ogDescription} />
      <meta property="twitter:image" content={`${url}${imageUrl}`} />

      {/* Additional SEO - keeping your existing keywords and robots */}
      <meta name="keywords" content="esport, gaming tournaments, esport betting, staking, blockchain gaming, crypto gaming" />
      <meta name="author" content="REKT" />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default SEOManager;