// WalletContext.js
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import {
  JsonRpcProvider,
  Wallet,
  getAddress,
  parseEther,
  parseUnits,
  HDNodeWallet,
  decryptKeystoreJson,
  encryptKeystoreJson,
  toBigInt,
  Contract,
} from "ethers";
import axiosInstance from "../axiosConfig";
import abiRekt from "../components/ABI/Rekt";

const BSC_TESTNET_RPCS = [
  "https://bsc-testnet.publicnode.com", // Un RPC public qui marche généralement mieux
  "https://bsc-testnet.public.blastapi.io",
  "https://endpoints.omniatech.io/v1/bsc/testnet/public",
  "https://data-seed-prebsc-1-s1.binance.org:8545",
  "https://data-seed-prebsc-2-s1.binance.org:8545",
];

const createProviderWithFallback = async () => {
  for (const rpcUrl of BSC_TESTNET_RPCS) {
    try {
      const provider = new JsonRpcProvider(rpcUrl, {
        name: "BSC Testnet",
        chainId: 97,
      });

      // Test la connexion
      await provider.getNetwork();
      console.log("Connected to RPC:", rpcUrl);
      return provider;
    } catch (error) {
      console.warn(`Failed to connect to ${rpcUrl}, trying next RPC...`);
    }
  }
  throw new Error("Failed to connect to any BSC Testnet RPC");
};

export const SUPPORTED_TOKENS = {
  BNB: {
    symbol: "tBNB",
    decimals: 18,
    isNative: true, // BNB est natif sur BSC
  },
  REKT: {
    address: "0x0a25BA759Df523a0710feB82D6b23A1b2793a8BE",
    symbol: "REKT",
    decimals: 18,
    isNative: false,
    abi: abiRekt,
  },
};

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState(null);

  const setNormalizedAccount = (address) => {
    setAccount(normalizeAddress(address));
  };

  useEffect(() => {
    createProviderWithFallback()
      .then((newProvider) => setProvider(newProvider))
      .catch((error) => console.error("Failed to initialize provider:", error));
  }, []);

  // Fonction pour nettoyer la session
  const clearWalletSession = useCallback(() => {
    localStorage.removeItem('walletMnemonic');
    localStorage.removeItem('walletStatus');
    localStorage.removeItem('sessionExpiry');
    localStorage.removeItem('encryptedWallet');
    localStorage.removeItem('walletPassword');
    localStorage.removeItem('tempWalletInfo');
    localStorage.removeItem('mnemonicSaved');
    setWallet(null);
    setAccount(null);
  }, []);

  const updateSessionExpiry = useCallback(() => {
    const expiryTime = new Date().getTime() + 12 * 60 * 60 * 1000;
    localStorage.setItem("sessionExpiry", expiryTime.toString());
  }, []);

  useEffect(() => {
    const autoConnect = async () => {
      if (!provider) return;

      try {
        const walletStatus = localStorage.getItem("walletStatus");
        const savedMnemonic = localStorage.getItem("walletMnemonic"); // Nouveau
        const sessionExpiry = localStorage.getItem("sessionExpiry");

        // Vérifier si la session est toujours valide
        const isSessionValid =
          sessionExpiry && new Date().getTime() < parseInt(sessionExpiry);

        if (walletStatus === "connected" && savedMnemonic && isSessionValid) {
          console.log("Restauration de la session wallet...");

          // Restaurer le wallet à partir de la phrase mnémonique
          const restoredWallet =
            HDNodeWallet.fromPhrase(savedMnemonic).connect(provider);

          setWallet(restoredWallet);
          setNormalizedAccount(restoredWallet.address);

          // Renouveler la session
          updateSessionExpiry();
          console.log("Wallet restauré avec succès");
        }
      } catch (error) {
        console.error("Error auto-connecting wallet:", error);
        clearWalletSession();
      } finally {
        setLoading(false);
      }
    };

    autoConnect();
  }, [provider, clearWalletSession, updateSessionExpiry]);

  const backupWallet = useCallback(
    async (password) => {
      if (!wallet) throw new Error("No wallet available");
      try {
        // Chiffrement du wallet avec le mot de passe utilisateur
        const encryptedWallet = await encryptKeystoreJson(wallet, password);

        // Sauvegarde dans la base de données via API
        await axiosInstance.post("/api/users/backup-wallet", {
          eth: wallet.address,
          encryptedWallet: encryptedWallet,
        });

        // Générer un fichier de sauvegarde pour l'utilisateur
        const backupData = JSON.stringify(encryptedWallet, null, 2);
        const blob = new Blob([backupData], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        // Télécharger le fichier
        const a = document.createElement("a");
        a.href = url;
        a.download = `wallet-backup-${wallet.address.slice(0, 8)}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        return true;
      } catch (error) {
        console.error("Error backing up wallet:", error);
        throw error;
      }
    },
    [wallet]
  );

  const restoreWallet = useCallback(
    async (backupFile, password) => {
      try {
        // Lire le fichier de backup
        const reader = new FileReader();
        const backupData = await new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsText(backupFile);
        });

        const encryptedWallet = JSON.parse(backupData);

        // Déchiffrer le wallet avec le mot de passe
        const decryptedWallet = await decryptKeystoreJson(
          encryptedWallet,
          password
        );

        const restoredWallet = new Wallet(decryptedWallet).connect(provider);

        // Mettre à jour le state
        setWallet(restoredWallet);
        setNormalizedAccount(restoredWallet.address);

        // Sauvegarder localement
        localStorage.setItem(
          "encryptedWallet",
          JSON.stringify(encryptedWallet)
        );
        localStorage.setItem("walletPassword", password);

        return restoredWallet;
      } catch (error) {
        console.error("Error restoring wallet:", error);
        throw new Error("Invalid backup file or password");
      }
    },
    [provider]
  );

  // Génération d'un mot de passe pour l'encryption
  const generateEncryptionPassword = useCallback(() => {
    const array = new Uint8Array(32);
    crypto.getRandomValues(array);
    return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
      ""
    );
  }, []);

  const connectWithMnemonic = useCallback(
    async (mnemonic, password) => {
      if (!provider) throw new Error("Provider not initialized");
      try {
        setLoading(true);

        if (!password || password.length < 8) {
          throw new Error("Invalid password");
        }

        const newWallet = HDNodeWallet.fromPhrase(mnemonic).connect(provider);

        // Sauvegarder la phrase mnémonique de manière sécurisée pour la session
        localStorage.setItem("walletMnemonic", mnemonic);
        localStorage.setItem("walletStatus", "connected");
        updateSessionExpiry();

        setWallet(newWallet);
        setNormalizedAccount(newWallet.address);

        return {
          address: newWallet.address,
          mnemonic: mnemonic,
        };
      } catch (error) {
        console.error("Error connecting wallet:", error);
        clearWalletSession();
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [provider, clearWalletSession, updateSessionExpiry]
  );

  const disconnect = useCallback(() => {
    // Nettoyer tous les états
    setWallet(null);
    setAccount(null);
    
    // Nettoyer tout le localStorage
    localStorage.clear();
    
    // Ne pas émettre d'événement, laissons App.js gérer la redirection
  }, []);

  // Création d'un nouveau wallet
  const createWallet = useCallback(async () => {
    try {
      if (wallet || account) {
        disconnect();
      }

      setLoading(true);
      const newWallet = HDNodeWallet.createRandom().connect(provider);
      console.log("New wallet created:", newWallet.address);

      const encryptionPassword = generateEncryptionPassword();
      const encryptedWallet = await encryptKeystoreJson(
        newWallet,
        encryptionPassword
      );

      const completeWalletInfo = {
        encryptedWallet,
        address: newWallet.address,
        mnemonic: newWallet.mnemonic?.phrase,
        password: encryptionPassword,
        version: 3
      };

      console.log(completeWalletInfo)
      
      // Sauvegarder pour la persistance
      localStorage.setItem('walletMnemonic', newWallet.mnemonic.phrase);
      localStorage.setItem('walletStatus', 'connected');
      localStorage.setItem('encryptedWallet', JSON.stringify(completeWalletInfo));
      
      setWallet(newWallet);
      setNormalizedAccount(newWallet.address);
  
      return completeWalletInfo;
    } catch (error) {
      console.error('Error creating wallet:', error);
      clearWalletSession(); 
      throw error;
    } finally {
      setLoading(false);
    }
  }, [provider, generateEncryptionPassword, disconnect, account, wallet, clearWalletSession]);

  const restoreFromMnemonic = useCallback(
    async (mnemonic) => {
      try {
        const restoredWallet =
          HDNodeWallet.fromPhrase(mnemonic).connect(provider);
        setWallet(restoredWallet);
        setNormalizedAccount(restoredWallet.address);

        // Sauvegarder localement avec un nouveau mot de passe
        const password = generateEncryptionPassword();
        const encryptedWallet = await encryptKeystoreJson(
          restoredWallet,
          password
        );

        localStorage.setItem(
          "encryptedWallet",
          JSON.stringify(encryptedWallet)
        );
        localStorage.setItem("walletPassword", password);

        return restoredWallet;
      } catch (error) {
        console.error("Error restoring from mnemonic:", error);
        throw new Error("Invalid mnemonic phrase");
      }
    },
    [generateEncryptionPassword, provider]
  );

  // Chargement d'un wallet existant
  const loadExistingWallet = useCallback(async () => {
    try {
      setLoading(true);
      const encryptedWallet = localStorage.getItem("encryptedWallet");
      const password = localStorage.getItem("walletPassword");
      const mnemonicSaved = localStorage.getItem("mnemonicSaved");

      if (encryptedWallet && password && mnemonicSaved === "true") {
        const decryptedWallet = await decryptKeystoreJson(
          JSON.parse(encryptedWallet),
          password
        );
        const walletWithProvider = new Wallet(decryptedWallet).connect(
          provider
        );

        setWallet(walletWithProvider);
        setNormalizedAccount(walletWithProvider.address);
        return walletWithProvider;
      }
      return null;
    } catch (error) {
      console.error("Error loading wallet:", error);
      // En cas d'erreur, nettoyer le localStorage
      localStorage.removeItem("encryptedWallet");
      localStorage.removeItem("walletPassword");
      localStorage.removeItem("mnemonicSaved");
      return null;
    } finally {
      setLoading(false);
    }
  }, [provider]);

  // Récupération du solde
  const getBalance = useCallback(async () => {
    if (!wallet || !provider) return toBigInt(0);
    try {
      const balance = await provider.getBalance(wallet.address);
      return balance;
    } catch (error) {
      console.error("Error getting BNB balance:", error);
      // Tenter de recréer le provider en cas d'erreur
      try {
        const newProvider = await createProviderWithFallback();
        setProvider(newProvider);
        return await newProvider.getBalance(wallet.address);
      } catch (fallbackError) {
        console.error("Fallback failed:", fallbackError);
        return toBigInt(0);
      }
    }
  }, [wallet, provider]);

  // Mise à jour du getTokenBalance
  const getTokenBalance = useCallback(
    async (tokenConfig) => {
      if (!wallet || !tokenConfig.address || !provider) return toBigInt(0);
      try {
        const contract = new Contract(
          tokenConfig.address,
          tokenConfig.abi,
          provider
        );

        const balance = await contract.balanceOf(wallet.address);

        if (tokenConfig.symbol === "REKT" && balance >= parseUnits("10", 18)) {
          try {
            // Vérifier et activer le referral si nécessaire
            await axiosInstance.post('/api/affiliate/activate-referral', {
              address: wallet.address,
            });
          } catch (error) {
            console.error('Error checking referral:', error);
          }
        }

        return balance;
      } catch (error) {
        console.error(`Error getting ${tokenConfig.symbol} balance:`, error);
        // Tenter de recréer le provider en cas d'erreur
        try {
          const newProvider = await createProviderWithFallback();
          setProvider(newProvider);
          const contract = new Contract(
            tokenConfig.address,
            tokenConfig.abi,
            newProvider
          );
          return await contract.balanceOf(wallet.address);
        } catch (fallbackError) {
          console.error("Fallback failed:", fallbackError);
          return toBigInt(0);
        }
      }
    },
    [wallet, provider]
  );

  // Fonction pour envoyer des tokens
  const sendToken = useCallback(
    async (tokenConfig, to, amount) => {
      if (!wallet) throw new Error("No wallet available");
      try {
        const contract = new Contract(
          tokenConfig.address,
          tokenConfig.abi || [
            "function transfer(address to, uint256 amount) returns (bool)",
          ],
          wallet
        );

        const amountInWei = parseUnits(amount.toString(), tokenConfig.decimals);
        const tx = await contract.transfer(to, amountInWei);
        return tx;
      } catch (error) {
        console.error("Error sending token:", error);
        throw error;
      }
    },
    [wallet]
  );

  // Fonction pour obtenir tous les soldes
  const getAllBalances = useCallback(async () => {
    if (!wallet) return {};

    try {
      // BNB est le token natif
      const balances = {
        BNB: await getBalance(),
      };

      // Récupérer le solde REKT
      const rektBalance = await getTokenBalance(SUPPORTED_TOKENS.REKT);
      balances.REKT = rektBalance;

      return balances;
    } catch (error) {
      console.error("Error getting all balances:", error);
      return {};
    }
  }, [wallet, getBalance, getTokenBalance]);

  // Envoi de transaction
  const sendTransaction = useCallback(
    async (to, amount) => {
      if (!wallet) throw new Error("No wallet available");
      try {
        const amountWei = parseEther(amount.toString());

        const tx = {
          to,
          value: amountWei,
        };

        const gasEstimate = await wallet.estimateGas(tx);
        const gasPrice = await provider.getFeeData();

        const transaction = {
          ...tx,
          gasLimit: gasEstimate,
          maxFeePerGas: gasPrice.maxFeePerGas,
          maxPriorityFeePerGas: gasPrice.maxPriorityFeePerGas,
        };

        const signedTx = await wallet.sendTransaction(transaction);
        return signedTx;
      } catch (error) {
        console.error("Error sending transaction:", error);
        throw error;
      }
    },
    [wallet, provider]
  );

  // Signature de message
  const signMessage = useCallback(
    async (message) => {
      if (!wallet) throw new Error("No wallet available");
      try {
        const signature = await wallet.signMessage(message);
        return signature;
      } catch (error) {
        console.error("Error signing message:", error);
        throw error;
      }
    },
    [wallet]
  );

  const contextValue = useMemo(
    () => ({
      wallet,
      account,
      loading,
      createWallet,
      connectWithMnemonic,
      loadExistingWallet,
      getBalance,
      sendTransaction,
      signMessage,
      disconnect,
      backupWallet,
      restoreWallet,
      restoreFromMnemonic,
      getTokenBalance,
      sendToken,
      getAllBalances,
      SUPPORTED_TOKENS,
      provider,
      normalizeAddress,
    }),
    [
      wallet,
      account,
      loading,
      createWallet,
      connectWithMnemonic,
      loadExistingWallet,
      getBalance,
      sendTransaction,
      signMessage,
      disconnect,
      backupWallet,
      restoreWallet,
      restoreFromMnemonic,
      getTokenBalance,
      sendToken,
      getAllBalances,
      provider,
    ]
  );

  if (!provider) {
    return <div>Connecting to the BSC Testnet...</div>;
  }

  return (
    <WalletContext.Provider
      value={{ ...contextValue, disconnect, isConnected: !!wallet }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};

export const normalizeAddress = (address) => {
  if (!address) return "";
  try {
    return getAddress(address);
  } catch (error) {
    console.error("Invalid Ethereum address:", error);
    return address;
  }
};
