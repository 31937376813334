import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import axiosInstance from "../axiosConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import FriendsList from "./Profile/FriendsList";

const ProfileModal = ({
  isOpen,
  onRequestClose,
  saveProfile,
  eth,
  walletInfo,
  existingProfile,
  isNewAccount,
  isConnected
}) => {
  // States
  const [profile, setProfile] = useState({
    name: existingProfile?.name || "",
    eth: eth || "",
    bio: existingProfile?.bio || "",
    password: "",
    encryptedWallet: existingProfile?.encryptedWallet,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [imagePreview, setImagePreview] = useState(
    existingProfile?.image || null
  );
  const [isUploading, setIsUploading] = useState(false);
  const [detailedBets, setDetailedBets] = useState([]);
  const [rankings, setRankings] = useState({
    betRank: "N/A",
    tournamentRank: "N/A",
    totalPlayers: 0,
  });
  const [activeView, setActiveView] = useState("profile");
  const [friends, setFriends] = useState([]);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const validatePassword = (password) => {
    return password.length >= 8; // Minimum 8 caractères
  };

  const fetchFriends = useCallback(async () => {
    if (!profile?.eth) return;

    try {
      const response = await axiosInstance.get(
        `/api/users/${profile.eth}/friends`
      );
      setFriends(response.data);
    } catch (error) {
      console.error("Error fetching friends:", error);
    }
  }, [profile?.eth]);

  // Dans l'useEffect existant, appeler fetchFriends
  useEffect(() => {
    fetchFriends();
  }, [fetchFriends]);

  const getRequiredXPForNextLevel = (level) => {
    const baseXP = 100;
    const multiplier = 1.5;
    return Math.floor(baseXP * Math.pow(multiplier, level - 1));
  };

  const currentLevel = existingProfile?.currentUserId?.experience?.level || 1;
  const currentXP = existingProfile?.currentUserId?.experience?.currentXP || 0;
  const requiredXP = getRequiredXPForNextLevel(currentLevel);
  const progressPercentage = (currentXP / requiredXP) * 100;

  const Stats = () => {
    const lcm = (a, b) => Math.abs(a * b) / gcd(a, b);
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const round = (num) => Math.round(num * 100) / 100;

    const calculateTournamentWinRate = (tournaments) => {
      if (!tournaments || tournaments.length === 0) return 0;

      const winningTournaments = tournaments.filter((tournament) => {
        // On vérifie si le tournamentElo est positif, ce qui indique une position dans le top 3
        return tournament.tournamentElo > 0;
      });

      return (winningTournaments.length / tournaments.length) * 100;
    };

    const [tooltip, setTooltip] = React.useState({
      visible: false,
      x: 0,
      y: 0,
      value: null,
      label: "",
    });

    const data = [
      {
        name: "BS",
        base: 2,
        value: Math.max(
          0,
          calculateBettingScore(existingProfile?.currentUserId?.bets) || 0
        ),
      },
      {
        name: "VS",
        base: 2,
        value: Math.max(
          0,
          calculateTournamentScore(existingProfile?.currentUserId?.bets) || 0
        ),
      },
      {
        name: "WR/B",
        base: 100,
        value: Math.max(
          0,
          statusTest === 0 ? 0 : (winnerTest / statusTest) * 100
        ),
      },
      {
        name: "WR/V",
        base: 100,
        value: calculateTournamentWinRate(
          existingProfile?.currentUserId?.tournaments
        ),
      },
      {
        name: "LVL",
        base: 100,
        value: Math.max(0, existingProfile?.currentUserId?.experience?.level),
      },
    ];

    const base = data.map((item) => item.base).reduce(lcm);
    const normalizer = Object.fromEntries(
      data.map((item) => [item.name, base / item.base])
    );

    const normalize = (label, n) => {
      const normalized = round(((n * normalizer[label]) / base) * 100);
      // Ajuster l'échelle pour commencer à 20%
      return 20 + Math.min(Math.max(normalized, 0), 100) * 0.8;
    };

    const normalizedData = data.map((item, i) => ({
      ...item,
      normalizedValue: normalize(item.name, item.value),
      angle: (Math.PI * 2 * i) / data.length,
    }));

    const getPoint = (angle, value) => {
      const radius = value * 0.85; // Réduire légèrement le rayon pour mieux centrer
      return {
        x: Math.cos(angle - Math.PI / 2) * radius,
        y: Math.sin(angle - Math.PI / 2) * radius,
      };
    };

    const createPolygonPath = (data) => {
      const points = data.map((item) => {
        const point = getPoint(item.angle, item.normalizedValue);
        return `${point.x},${point.y}`;
      });
      return `M ${points.join(" L ")} Z`;
    };

    const handleMouseOver = (e, item) => {
      const x = e.clientX;
      const y = e.clientY;

      setTooltip({
        visible: true,
        x,
        y,
        value: item.value,
        label: item.name,
      });
    };

    const handleMouseOut = () => {
      setTooltip({ ...tooltip, visible: false });
    };

    return (
      <div className="space-y-6">
        <div className="relative w-full aspect-square max-w-[310px] mx-auto flex items-center justify-center bg-black/20 rounded-lg p-2 mt-6">
          <svg viewBox="-100 -109 200 200" className="w-full h-full">
            {/* Grille de fond avec un rayon légèrement réduit */}
            {[20, 40, 60, 80, 100].map((level) => (
              <polygon
                key={`grid-${level}`}
                points={Array.from({ length: data.length }, (_, i) => {
                  const angle = (Math.PI * 2 * i) / data.length;
                  const point = getPoint(angle, level);
                  return `${point.x},${point.y}`;
                }).join(" ")}
                fill="none"
                stroke="rgba(255,255,255,0.1)"
                strokeWidth="0.5"
              />
            ))}

            {/* Lignes des axes */}
            {data.map((_, i) => {
              const angle = (Math.PI * 2 * i) / data.length;
              const point = getPoint(angle, 100);
              return (
                <line
                  key={`axis-${i}`}
                  x1="0"
                  y1="0"
                  x2={point.x}
                  y2={point.y}
                  stroke="rgba(255,255,255,0.1)"
                  strokeWidth="0.5"
                />
              );
            })}

            {/* Forme des données */}
            <path
              d={createPolygonPath(normalizedData)}
              fill="rgba(136, 132, 216, 0.4)"
              stroke="rgb(136, 132, 216)"
              strokeWidth="1"
            />

            {/* Points interactifs */}
            {normalizedData.map((item, i) => {
              const point = getPoint(item.angle, item.normalizedValue);
              return (
                <circle
                  key={`point-${i}`}
                  cx={point.x}
                  cy={point.y}
                  r="3"
                  fill="rgb(136, 132, 216)"
                  className="cursor-pointer hover:r-4 transition-all duration-200"
                  onMouseOver={(e) => handleMouseOver(e, item)}
                  onMouseOut={handleMouseOut}
                />
              );
            })}

            {/* Labels avec position ajustée */}
            {normalizedData.map((item, i) => {
              const point = getPoint(item.angle, 108);
              return (
                <text
                  key={`label-${i}`}
                  x={point.x}
                  y={point.y}
                  textAnchor="middle"
                  fill="rgba(255,255,255,0.7)"
                  fontSize="12"
                  dominantBaseline="middle"
                  className="select-none"
                >
                  {item.name}
                </text>
              );
            })}
          </svg>

          {/* Tooltip avec position fixe */}
          {tooltip.visible && (
            <div
              className="fixed bg-black/80 px-2 py-1 rounded text-white text-xs pointer-events-none z-50"
              style={{
                left: `${tooltip.x}px`,
                top: `${tooltip.y - 30}px`,
              }}
            >
              <div className="font-medium">{tooltip.label}</div>
              <div className="text-[#8884d8]">{round(tooltip.value)}</div>
            </div>
          )}
        </div>

        {/* Nouvelle barre de progression de niveau */}
        <div className="space-y-2">
          <div className="flex justify-between items-center text-sm text-white/70">
            <div>Level {currentLevel}</div>
            <div>
              {currentXP} / {requiredXP} XP
            </div>
          </div>
          <div className="h-2 w-full bg-white/10 rounded-full overflow-hidden">
            <div
              className="h-full bg-gradient-to-r from-[#bfc8d4] to-[#2563EB] transition-all duration-300"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
          {/* Total XP */}
          <div className="text-center text-sm text-white/50">
            Total XP: {existingProfile?.currentUserId?.experience?.totalXP || 0}
          </div>
        </div>
      </div>
    );
  };

  const calculateTimeAgo = (timestamp) => {
    if (!timestamp) return "Invalid date";

    try {
      // Convertir le format ISO avec timezone en objet Date
      const date = new Date(timestamp.replace("+00:00", "Z"));

      if (isNaN(date.getTime())) {
        console.error("Invalid date from timestamp:", timestamp);
        return "Invalid date";
      }

      const now = new Date();
      const seconds = Math.floor((now - date) / 1000);

      const intervals = [
        { label: "year", seconds: 31536000 },
        { label: "month", seconds: 2592000 },
        { label: "day", seconds: 86400 },
        { label: "hour", seconds: 3600 },
        { label: "minute", seconds: 60 },
        { label: "second", seconds: 1 },
      ];

      for (const interval of intervals) {
        const count = Math.floor(seconds / interval.seconds);
        if (count >= 1) {
          return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
        }
      }
      return "Just now";
    } catch (error) {
      console.error(
        "Error calculating time ago:",
        error,
        "timestamp:",
        timestamp
      );
      return "Date invalide";
    }
  };

  useEffect(() => {
    const fetchBetsDetails = async () => {
      if (!existingProfile?.currentUserId?.bets?.length) {
        setDetailedBets([]);
        return;
      }

      try {
        // Récupérer seulement les pandascoreIds des paris de l'utilisateur
        const pandascoreIds = existingProfile.currentUserId.bets
          .map((bet) => bet.betId)
          .filter((id) => id); // Filtrer les ids null/undefined

        if (pandascoreIds.length === 0) {
          setDetailedBets([]);
          return;
        }

        // Récupérer les détails des paris
        const response = await axiosInstance.get("/api/bets/finished", {
          params: {
            betIds: pandascoreIds.join(","),
          },
        });

        const betsWithDetails = existingProfile.currentUserId.bets.map(
          (userBet) => {
            const matchDetails = response.data.find(
              (match) => match.pandascoreId === userBet.betId
            );

            // Si createdAt est déjà formaté (contient "ago"), on le garde tel quel
            // Sinon on le formate avec calculateTimeAgo
            const timeAgo = userBet.createdAt.includes("ago")
              ? userBet.createdAt
              : calculateTimeAgo(userBet.createdAt);

            return {
              ...userBet,
              ...matchDetails,
              amount: userBet.amount,
              chosenTeam: userBet.team,
              odd: userBet.odd,
              createdAt: timeAgo,
            };
          }
        );

        setDetailedBets(betsWithDetails);
      } catch (error) {
        console.error("Error fetching bets details:", error);
      }
    };

    if (isOpen && existingProfile?.currentUserId?.bets) {
      fetchBetsDetails();
    }
  }, [isOpen, existingProfile]);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await axiosInstance.get("/api/leaderboards/rankings");
        const { bettingRanking, tournamentRanking, totalPlayers } =
          response.data;

        // Log pour debug
        console.log("Current user eth:", eth);
        console.log("Betting ranking:", bettingRanking);

        // Trouver le rang du joueur actuel dans chaque classement
        const betPlayer = bettingRanking.find((p) => p.eth === eth);
        const tournamentPlayer = tournamentRanking.find((p) => p.eth === eth);

        console.log("Found betting player:", betPlayer);
        console.log("Found tournament player:", tournamentPlayer);

        setRankings({
          betRank: betPlayer?.rank || "N/A",
          tournamentRank: tournamentPlayer?.rank || "N/A",
          totalPlayers,
          // Ajouter les scores pour référence
          betElo: betPlayer?.betElo || 0,
          tournamentElo: tournamentPlayer?.tournamentElo || 0,
        });
      } catch (error) {
        console.error("Error fetching rankings:", error);
      }
    };

    if (isOpen && eth) {
      fetchRankings();
    }
  }, [isOpen, eth]);

  useEffect(() => {
    if (existingProfile) {
      setProfile(existingProfile);
      const imageUrl = existingProfile.image
        ? `${existingProfile.image}`
        : null;
      console.log("Setting image preview to:", imageUrl);
      setImagePreview(imageUrl);
    }
  }, [existingProfile]);

  // Validation functions
  const isValidEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const validateNameFormat = (name) => {
    return name.length >= 3 && name.length <= 20 && /^[a-zA-Z0-9]+$/.test(name);
  };

  const handleSaveProfile = async () => {
    if (!isValidEthereumAddress(profile.eth)) {
      setErrorMessage("The Ethereum address is invalid.");
      return;
    }
    if (!isNameValid) {
      setErrorMessage("The nickname is invalid.");
      return;
    }
  
    if (isNewAccount) {
      if (!isPasswordValid) {
        setErrorMessage("Password must contain at least 8 characters");
        return;
      }
    }
  
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const referralCode = urlParams.get('ref');
      // Récupérer les informations du wallet
      const storedWalletInfo = isNewAccount 
        ? JSON.parse(localStorage.getItem("tempWalletInfo"))
        : profile.encryptedWallet || existingProfile?.encryptedWallet;
  
      if (isNewAccount && !storedWalletInfo) {
        setErrorMessage("Les informations du wallet sont manquantes");
        return;
      }
  
      // Préparer les données du profil
      const completeProfile = {
        name: profile.name,
        bio: profile.bio || "",
        image: profile.image,
        password: profile.password, // le password est optionnel pour les profils existants
        encryptedWallet: storedWalletInfo,
        eth: profile.eth,
        referralCode,
      };
  
      // Supprimer le password si non fourni (pour les profils existants)
      if (!completeProfile.password) {
        delete completeProfile.password;
      }
  
      console.log("About to save profile with data:", completeProfile);
  
      const response = await axiosInstance.put(
        `/api/users/${eth}`,
        completeProfile
      );
  
      console.log("Profile save response:", response.data);
  
      // Formater la réponse avec les infos wallet nécessaires pour handleProfileSaved
      const formattedProfile = {
        ...response.data,
        image: response.data.imageUrl || null,
        experience: response.data.experience || {
          level: 1,
          currentXP: 0,
          totalXP: 0,
        },
        tournaments: response.data.tournaments || [],
        bets: response.data.bets || [],
        achievements: response.data.achievements || [],
        friendships: response.data.friendships || [],
        friendRequests: response.data.friendRequests || [],
        actionHistory: response.data.actionHistory || {
          teams: { creates: [], joins: [] },
        },
        // Crucial : passer les infos wallet pour les nouveaux comptes
        walletInfo: isNewAccount ? storedWalletInfo : undefined,
        // Passer le password si fourni
        password: completeProfile.password
      };
  
      saveProfile(formattedProfile);
      onRequestClose();
    } catch (error) {
      console.error("Error during profile save:", error);
      const errorMsg = error.response?.data?.msg || "Error saving profile";
      setErrorMessage(errorMsg);
    }
  };

  const formatCloudinaryUrl = (url) => {
    if (!url) return null;

    // Remove any potential double https:// and cleanup
    let cleanUrl = url
      .replace(/^https?:\/\/https?:\/\//, "https://")
      .replace(/^http:\/\/localhost:\d+\/https?:\/\//, "https://");

    // Ensure URL starts with https://
    if (!cleanUrl.startsWith("https://")) {
      cleanUrl = "https://" + cleanUrl.replace(/^http:\/\//, "");
    }

    return cleanUrl;
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setErrorMessage("");

    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await axiosInstance.post(
        `/api/users/${eth}/profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Upload response:", response.data);

      if (response.data.imageUrl) {
        const formattedUrl = formatCloudinaryUrl(response.data.imageUrl);
        console.log("Formatted image URL:", formattedUrl);

        setImagePreview(formattedUrl);
        setProfile((prev) => ({
          ...prev,
          image: formattedUrl,
        }));
      }
    } catch (error) {
      console.error("Upload error:", error);
      setErrorMessage("Failed to upload image");
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (existingProfile?.image) {
      const formattedUrl = formatCloudinaryUrl(existingProfile.image);
      setImagePreview(formattedUrl);
    }
  }, [existingProfile]);

  const handleImageError = (e) => {
    console.error("Image load error with URL:", imagePreview);
    e.target.onerror = null;
    setImagePreview(null);
    setErrorMessage("Failed to load image");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });

    if (name === "name") {
      const isValid = validateNameFormat(value);
      setIsNameValid(isValid);
    }

    if (name === "password") {
      setIsPasswordValid(validatePassword(value));
    }
  };

  const handleBioChange = (e) => {
    setProfile((prev) => ({
      ...prev,
      bio: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting profile:", profile);
    handleSaveProfile();
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };

  const formatGameName = (game) => {
    const gameNames = {
      "dota-2": "Dota 2",
      "league-of-legends": "League of Legends",
      "cs-go": "CS:GO",
      valorant: "Valorant",
      rl: "Rocket League",
    };
    return gameNames[game] || game;
  };

  const getRecentBets = (bets, limit = 50) => {
    if (!bets || !Array.isArray(bets)) return [];

    return bets
      .filter((bet) => bet.status === "finished")
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, limit)
      .map((bet) => {
        const totalAmountWon = parseFloat(bet.amount) * parseFloat(bet.odd);
        const totalAmountLoss = parseFloat(bet.amount);
        const hasWon = bet.winner && bet.chosenTeam === bet.winner.name;

        return {
          title:
            bet.team1 && bet.team2
              ? `${bet.team1.name} vs ${bet.team2.name}`
              : "Match inconnu",
          game: formatGameName(bet.game || "unknown"),
          amountWon: totalAmountWon.toFixed(2),
          amountLoss: totalAmountLoss.toFixed(2),
          hasWon,
          timeAgo: bet.createdAt,
        };
      });
  };

  const getRecentTournaments = (tournaments, limit = 5) => {
    if (!tournaments || !Array.isArray(tournaments)) return [];

    return tournaments
      .sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
        return dateB - dateA;
      })
      .slice(0, limit)
      .map((tournament) => ({
        title: tournament.game || "Unknown Game",
        place: `Elo: ${(tournament.tournamentElo || 0).toFixed(1)}`,
        date: tournament.createdAt,
      }));
  };

  const statusTest = detailedBets?.filter(
    (bet) => bet?.status === "finished"
  ).length;

  const winnerTest = detailedBets?.filter(
    (bet) => bet?.winner?.name === bet?.chosenTeam
  ).length;

  const calculateBettingScore = (bets) => {
    if (!bets || bets.length === 0) return 1.0;

    // Prendre les 50 derniers paris
    const recentBets = bets.slice(-50);

    let totalEloGained = 0;
    let totalRektLost = 0;

    recentBets.forEach((bet) => {
      if (bet.betElo > 0) {
        totalEloGained += bet.betElo - bet.amount;
      } else {
        totalRektLost += bet.amount || 0;
      }
    });

    const averageEloGained = totalEloGained / recentBets.length;
    const averageRektLost = totalRektLost / recentBets.length;

    // Le vrai ratio sans plafond
    return averageRektLost === 0
      ? 1.0
      : +(averageEloGained / averageRektLost).toFixed(2);
  };

  const calculateTournamentScore = (tournaments) => {
    if (!tournaments || tournaments.length === 0) return 1.0;

    // Prendre les 10 derniers tournois
    const recentTournaments = tournaments.slice(-10);

    let totalEloGained = 0;
    let totalRektLost = 0;

    recentTournaments.forEach((tournament) => {
      if (tournament.tournamentElo > 0) {
        totalEloGained += tournament.tournamentElo - tournament.entryFee;
      } else {
        // Pour les tournois sans gain d'Elo, on considère l'entry fee comme perte
        // Si pas d'entry fee dans les données, on prend une valeur par défaut de 100
        totalRektLost += tournament.entryFee || 0;
      }
    });

    const averageEloGained = totalEloGained / recentTournaments.length;
    const averageRektLost = totalRektLost / recentTournaments.length;

    return averageRektLost === 0
      ? 1.0
      : +(averageEloGained / averageRektLost).toFixed(2);
  };

  const modalContent = (
    <div
      className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-[9999] p-2 sm:p-4"
      onClick={handleOverlayClick}
      style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <div
        className="bg-[#ffffff1a] rounded-2xl w-full max-w-6xl max-h-[80%] relative z-[10000] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header - Responsive adjustments */}
        <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />
        <div className="p-4 sm:p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6">
            {/* Profile Edit Section */}
            <div className="md:col-span-1 bg-white/5 rounded-2xl p-4 sm:p-6 relative overflow-hidden">
              <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />
              <div className="flex space-x-4 mb-0 justify-between">
                <button
                  onClick={() => setActiveView("profile")}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    activeView === "profile"
                      ? "bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] text-white"
                      : "bg-white/5 text-white/70 hover:bg-white/10"
                  }`}
                >
                  Profile
                </button>
                <button
                  onClick={() => setActiveView("stats")}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    activeView === "stats"
                      ? "bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] text-white"
                      : "bg-white/5 text-white/70 hover:bg-white/10"
                  }`}
                >
                  Stats
                </button>
              </div>

              {activeView === "profile" ? (
                <form
                  onSubmit={handleSubmit}
                  className="space-y-4 sm:space-y-6"
                >
                  {/* Profile Image */}
                  <div className="relative w-24 h-24 sm:w-32 sm:h-32 mx-auto mb-4 sm:mb-6">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Profile"
                        className="w-full h-full rounded-full object-cover"
                        onError={handleImageError}
                      />
                    ) : (
                      <div className="w-full h-full rounded-full bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] flex items-center justify-center text-2xl sm:text-3xl text-white">
                        {profile.name
                          ? profile.name.substring(0, 2).toUpperCase()
                          : ""}
                      </div>
                    )}
                    <label
                      className="absolute bottom-0 right-0 w-7 h-7 sm:w-8 sm:h-8 bg-white/10 rounded-full flex items-center justify-center cursor-pointer hover:bg-white/20 transition-colors"
                      htmlFor="profileImage"
                    >
                      <FontAwesomeIcon
                        icon={faCamera}
                        className="w-3 h-3 sm:w-4 sm:h-4 text-white"
                      />
                    </label>
                    <input
                      id="profileImage"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageChange}
                      disabled={isUploading}
                    />
                  </div>

                  {/* Form Fields */}
                  <div className="space-y-3 sm:space-y-4">
                    <div>
                      <input
                        type="text"
                        name="name"
                        value={profile.name}
                        onChange={handleChange}
                        placeholder="Pseudo"
                        className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 sm:px-4 sm:py-3 text-white placeholder-white/50 text-sm sm:text-base"
                      />
                      {!isNameValid && (
                        <p className="text-xs sm:text-sm text-red-500 mt-1">
                          Between 3 and 20 characters. Only letters and numbers.
                        </p>
                      )}
                    </div>
                    {(isNewAccount || isConnected) && (
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          value={profile.password}
                          onChange={handleChange}
                          placeholder="Password (min. 8 characters)"
                          className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 sm:px-4 sm:py-3 text-white placeholder-white/50 text-sm sm:text-base"
                          required
                        />
                        {!isPasswordValid && profile.password && (
                          <p className="text-red-500 text-sm mt-1">
                            Password must contain at least 8 characters
                          </p>
                        )}
                      </div>
                    )}

                    <input
                      type="text"
                      name="eth"
                      value={profile.eth}
                      readOnly
                      className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 sm:px-4 sm:py-3 text-white/50 text-sm sm:text-base"
                    />

                    <textarea
                      value={profile.bio}
                      onChange={handleBioChange}
                      placeholder="Are you interesting ?"
                      maxLength="100"
                      className="w-full bg-white/5 border border-white/10 rounded-lg px-3 py-2 sm:px-4 sm:py-3 text-white placeholder-white/50 min-h-[80px] sm:min-h-[100px] resize-none text-sm sm:text-base"
                    />
                  </div>

                  {errorMessage && (
                    <div className="bg-red-500/10 border border-red-500/20 text-red-500 rounded-lg p-2 sm:p-3 text-xs sm:text-sm">
                      {errorMessage}
                    </div>
                  )}

                  <button
                    type="submit"
                    disabled={!isNameValid || isUploading}
                    className="w-full py-2 sm:py-3 rounded-lg bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] text-white font-semibold hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed text-sm sm:text-base"
                  >
                    {isUploading ? "Uploading..." : "Save Changes"}
                  </button>
                </form>
              ) : (
                <Stats />
              )}
            </div>

            {/* Separator */}
            <div className="hidden md:block w-px bg-white/10 h-full" />

            {/* Stats & History Section */}
            <div className="md:col-span-2 space-y-4 sm:space-y-6">
              {/* Stats Grid */}
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
                {[
                  {
                    title: "Betting Score",
                    value:
                      calculateBettingScore(
                        existingProfile?.currentUserId?.bets
                      ).toFixed(2) || "0",
                  },
                  {
                    title: "Versus Score",
                    value:
                      calculateTournamentScore(
                        existingProfile?.currentUserId?.bets
                      ).toFixed(2) || "0",
                  },
                  { title: "Betting Rank", value: `#${rankings.betRank}` },
                  {
                    title: "Versus Rank",
                    value: `#${rankings.tournamentRank}`,
                  },
                ].map((stat, i) => (
                  <div
                    key={i}
                    className="bg-white/5 rounded-2xl p-3 sm:p-4 relative overflow-hidden flex flex-col"
                  >
                    <div className="absolute top-0 left-0 right-0 h-[3px] bg-gradient-to-r from-[#F4B000] to-[#FF6B6B]" />
                    <div className="text-white/70 text-xs sm:text-sm mb-1 sm:mb-2">
                      {stat.title}
                    </div>
                    <div className="text-xl sm:text-2xl font-bold text-[#F4B000] mt-auto">
                      {stat.value}
                    </div>
                  </div>
                ))}
              </div>

              {/* Recent Activity Grid */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
                {/* Recent Bets */}
                <div className="bg-white/5 rounded-2xl p-4 sm:p-6">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4 text-white">
                      Recent Bets
                    </h3>
                    <h3 className="text-[#d6d4d4] text-lg font-semibold mb-3">
                      {detailedBets.length
                        ? `${(statusTest === 0
                            ? 0
                            : (winnerTest / statusTest) * 100
                          ).toFixed(1)}%`
                        : ""}
                    </h3>
                  </div>
                  <div className="space-y-3 sm:space-y-4 max-h-[220px] overflow-y-auto">
                    {getRecentBets(detailedBets).length > 0 ? (
                      getRecentBets(detailedBets).map((bet, i) => (
                        <div
                          key={i}
                          className="p-3 sm:p-4 bg-white/5 rounded-xl"
                        >
                          <div className="flex flex-col min-h-[80px]">
                            <div className="text-white font-medium text-sm sm:text-base">
                              {bet.title}
                            </div>

                            <div className="text-white/70 text-xs">
                              {bet.game}
                            </div>

                            <div className="flex-grow flex flex-col justify-end">
                              <div className="flex justify-end">
                                <div className="text-white/70 text-xs flex-grow flex flex-col justify-end">
                                  {bet.timeAgo}
                                </div>
                                <div
                                  className={`text-sm sm:text-base ${
                                    bet.hasWon
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {bet.hasWon
                                    ? "+" + bet.amountWon
                                    : "-" + bet.amountLoss}{" "}
                                  REKT
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-white/50 text-center py-8">
                        No bets
                      </div>
                    )}
                  </div>
                </div>

                {/* Recent Tournaments */}
                <div className="bg-white/5 rounded-2xl p-4 sm:p-6">
                  <h3 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4 text-white">
                    Recent Tournaments
                  </h3>
                  <div className="space-y-3 sm:space-y-4 max-h-[220px] overflow-y-auto">
                    {getRecentTournaments(
                      existingProfile?.currentUserId?.tournaments
                    ).length > 0 ? (
                      getRecentTournaments(
                        existingProfile?.currentUserId?.tournaments
                      ).map((tournament, i) => (
                        <div
                          key={i}
                          className="flex items-center justify-between p-3 sm:p-4 bg-white/5 rounded-lg"
                        >
                          <div>
                            <div className="text-white font-medium text-sm sm:text-base">
                              {tournament.title}
                            </div>
                            <div className="text-white/50 text-xs sm:text-sm">
                              {tournament.time}
                            </div>
                          </div>
                          <div className="text-[#F4B000] text-sm sm:text-base">
                            {tournament.place}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-white/50 text-center py-8">
                        No tournaments
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <FriendsList friends={friends} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default ProfileModal;
