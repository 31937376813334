import React, { useState, useEffect, useRef } from "react";
import SearchBar from "./SearchBar";
import axiosInstance from "../../axiosConfig";
import ModalProfile from "../Profile/ModalProfile";
import ReactDOM from "react-dom";

const SearchResults = ({ currentUserId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState({ teams: [], players: [] });
  const [isFocused, setIsFocused] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({ type: "", id: "" });
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [selectedProfileTeam, setSelectedProfileTeam] = useState({
    type: "",
    id: "",
  });
  const [isProfileTeamModalOpen, setIsProfileTeamModalOpen] = useState(false);

  const resultSearchRef = useRef(null);

  useEffect(() => {
    if (searchTerm) {
      const fetchResults = async () => {
        try {
          const { data } = await axiosInstance.get(`/api/search`, {
            params: { term: searchTerm },
          });
          console.log("Search results:", data);
          setResults(data);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
      };
      if (searchTerm) {
        fetchResults();
      } else {
        setResults({ teams: [], players: [] });
      }
    } else {
      setResults({ teams: [], players: [] });
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isProfileModalOpen ||
        (resultSearchRef.current &&
          resultSearchRef.current.contains(event.target))
      ) {
        return;
      }
      setSearchTerm("");
      setIsFocused(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProfileModalOpen]);

  const hasNoResults =
    (!results.teams || results.teams.length === 0) &&
    (!results.players || results.players.length === 0);

  const handleProfileClick = (type, id, event) => {
    event.stopPropagation();
    setSelectedProfile({ type, id });
    setIsProfileModalOpen(true);
  };

  const handleProfileTeamClick = (type, id, event) => {
    console.log("opening team modal")
    event.stopPropagation();
    setSelectedProfileTeam({ type, id });
    setIsProfileTeamModalOpen(true);
  };

  const closeModal = () => {
    console.log("Closing Modal");
    setIsProfileModalOpen(false);
    setIsProfileTeamModalOpen(false);
  };

  const handleSearchBarFocus = () => {
    setIsFocused(true);
  };

  const handleSearchBarBlur = () => {
    // Comment out this line to keep results open even after search bar loses focus
    // setIsFocused(false);
  };

  const handleResultSearchClick = (event) => {
    event.stopPropagation();
  };

  const formatAddress = (address) => {
    if (!address) return "";
    const start = address.slice(0, 2);
    const end = address.slice(-5);
    return `${start}...${end}`;
  };

  return (
    <div ref={resultSearchRef} onClick={handleResultSearchClick}>
      <SearchBar
        onSearch={setSearchTerm}
        onFocus={handleSearchBarFocus}
        onBlur={handleSearchBarBlur}
        searchTerm={searchTerm}
      />
      {isFocused && searchTerm && (
        <div className="resultSearch">
          <div className="resultOverflow">
            {hasNoResults && <p>No results found</p>}
            {results.players && results.players.length !== 0 ? (
              <div>
                <h3>User(s)</h3>
                <ul>
                  {results.players.map((player) => (
                    <li
                      key={player._id}
                      className="resultItem"
                      href="#"
                      onClick={(event) => {
                        console.log("Player item clicked:", player._id);
                        handleProfileClick("user", player._id, event);
                      }}
                    >
                      <div className="name">{player.name}</div>
                      <div className="preview">
                        <p>{formatAddress(player.eth)}</p>
                        {/* Ajoutez d'autres informations de prévisualisation ici */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {results.players && results.teams.length !== 0 ? (
              <div>
                <h3>Team(s)</h3>
                <ul>
                  {results.teams.map((team) => (
                    <li
                      key={team._id}
                      className="resultItem"
                      href="#"
                      onClick={(event) => {
                        console.log("Team item clicked:", team._id);
                        handleProfileTeamClick("team", team._id, event);
                      }}
                    >
                      <div className="name">{team.name}</div>
                      <div className="preview">
                        <p>{formatAddress(team.address)}</p>
                        {/* Ajoutez d'autres informations de prévisualisation ici */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {isProfileModalOpen &&
        ReactDOM.createPortal(
          <div onClick={(e) => e.stopPropagation()}>
            <ModalProfile
              isOpen={isProfileModalOpen}
              onRequestClose={closeModal}
              type={selectedProfile.type}
              id={selectedProfile.id}
              userId={currentUserId._id}
            />
          </div>,
          document.body
        )}
      {isProfileTeamModalOpen &&
        ReactDOM.createPortal(
          <div onClick={(e) => e.stopPropagation()}>
            <ModalProfile
              isOpen={isProfileTeamModalOpen}
              onRequestClose={closeModal}
              type={selectedProfileTeam.type}
              id={selectedProfileTeam.id}
              userId={currentUserId._id}
              eth={currentUserId.eth}
            />
          </div>,
          document.body
        )}
    </div>
  );
};

export default SearchResults;
