import { Stake } from "./Stake";
import { Unstake } from "./Unstake";
import { ClaimRewards } from "./ClaimRewards";
import { StakingStats } from "./StakingStats";
import { useWallet } from "../../context/WalletContext";
import SEOManager from "../SEO/SEOManager";

import styles from "../../styles/Staking.module.css";
import Particles from "../../utils/particlesUtils";

const StakingInterface = () => {
  const { account } = useWallet();

  return (
    <>
      <SEOManager
        title={`REKT Esport - Staking Platform`}
        description={`Stake your REKT tokens and earn passive rewards. Participate in our Web3 gaming ecosystem.`}
        ogTitle={`REKT Esport | Staking`}
        ogDescription="Stake REKT tokens to earn rewards. Join our Web3 gaming platform and maximize your earnings through staking."
      />
      <div className="rondBizarreOrangeQuiClignote" />
      <Particles />
      <div
        className={`${styles.parent} bg-[#1D1F24] min-h-screen text-white overflow-x-hidden`}
      >
        <div className={`${styles.container} min-h-[400px] z-1 relative`}>
          {account && (
            <div className={styles.stakingInterface}>
              <div className={styles.actionsGrid}>
                <Stake />
                <Unstake />
                <ClaimRewards />
              </div>
              <div className="border-l border-white/15 h-full mx-4 w-0"></div>
              <div className={styles.actionsGrid}>
                <StakingStats />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StakingInterface;
