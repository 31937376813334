import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useWallet } from '../context/WalletContext'; 

const AdminRoute = ({ element: Component, allowedAddress, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { account } = useWallet(); 

  useEffect(() => {
    const checkAddress = async () => {
      try {
        // Utilisez directement l'account de votre contexte
        if (account === allowedAddress) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error checking address:", error);
      }
      setLoading(false);
    };

    checkAddress();
  }, [allowedAddress, account]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

export default AdminRoute;