import React from 'react';
import { GAME_NAMES, normalizeGameName } from '../../utils/gameUtils';
import rl from "./images/rl.png"
import valo from "./images/valo.png"
import cs from "./images/cs.png"
import lol from "./images/lol.png"
import dota2 from "./images/dota2.png"
import bs from "./images/bs.png"
import apex from "./images/apex.png"
import coc from "./images/coc.png"
import cr from "./images/cr.png"
import styles from '../../styles/GameTabs.module.css'

const GameTabs = ({ activeTab, onTabClick, showAllTab = true }) => {
  const tabs = [
    {
      id: GAME_NAMES.VALORANT,
      logo: valo,
      alt: "Valorant"
    },
    {
      id: GAME_NAMES.LOL,
      logo: lol,
      alt: "League of Legends"
    },
    {
      id: GAME_NAMES.CSGO,
      logo: cs,
      alt: "CS:GO"
    },
    {
      id: GAME_NAMES.DOTA2,
      logo: dota2,
      alt: "Dota 2"
    },
    {
      id: GAME_NAMES.RL,
      logo: rl,
      alt: "Rocket League"
    },
    {
      id: GAME_NAMES.APEX,
      logo: apex,
      alt: "Apex"
    },
    {
      id: GAME_NAMES.COC,
      logo: coc,
      alt: "Clash of Clans"
    },
    {
      id: GAME_NAMES.CR,
      logo: cr,
      alt: "Clash Royale"
    },
    {
      id: GAME_NAMES.BS,
      logo: bs,
      alt: "Brawl Stars"
    },
  ];

  const normalizedActiveTab = normalizeGameName(activeTab);

  return (
    <div className={`${styles.scrollbarHide}`}>
    <div className="flex justify-between gap-2 pb-4 rounded-lg min-w-max">
      {showAllTab && (
        <button
          className={`px-4 py-2 rounded-md transition-colors ${
            activeTab === 'all'
              ? 'bg-[#f4af0094] text-white'
              : 'bg-[#88888831] text-gray-300 hover:bg-gray-600'
          }`}
          onClick={() => onTabClick('all')}
        >
          All
        </button>
      )}
      {tabs.map(tab => (
        <button
          key={tab.id}
          className={`p-2 flex justify-center rounded-md transition-colors w-full ${
            normalizedActiveTab === tab.id
              ? 'bg-[#f4af0094]'
              : 'bg-[#88888831] hover:bg-gray-600'
          }`}
          onClick={() => onTabClick(tab.id)}
        >
          <img 
            src={tab.logo} 
            alt={tab.alt}
            className="h-8 w-auto object-contain invert" 
          />
        </button>
      ))}
    </div>
  </div>
  );
};

export default GameTabs;