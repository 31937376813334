import axiosInstance from "../axiosConfig";

const getLiveStreams = async (channels) => {
    try {
        const response = await axiosInstance.get('/api/streams', {
            params: { channels: channels.join(',') },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching live streams:', error);
        throw error;
    }
};

export { getLiveStreams };