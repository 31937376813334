import React, { useEffect, useState } from "react";

const RoadmapWeb3 = () => {
  const [progress, setProgress] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0)
  const milestones2 = [
    { date: new Date("2024-07-24") },
    { date: new Date("2024-10-01") },
    { date: new Date("2025-01-01") },
    { date: new Date("2025-04-01") },
    { date: new Date("2025-10-01") },
  ];

  useEffect(() => {
    // Définition précise des dates de chaque milestone
    const milestones = [
      { date: new Date("2024-07-24") },
      { date: new Date("2024-10-01") },
      { date: new Date("2025-01-01") },
      { date: new Date("2025-04-01") },
      { date: new Date("2025-10-01") },
    ];

    const calculateProgress = () => {
      const now = new Date();
      const startDate = milestones[0].date;
      const endDate = milestones[milestones.length - 1].date;

      // Trouver les deux milestones entre lesquels nous sommes
      let currentSegment = milestones.length - 2;
      for (let i = 0; i < milestones.length - 1; i++) {
        if (now >= milestones[i].date && now < milestones[i + 1].date) {
          currentSegment = i;
          break;
        }
      }

      let percentage;
      let progressPercentage;
      if (now < startDate) {
        percentage = 0;
      } else if (now > endDate) {
        percentage = 100;
      } else {
        // Calculer la progression dans le segment actuel
        const segmentStart = milestones[currentSegment].date;
        const segmentEnd = milestones[currentSegment + 1].date;
        const segmentDuration = segmentEnd.getTime() - segmentStart.getTime();
        const segmentProgress = now.getTime() - segmentStart.getTime();

        const adjustedBasePercentage =
          (currentSegment / (milestones.length - 1)) * 83 + 10;
        const adjustedSegmentPercentage =
          (segmentProgress / segmentDuration) * (83 / (milestones.length - 1));
        percentage = adjustedBasePercentage + adjustedSegmentPercentage;
        progressPercentage = (segmentProgress / segmentDuration) * 100
      }

      setProgress(Math.min(100, Math.max(0, percentage)));
      setProgressPercentage(Math.min(100, Math.max(0, progressPercentage)))
    };

    calculateProgress();
    const timer = setInterval(calculateProgress, 1000 * 60);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll(".milestone").forEach((milestone) => {
      observer.observe(milestone);
    });

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <style>
        {`
          .container {
            max-width: 1200px;
            margin: 0 auto;
          }

          .title {
            text-align: center;
            font-size: 3.5rem;
            background: var(--gradient);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: titleFloat 3s ease-in-out infinite;
          }

          @keyframes titleFloat {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
          }

          .roadmap {
            position: relative;
            padding: 2rem 0;
          }

          .timeline {
            position: absolute;
            left: 50%;
            width: 4px;
            height: 100%;
            transform: translateX(-50%);
            opacity: 1;
            background: linear-gradient(
              rgba(255, 101, 132, 0) 0%,
              rgba(255, 101, 132, 0.1) 50%,
              rgba(255, 101, 132, 0) 100%);
          }

          .timeline-progress {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-radius: 10px;
            transition: height 0.5s ease;
          }

          .timeline::before,
          .timeline::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 50px;
            pointer-events: none;
          }

          .timeline::before {
            top: 0;
            background: linear-gradient(to bottom,
              transparent,
              rgba(108, 99, 255, 0) 100%
            );
          }

          .timeline::after {
            bottom: 0;
            background: linear-gradient(to top,
              transparent,
              rgba(108, 99, 255, 0) 100%
            );
          }

          .milestone {
            position: relative;
            margin: 1rem 0;
            opacity: 0;
            transform: translateY(50px);
            transition: all 0.8s ease-out;
          }

          .milestone.visible {
            opacity: 1;
            transform: translateY(0);
          }

          .milestone:nth-child(odd) {
            margin-right: 50%;
            padding-right: 3rem;
            text-align: right;
          }

          .milestone:nth-child(even) {
            margin-left: 50%;
            padding-left: 3rem;
          }

          .milestone-content {
            background: rgba(255, 255, 255, 0.04);
            border-radius: 15px;
            padding: 2rem;
            backdrop-filter: blur(3px);
            transition: transform 0.3s ease;
            position: relative;
          }

          .milestone:nth-child(odd) .milestone-content:hover {
            transform: scale(1.05);
            transform-origin: right center;
            background: rgba(255, 255, 255, 0.05);
          }

          .milestone:nth-child(even) .milestone-content:hover {
            transform: scale(1.05);
            transform-origin: left center;
            background: rgba(255, 255, 255, 0.05);
          }

          .dot {
            position: absolute;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 50%;
            left: calc(150vw - 10px);
            top: 50%;
            transform: translateY(-50%);
            animation: pulse 2s infinite;
          }

          .connector {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 3px;
            background: linear-gradient(135deg, rgba(108, 99, 255), rgba(255, 101, 132));
            z-index: -1;
          }

         .milestone:nth-child(odd) .connector {
            right: 0;
            width: 3rem;
          }

          .milestone:nth-child(even) .connector {
            left: 0;
            width: 3rem;
          }

          .milestone:nth-child(odd) .dot {
            right: -10px;
            left: auto;
          }

          .milestone:nth-child(even) .dot {
            left: -10px;
          }

          .milestone-title {
            display: block;
            font-size: 1.4rem !important;
            margin-bottom: 0.5rem !important;
            background: linear-gradient(135deg, rgba(108, 99, 255), rgba(255, 101, 132));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border: none !important;
          }

          .milestone-desc {
            line-height: 1.6;
            color: #94A3B8;
          }

          @keyframes pulse {
            0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); }
            70% { box-shadow: 0 0 0 10px rgba(255, 255, 255, 0); }
            100% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); }
          }

          @media (max-width: 1400px) {
            .roadmap {
              padding-right: 5px;
            }

            .milestone-title {
              font-size: 20px;
            }

            .timeline {
              left: auto;
              right: 0px;
            }

            .milestone {
              margin: 2rem 0;
              padding-right: 40px;
              width: 100%;
            }

            .milestone:nth-child(odd),
            .milestone:nth-child(even) {
              margin-right: 0;
              margin-left: 0;
              padding-right: 25px;
              padding-left: 0;
              text-align: left;
            }

            .milestone:nth-child(odd) .dot,
            .milestone:nth-child(even) .dot {
              left: auto;
              right: -11px;
            }

           .milestone:nth-child(odd) .dot.passed,
            .milestone:nth-child(even) .dot.passed {
              left: auto !important;
              right: -6px !important;
            }

            .milestone:nth-child(odd) .connector,
            .milestone:nth-child(even) .connector {
              left: auto;
              right: 0;
              width: 25px;
            }

            .milestone:nth-child(odd) .milestone-content:hover,
            .milestone:nth-child(even) .milestone-content:hover {
              transform-origin: right center;
            }
          }

          .dot.passed {
            background: #fff;
            animation: none;
            width: 10px;
            height: 10px;
            }
            
          .milestone:nth-child(odd) .dot.passed {
            right: -5px;
            left: auto;
          }

          .milestone:nth-child(even) .dot.passed {
            left: -5px;
          }

          .connector.passed {
            opacity: 0.5;
          }

          @media (min-width: 900px) {
            .progress-indicator {
              position: absolute;
              right: -40px;
              transform: translateY(-50%);
              display: flex;
              align-items: center;
              color: rgba(255, 101, 132, 1);
              font-weight: bold;
              transition: top 0.5s ease;
              font-size: 13px;
            }

            .arrow {
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-right: 6px solid rgba(255, 101, 132, 1);
              margin-right: 3px;
            }
          }

          @media (max-width: 900px) {
            .progress-indicator {
              display: none;
            }

            .arrow {
              display: none;
            }
          }
        `}
      </style>
      <div className="container">
        <div className="roadmap">
          <div className="timeline">
            <div
              className="timeline-progress"
              style={{
                height: `${progress}%`,
                background: `linear-gradient(
                  180deg,
                  rgba(255, 101, 132, 0) 0%,
                  rgba(255, 101, 132, 1) 30%,
                  rgba(255, 101, 132, 1) 70%,
                  rgba(255, 101, 132, 1) 100%
                )`,
              }}
            />
            <div
              className="progress-indicator"
              style={{
                top: `${progress}%`,
              }}
            >
              <div className="arrow"></div>
              {Math.round(progressPercentage)}%
            </div>
          </div>

          {[
            {
              title: "Initial Development - Q3 2024",
              items: [
                "Design and planning",
                "Setting up relationships",
                "Platform UI/UX definition",
                "Initial development",
              ],
            },
            {
              title: "Development & Testnet - Q4 2024",
              items: [
                "Smart contract finalization",
                "Tokenomics implementation",
                "Development Finalization",
                "Testnet launch",
              ],
            },
            {
              title: "ICO, Launch & Team - Q1 2025",
              items: [
                "Marketing campaigns",
                "ICOs launch (1 month duration)",
                "Official platform launch",
                "Official Esport team",
              ],
            },
            {
              title: "Events & Promotion - Q2 2025",
              items: [
                "Tournament events",
                "Project promotion",
                "Mobile app",
              ],
            },
            {
              title: "Airdrop - Q4 2025",
              items: ["Airdrop"],
            },
          ].map((milestone, index) => {
            const isPassed =
              (index / (milestones2.length - 1)) * 83 + 10 < progress;

            return (
              <div key={index} className="milestone">
                <div className={`dot ${isPassed ? "passed" : ""}`}></div>
                <div className={`connector ${isPassed ? "passed" : ""}`}></div>
                <div className="milestone-content">
                  <h2 className="milestone-title">{milestone.title}</h2>
                  <p className="milestone-desc">
                    {milestone.items.map((item, i) => (
                      <React.Fragment key={i}>
                        • {item}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
                <div className={`connector ${isPassed ? "passed" : ""}`}></div>
                <div className={`dot ${isPassed ? "passed" : ""}`}></div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RoadmapWeb3;
